import React, { ChangeEvent, useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import Loading from 'components/Loading';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useLookupModules from 'hooks/useLookupModules';
import set from 'lodash/set';
import { Modules, TaxTypes } from 'types/tenants';

interface ModulesTaxTypesProps {
  contractualInfoModules: Modules[];
  handleChange: (newTenant: Modules[]) => void;
}

function ModulesAndTaxTypes({
  contractualInfoModules,
  handleChange,
}: ModulesTaxTypesProps): JSX.Element {
  const accessToken = useGetAccessToken();

  const { data = [], isLoading, isSuccess } = useLookupModules(accessToken);
  const [modules, setModules] = useState<Modules[]>([]);

  useEffect(() => {
    if (
      (contractualInfoModules.length || (data.length && isSuccess)) &&
      !isLoading
    ) {
      const flattenDataModules = [...contractualInfoModules, ...data];
      const setValue = new Set();
      const unionModules = flattenDataModules.filter((module) => {
        if (!setValue.has(module.moduleId) && module.moduleName !== '') {
          setValue.add(module.moduleId);
          return true;
        }
        return false;
      }, setValue);
      if (unionModules.length) {
        setModules(unionModules);
      }
    }
  }, [contractualInfoModules, data, isSuccess, isLoading]);

  const updateModuleTaxType =
    (module: TaxTypes, parentModuleId: number | null) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const newModule = modules as Modules[];
      const currentModuleIndex: number = modules.findIndex(
        ({ moduleId }) => moduleId === parentModuleId
      );

      const newTaxTypeIndex: number =
        newModule[currentModuleIndex]?.taxTypes?.findIndex(
          ({ taxTypeId }) => taxTypeId === module.taxTypeId
        ) ?? -1;

      set(
        newModule,
        `${currentModuleIndex}.taxTypes.${newTaxTypeIndex}.selected`,
        event.target.checked
      );

      set(newModule, `${currentModuleIndex}.selected`, event.target.checked);

      handleChange(newModule);
    };

  const updateModule =
    (module: Modules) => (event: ChangeEvent<HTMLInputElement>) => {
      const currentModuleIndex = modules.findIndex(
        ({ moduleId }) => moduleId === module.moduleId
      );

      const newModulesTaxTypes = modules as Modules[];
      newModulesTaxTypes[currentModuleIndex].selected = event.target.checked;

      if (!!newModulesTaxTypes[currentModuleIndex]?.taxTypes?.length) {
        newModulesTaxTypes[currentModuleIndex]?.taxTypes?.forEach(
          (el) => (el.selected = event.target.checked)
        );
      }

      handleChange(newModulesTaxTypes);
    };

  const renderModules = () => {
    return modules.map((module: Modules) => (
      <>
        <ListItem key={module.moduleId} alignItems="flex-start">
          <Checkbox
            value="module"
            color="primary"
            id={`module-id-moduleId-${module.moduleId}`}
            checked={module.selected}
            label={module.moduleName}
            onChange={updateModule(module)}
          />
        </ListItem>
        {module?.taxTypes &&
          module?.taxTypes?.length > 0 &&
          module?.taxTypes.map((childModule: TaxTypes) => (
            <ListItem
              key={childModule.taxTypeId}
              sx={{ pl: 5 }}
              alignItems="flex-start"
            >
              <Checkbox
                value="test_Checkbox_value"
                color="primary"
                id={`module-id-moduleId-${childModule.taxTypeId}`}
                checked={childModule.selected}
                label={childModule.taxTypeName}
                onChange={updateModuleTaxType(childModule, module.moduleId)}
              />
            </ListItem>
          ))}
      </>
    ));
  };

  return isLoading && !!modules ? (
    <Loading />
  ) : (
    <Grid container spacing={1}>
      <Grid item xs={10} mb={0.5}>
        <List sx={{ minWidth: '400px', display: 'inline' }} disablePadding>
          {renderModules()}
        </List>
      </Grid>
    </Grid>
  );
}

export default ModulesAndTaxTypes;
