import React, { useEffect, useState } from 'react';

import {
  Edit,
  ExpandLess,
  ExpandMore,
  FileCopy,
  Lock,
  LockOpen,
} from '@mui/icons-material/';
import { Grid, SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { HeaderColumn } from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTable from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DefaultDataTable';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
// TODO: go back and Change this control back to revxcoreui once updated
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { ModuleResponse, RoleResponse, PolicyResponse } from 'types/graphTypes';
import { RoleStatus } from 'types/Status/roleStatus';

import modules, { rolesById } from './manageRolesDashboardScripts';

function ManageRolesDashboard(): JSX.Element {
  const { t } = useTranslation();
  useUpdateTitle(t('pages.manageRoles.title'));
  const dispatch = useAppDispatch();

  const accessToken = useGetAccessToken();
  const {
    data: moduleList,
    isError: isErrorModuleList,
    isLoading: isLoadingModuleList,
    isSuccess: isSuccessModuleList,
    refetch: refetchModuleList,
  } = useQueryRequest<{ Modules: [ModuleResponse] }>(
    'modules',
    accessToken,
    modules,
    {}
  );

  const [selectedRoleId, setSelectedRoleId] = useState<string>('');
  const {
    data: rolesByModule,
    refetch,
    isError: isErrorRolesByModule,
  } = useQueryRequest<{ RolesByModule: [RoleResponse] }>(
    ['RolesById', selectedRoleId],
    accessToken,
    rolesById,
    {
      roleId: selectedRoleId,
    },
    'roleId'
  );

  const onSelectRole = (event: SelectChangeEvent<string | number>) => {
    setSelectedRoleId((event.target as HTMLSelectElement).value);
  };

  useEffect(() => {
    if (!!accessToken) {
      refetchModuleList();
    }
  }, [refetchModuleList, accessToken]);

  useEffect(() => {
    if (isErrorModuleList || isErrorRolesByModule) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [isErrorModuleList, isErrorRolesByModule, dispatch, t]);

  useEffect(() => {
    if (!!selectedRoleId && !!accessToken) {
      refetch();
    }
  }, [selectedRoleId, refetch, accessToken]);

  /*
    Set Default Role by the first element on Module List
   */
  useEffect(() => {
    if (
      isSuccessModuleList &&
      !selectedRoleId &&
      moduleList &&
      moduleList?.Modules?.length > 0
    ) {
      const firstModule: ModuleResponse = moduleList.Modules[0];
      setSelectedRoleId(`${firstModule.moduleId}`);
    }
  }, [isSuccessModuleList, selectedRoleId, setSelectedRoleId, moduleList]);

  const rolesColumns: Column[] = [
    {
      Header: <HeaderColumn localization={t('components.actions.edit')} />,
      id: 'role-edit',
      Cell: (props: CellProps<Record<string, unknown>, unknown>) => {
        return (
          <Link
            to={{ pathname: `/manageroles/edit/${props.row.original.roleId}` }}
          >
            <Edit color={'primary'} />
          </Link>
        );
      },
    },
    {
      Header: <HeaderColumn localization={t('components.actions.clone')} />,
      id: 'role-clone',
      Cell: (props: CellProps<Record<string, unknown>, unknown>) => (
        <Link
          to={{ pathname: `/manageroles/clone/${props.row.original.roleId}` }}
        >
          <FileCopy color={'primary'} />
        </Link>
      ),
    },
    {
      Header: <HeaderColumn localization={t('pages.manageRoles.state')} />,
      id: 'role-state',
      Cell: (props: CellProps<Record<string, unknown>, unknown>) => {
        if (props.row.original.statusId == RoleStatus.Active) return <Lock />;
        else return <LockOpen />;
      },
    },
    {
      Header: <HeaderColumn localization={t('pages.manageRoles.name')} />,
      accessor: 'roleName',
    },
    {
      Header: (
        <HeaderColumn localization={t('pages.manageRoles.description')} />
      ),
      accessor: 'roleDescription',
    },
    {
      Header: (
        <HeaderColumn
          localization={`${t('pages.manageRoles.platform')}/${t(
            'pages.manageRoles.module'
          )}`}
        />
      ),
      accessor: 'moduleName',
    },
    {
      Header: <HeaderColumn localization={t('pages.manageRoles.policies')} />,
      id: 'expander',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
        </span>
      ),
    },
  ];

  const AuthorizationPolicyInfo: React.FC<{
    authorizationPolicies: [PolicyResponse];
  }> = ({ authorizationPolicies }): JSX.Element => {
    return !isEmpty(authorizationPolicies) ? (
      <>
        <Typography
          variant="h3"
          sx={{ fontSize: 16, fontWeight: 'bold', marginBottom: 1 }}
        >
          {t('pages.manageRoles.assignedPolicies')}
        </Typography>
        {authorizationPolicies.map(
          ({
            authorizationPolicyId,
            policyName,
            policyDescription,
          }: PolicyResponse) => (
            <ListItem
              key={`${authorizationPolicyId}-${policyName}`}
              sx={{ padding: '0.1em 0' }}
            >
              <Grid container spacing={12}>
                <Grid item xs={4}>
                  <ListItemText>{policyName}</ListItemText>
                </Grid>
                <Grid item xs={8}>
                  <ListItemText>{policyDescription}</ListItemText>
                </Grid>
              </Grid>
            </ListItem>
          )
        )}
      </>
    ) : (
      <NoResults />
    );
  };

  return (
    <Box>
      {isLoadingModuleList && <Loading />}
      {rolesByModule && isEmpty(rolesByModule) && <NoResults />}
      {rolesByModule && rolesByModule?.RolesByModule.length > 0 && (
        <DefaultDataTable
          columns={rolesColumns}
          data={rolesByModule.RolesByModule}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
          customHeader={
            <Select
              id="datatable-selectfilter"
              data-testid="datatable-selectfilter"
              label={t('pages.manageRoles.showRolesFor')}
              value={selectedRoleId}
              options={
                moduleList?.Modules.map(
                  ({ moduleId, name }: ModuleResponse) => {
                    return { key: `${moduleId}`, desc: name };
                  }
                ) ?? [
                  {
                    key: '',
                    desc: `${t('pages.manageRoles.selectPlatformModule')}`,
                  },
                ]
              }
              onChange={onSelectRole}
              width="18em"
            />
          }
          renderRowSubComponent={(row) => (
            <Box sx={{ width: '60em', margin: 'auto' }}>
              <AuthorizationPolicyInfo
                authorizationPolicies={
                  row?.original?.authorizationPolicy as [PolicyResponse]
                }
              />
            </Box>
          )}
        />
      )}
    </Box>
  );
}

export default ManageRolesDashboard;
