import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import NoResults from 'components/NoResults';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { AuthorizationPolicy, AuthorizationPolicies } from 'types/roles';

function AuthorizationPolicyInfo({
  authorizationPolicies,
}: AuthorizationPolicies): JSX.Element {
  const { t } = useTranslation();
  return !isEmpty(authorizationPolicies) ? (
    <>
      <Typography
        variant="h3"
        sx={{ fontSize: 16, fontWeight: 'bold', marginBottom: 1 }}
      >
        {t('pages.viewRolesDashboard.assignedPolicies')}
      </Typography>
      {authorizationPolicies.map(
        ({
          authorizationPolicyId,
          policyName,
          policyDescription,
        }: AuthorizationPolicy) => (
          <ListItem
            key={`${authorizationPolicyId}-${policyName}`}
            sx={{ padding: '0.1em 0' }}
            data-testid="auth-policy-list"
          >
            <ListItemText>{policyName}</ListItemText>
            <ListItemText>{policyDescription}</ListItemText>
          </ListItem>
        )
      )}
    </>
  ) : (
    <NoResults />
  );
}

export default AuthorizationPolicyInfo;
