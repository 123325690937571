import { useMemo } from 'react';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => ({
    table: {
      width: '70%',
      border: '1px solid #ddd',
      borderCollapse: 'collapse',
    },
    td: {
      whiteSpace: 'nowrap',
      border: '1px solid #ddd',
      padding: '20px',
      textAlign: 'center',
    },
    th: {
      backgroundColor: '#eee',
      border: '1px solid #ddd',
    },
  })
);

interface DataProps {
  row: string[];
}

interface Props {
  dataSource: DataProps[];
}

export default function TableInfo({ dataSource }: Props) {
  const classes = useStyles();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.bulkUserUpload',
  });

  const header = useMemo(() => {
    return t('instructions.example.header').split(',');
  }, [t]);

  return (
    <table className={classes.table}>
      <thead>
        <tr className={`${classes.td} ${classes.th}`}>
          {header.map((column, index) => (
            <th key={index}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataSource.map((data, rowIndex) => (
          <tr className={classes.td} key={rowIndex}>
            {data.row.map((column, columnIndex) => (
              <td key={columnIndex}>{column}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
