import React, { useState } from 'react';

import { Box, FormLabel, Theme } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { useTranslation } from 'react-i18next';
import { RecurrenceScheduleInput } from 'types/channels';

export const labelStyle = (theme: Theme) => {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#333',
    borderBottom: 'none',
    '& .MuiFormLabel-asterisk': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};

interface Days {
  value: number;
}

interface Props {
  setRecurrenceSchedule: React.Dispatch<
    React.SetStateAction<RecurrenceScheduleInput>
  >;
  recurrenceSchedule: RecurrenceScheduleInput;
}
// TODO: replace with lookup once created
const daysOfMonth: Days[] = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
  { value: 15 },
  { value: 16 },
  { value: 17 },
  { value: 18 },
  { value: 19 },
  { value: 20 },
  { value: 21 },
  { value: 22 },
  { value: 23 },
  { value: 24 },
  { value: 25 },
  { value: 26 },
  { value: 27 },
  { value: 28 },
  { value: 29 },
  { value: 30 },
  { value: 31 },
];

function MonthDays({
  setRecurrenceSchedule,
  recurrenceSchedule,
}: Props): JSX.Element {
  const [monthDaysValue, setMonthDaysValue] = useState<number[]>([]);
  const [buttonValues, setButtonValues] = useState<number[]>([]);
  const { t } = useTranslation();

  const handleButton = (value: number) => {
    const newSelectedValues = buttonValues;

    if (!newSelectedValues.includes(value)) {
      newSelectedValues.push(value);
    } else {
      newSelectedValues.splice(newSelectedValues.indexOf(value), 1);
    }

    setButtonValues(newSelectedValues);
    setMonthDaysValue([...monthDaysValue, value]);
    setRecurrenceSchedule({
      ...recurrenceSchedule,
      monthDays: buttonValues,
    });
  };

  return (
    <>
      <Box sx={{ width: '44.57em', display: 'flex', flexDirection: 'column' }}>
        <Box>
          <FormLabel sx={labelStyle} id="weekDays">
            {t('pages.manageChannel.monthDays')}
          </FormLabel>
        </Box>
        <Box>
          {daysOfMonth.map((day, index) => (
            <Button
              key={day.value}
              id={index.toString()}
              onClick={() => handleButton(day.value)}
              sx={[
                (theme) =>
                  buttonValues.includes(day.value)
                    ? { backgroundColor: theme.palette.primary.main }
                    : { backgroundColor: theme.palette.grey5.main },
                { minWidth: '4.5em' },
                { marginLeft: '.3em' },
                { marginBottom: '.2em' },
              ]}
            >
              {day.value}
            </Button>
          ))}
        </Box>
      </Box>
    </>
  );
}
export default MonthDays;
