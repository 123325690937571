import { useCallback, useContext, useMemo } from 'react';

import { Box } from '@mui/system';
import { DatePicker } from '@revenue-solutions-inc/revxcoreui';
import { AssignedRolesContext } from 'components/contexts/AssignedRoles/AssignedRolesProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { AssignedUserRole } from 'types/roles';
import { getFormatDate, isMaxValueDate, toDate } from 'utils/date-util';

type KeyType = 'startDate' | 'endDate';

export default function EditableDatesCell({
  row: { original, index },
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
CellProps<any>): JSX.Element {
  const { assignedRoles, setAssignedRoles } = useContext(AssignedRolesContext);

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.editUserRole',
  });

  const { initialStartDate, initialEndDate, roleId } = useMemo(() => {
    const { startDate, endDate } = original as AssignedUserRole;
    return {
      roleId: original.roleId,
      initialStartDate: toDate(startDate),
      initialEndDate: toDate(endDate),
    };
  }, [original]);

  const { startDateValue, endDateValue, isEdit } = useMemo(() => {
    const role = assignedRoles?.roles.find((r) => r.roleId === roleId);
    return {
      ...role,
      startDateValue:
        role?.startDate && !isMaxValueDate(role.startDate)
          ? role.startDate
          : null,
      endDateValue:
        role?.endDate && !isMaxValueDate(role.endDate) ? role.endDate : null,
    };
  }, [assignedRoles, roleId]);

  const stringFormat = useCallback(
    (value?: Date | null) => {
      if (value && !isMaxValueDate(value)) return getFormatDate(value);
      return t('noDateSpecified');
    },
    [t]
  );

  const handleChangeDate = (key: KeyType) => (value: Date | null) => {
    if (assignedRoles) {
      setAssignedRoles({
        ...assignedRoles,
        roles: [
          ...assignedRoles.roles.map((role) =>
            role.roleId === roleId
              ? {
                  ...role,
                  [key]: value,
                }
              : role
          ),
        ],
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flexDirection: 'row', flexShrink: 2, px: 1 }}>
        {!isEdit ? (
          stringFormat(initialStartDate)
        ) : (
          <DatePicker
            id={`datePicker-startDate-${index}`}
            label=""
            value={startDateValue}
            handleChange={handleChangeDate('startDate')}
          />
        )}
      </Box>
      <Box sx={{ flexDirection: 'row', flexShrink: 2, px: 1 }}>
        {!isEdit ? (
          stringFormat(initialEndDate)
        ) : (
          <DatePicker
            id={`datePicker-endDate-${index}`}
            label=""
            value={endDateValue}
            handleChange={handleChangeDate('endDate')}
          />
        )}
      </Box>
    </Box>
  );
}
