import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, FormLabel, Grid, IconButton, Theme } from '@mui/material';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import useMaskInput from 'hooks/useMaskInput';
import { useTranslation } from 'react-i18next';
import { RecurrenceScheduleInput } from 'types/channels';

interface Props {
  setRecurrenceSchedule: React.Dispatch<
    React.SetStateAction<RecurrenceScheduleInput>
  >;
  recurrenceSchedule: RecurrenceScheduleInput;
}

export const labelStyle = (theme: Theme) => {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#333',
    borderBottom: 'none',
    '& .MuiFormLabel-asterisk': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};

function ExecuteTimes({
  setRecurrenceSchedule,
  recurrenceSchedule,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const handleTimeAdd = () => {
    setRecurrenceSchedule({
      ...recurrenceSchedule,
      hours: [...recurrenceSchedule.hours, 0],
      minutes: [...recurrenceSchedule.minutes, 0],
    });
  };

  const hourMask = useMaskInput(
    '99',
    '',
    /^(?:[1-9]|1[0-9]|2[0-3])$/,
    recurrenceSchedule.hours[0] === undefined ||
      isNaN(recurrenceSchedule.hours[0])
      ? ''
      : recurrenceSchedule.hours[0].toString()
  );

  const minuteMask = useMaskInput(
    '99',
    '',
    /^(?:[0-9]|[1-5][0-9])$/,
    recurrenceSchedule.minutes[0] === undefined ||
      isNaN(recurrenceSchedule.minutes[0])
      ? ''
      : recurrenceSchedule.minutes[0].toString()
  );

  const handleHourTextChange = (index: number) => {
    const inputData = [...recurrenceSchedule.hours];

    if (inputData[index] > 0 && inputData[index] < 24) {
      return '';
    }

    return t('pages.manageChannel.hourMask');
  };

  const handleMinuteTextChange = (index: number) => {
    const inputData = [...recurrenceSchedule.minutes];
    if (inputData[index] > 0 && inputData[index] < 60) {
      return '';
    }

    return t('pages.manageChannel.minuteMask');
  };

  const formatTime = (index: number) => {
    let time = `${t('pages.manageChannel.scheduleTime')} ${
      recurrenceSchedule.hours[index]
    }:${recurrenceSchedule.minutes[index]}`;
    if (
      recurrenceSchedule.hours[index] === undefined ||
      recurrenceSchedule.minutes[index] === undefined
    ) {
      return '';
    } else if (
      recurrenceSchedule.minutes[index] !== undefined &&
      recurrenceSchedule.minutes[index].toString().length === 1
    ) {
      return (time = `${t('pages.manageChannel.scheduleTime')} ${
        recurrenceSchedule.hours[index]
      }:0${recurrenceSchedule.minutes[index]}`);
    }
    return time;
  };

  return (
    <>
      <Grid item xs={10} mb={3}>
        <Box sx={{ marginBottom: '.4em' }}>
          <FormLabel sx={labelStyle} id="execute-times">
            {t('pages.manageChannel.executeTimes')}
          </FormLabel>
        </Box>
        {recurrenceSchedule.hours.map((hour, index) => (
          <React.Fragment key={`${index} Frag`}>
            <Box
              sx={[
                recurrenceSchedule.hours.length - 1 > 0
                  ? { width: '46.5em' }
                  : { width: '42em' },
                {
                  display: 'flex',
                  justifyContent: 'space-between',
                },
              ]}
            >
              <Input
                key={index}
                id="channelHour"
                label={t('pages.manageChannel.hour')}
                helperText={
                  recurrenceSchedule.hours[index] === 0
                    ? ''
                    : handleHourTextChange(index)
                }
                value={
                  recurrenceSchedule.hours[index] === undefined ||
                  recurrenceSchedule.hours[index] === 0
                    ? ''
                    : recurrenceSchedule.hours[index].toString()
                }
                sx={{ width: '20em' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  hourMask.handleMaskChange(event);
                  const inputData = [...recurrenceSchedule.hours];
                  inputData[index] = parseInt(event.target.value);
                  if (event.target.value === '') {
                    inputData[index] = 0;
                  } else {
                    inputData[index] = parseInt(event.target.value);
                  }
                  setRecurrenceSchedule({
                    ...recurrenceSchedule,
                    hours: inputData,
                  });
                }}
              />
              <Input
                id="channelMinutes"
                key={`${index} Minutes`}
                label={t('pages.manageChannel.minutes')}
                helperText={
                  recurrenceSchedule.minutes[index] === 0
                    ? ''
                    : handleMinuteTextChange(index)
                }
                value={
                  recurrenceSchedule.minutes[index] === undefined ||
                  recurrenceSchedule.minutes[index] === 0
                    ? ''
                    : recurrenceSchedule.minutes[index].toString()
                }
                sx={{ width: '20em' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  minuteMask.handleMaskChange(event);
                  const inputData = [...recurrenceSchedule.minutes];

                  if (event.target.value === '') {
                    inputData[index] = 0;
                  } else {
                    inputData[index] = parseInt(event.target.value);
                  }
                  setRecurrenceSchedule({
                    ...recurrenceSchedule,
                    minutes: inputData,
                  });
                }}
              />
              {recurrenceSchedule.hours.length - 1 > 0 && (
                <IconButton
                  size="small"
                  onClick={() => {
                    const deleteValue = [...recurrenceSchedule.hours];
                    deleteValue.splice(index, 1);
                    setRecurrenceSchedule({
                      ...recurrenceSchedule,
                      hours: deleteValue,
                    });
                  }}
                  aria-label="clear-button"
                >
                  <ClearIcon key={`${index} ClearIcon`} />
                </IconButton>
              )}
            </Box>
            <Box sx={{ marginTop: '.4em', marginBottom: '.4em' }}>
              <FormLabel sx={labelStyle} id="formmatedTime">
                {formatTime(index)}
              </FormLabel>
            </Box>
            <Box key={`${index} Box3`}>
              {recurrenceSchedule.hours.length - 1 === index && (
                <IconButton
                  size="small"
                  onClick={() => handleTimeAdd()}
                  aria-label="add-button"
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
}
export default ExecuteTimes;
