import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { DateTimePicker } from '@revenue-solutions-inc/revxcoreui';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import ExecuteTimes from 'components/channelScheduling/ExecuteTimes';
import Frequency from 'components/channelScheduling/Frequency';
import MonthDays from 'components/channelScheduling/MonthDays';
import WeekDays from 'components/channelScheduling/WeekDays';
import useMaskInput from 'hooks/useMaskInput';
import { useTranslation } from 'react-i18next';
import { PipelineScheduleInput, RecurrenceScheduleInput } from 'types/channels';

interface Props {
  pipelineSchedule: PipelineScheduleInput[];
  setPipelineSchedule: React.Dispatch<
    React.SetStateAction<PipelineScheduleInput[]>
  >;
  recurrenceSchedule: RecurrenceScheduleInput;
  setRecurrenceSchedule: React.Dispatch<
    React.SetStateAction<RecurrenceScheduleInput>
  >;
  setChannelScheduleValid: React.Dispatch<React.SetStateAction<boolean>>;
}

function ChannelSchedule({
  pipelineSchedule,
  setPipelineSchedule,
  setRecurrenceSchedule,
  recurrenceSchedule,
  setChannelScheduleValid,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [intervalValid, setIntervalValid] = useState<boolean>(true);
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);

  const ScheduleNameMask = useMaskInput(
    '',
    t('pages.manageChannel.channelScheduleNameMask'),
    /^[A-Za-z0-9\s_]*$/,
    pipelineSchedule[0].name ?? ''
  );

  const hoursValid = recurrenceSchedule.hours.some((hour) => hour > 23);

  const minutesValid = recurrenceSchedule.minutes.some((minute) => minute > 59);

  const handleDateCheck = () => {
    if (
      pipelineSchedule[0].endTime &&
      pipelineSchedule[0].startTime !== null &&
      pipelineSchedule[0].endTime < pipelineSchedule[0].startTime
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateRequiredFields = () => {
    let isValid = true;

    if (
      ScheduleNameMask.maskError ||
      pipelineSchedule[0].name === '' ||
      startDateError === true ||
      pipelineSchedule[0].startTime === null ||
      endDateError === true ||
      intervalValid === false ||
      hoursValid === true ||
      minutesValid === true ||
      handleDateCheck() === true
    ) {
      isValid = false;
    }

    setChannelScheduleValid(isValid);
  };

  useEffect(() => {
    validateRequiredFields();
  });

  const handleHelperTextChange = () => {
    return ScheduleNameMask.maskError;
  };

  const handleMinuteHour = () => {
    if (
      (pipelineSchedule[0].frequency === 'Week' &&
        recurrenceSchedule.hours[0] === undefined) ||
      (pipelineSchedule[0].frequency === 'Month' &&
        recurrenceSchedule.hours[0] === undefined) ||
      (pipelineSchedule[0].frequency === 'Day' &&
        recurrenceSchedule.hours[0] === undefined)
    ) {
      setRecurrenceSchedule({
        ...recurrenceSchedule,
        hours: [0],
        minutes: [0],
      });
    } else if (
      (pipelineSchedule[0].frequency === 'Minute' &&
        recurrenceSchedule.hours[0] !== undefined) ||
      (pipelineSchedule[0].frequency === 'Hour' &&
        recurrenceSchedule.hours[0] !== undefined)
    ) {
      setRecurrenceSchedule({
        ...recurrenceSchedule,
        hours: [],
        minutes: [],
      });
    }
  };
  handleMinuteHour();

  return (
    <>
      <Grid container>
        <Grid item xs={10} mb={3}>
          <Input
            id="channelScheduleName"
            required
            label={t('pages.manageChannel.channelScheduleName')}
            value={pipelineSchedule[0].name.replace('(s)', '')}
            helperText={handleHelperTextChange()}
            sx={{ width: '20em' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              ScheduleNameMask.handleMaskChange(event);
              setPipelineSchedule(
                [...pipelineSchedule].map((object) => {
                  return {
                    ...object,
                    name: event.target.value as string,
                    id: event.target.value as string,
                  };
                })
              );
            }}
          />
        </Grid>

        <Grid item xs={10} mb={3}>
          <Box
            sx={{
              width: '42em',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <DateTimePicker
                id="date-start-time"
                label={t('pages.manageChannel.startTime')}
                required
                sx={{ width: '20em' }}
                onError={(reason) => {
                  setStartDateError(reason ? true : false);
                }}
                value={
                  pipelineSchedule[0].startTime
                    ? pipelineSchedule[0].startTime
                    : null
                }
                handleChange={(date: Date | null) => {
                  setPipelineSchedule(
                    [...pipelineSchedule].map((object) => {
                      return {
                        ...object,
                        startTime: date,
                      };
                    })
                  );
                }}
              />
            </Box>
            <Box>
              <DateTimePicker
                id="date-end-time"
                label={t('pages.manageChannel.endTime')}
                helperText={
                  handleDateCheck() === true
                    ? t('pages.manageChannel.endDateCheck')
                    : ''
                }
                sx={{ width: '20em' }}
                onError={(reason) => {
                  setEndDateError(reason ? true : false);
                }}
                value={
                  pipelineSchedule[0].endTime
                    ? pipelineSchedule[0].endTime
                    : null
                }
                handleChange={(date: Date | null) => {
                  setPipelineSchedule(
                    [...pipelineSchedule].map((object) => {
                      return {
                        ...object,
                        endTime: date,
                      };
                    })
                  );
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={10} mb={3}>
          <Frequency
            setPipelineSchedule={setPipelineSchedule}
            pipelineSchedule={pipelineSchedule}
            setIntervalValid={setIntervalValid}
          />
        </Grid>

        <br />
        {(() => {
          switch (pipelineSchedule[0].frequency) {
            case 'Week':
              return (
                <>
                  <Grid item xs={10} mb={3}>
                    <WeekDays
                      setRecurrenceSchedule={setRecurrenceSchedule}
                      recurrenceSchedule={recurrenceSchedule}
                    />
                  </Grid>
                  <Grid item xs={10} mb={3}>
                    <ExecuteTimes
                      setRecurrenceSchedule={setRecurrenceSchedule}
                      recurrenceSchedule={recurrenceSchedule}
                    />
                  </Grid>
                </>
              );
            case 'Day':
              return (
                <ExecuteTimes
                  setRecurrenceSchedule={setRecurrenceSchedule}
                  recurrenceSchedule={recurrenceSchedule}
                />
              );
            case 'Month':
              return (
                <Grid item xs={10} mb={3}>
                  <MonthDays
                    setRecurrenceSchedule={setRecurrenceSchedule}
                    recurrenceSchedule={recurrenceSchedule}
                  />
                  <br />
                  <ExecuteTimes
                    setRecurrenceSchedule={setRecurrenceSchedule}
                    recurrenceSchedule={recurrenceSchedule}
                  />
                </Grid>
              );
            default:
              return null;
          }
        })()}
      </Grid>
    </>
  );
}

export default ChannelSchedule;
