import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import FileDefinitionFields from 'components/channelScheduling/FileDefinitionFields';
import UploadFileDefinition from 'components/UploadFileDefinition';
import { useTranslation } from 'react-i18next';
import { FileGroupInput, FileInput } from 'types/channels';

interface Props {
  fileGroup: FileGroupInput;
  setFileGroup: React.Dispatch<React.SetStateAction<FileGroupInput>>;
  fileInput: FileInput[];
  setFileInput: React.Dispatch<React.SetStateAction<FileInput[]>>;
  setChannelSchemaDef: React.Dispatch<React.SetStateAction<boolean>>;
}

function FileDefintion({
  fileGroup,
  setFileGroup,
  fileInput,
  setFileInput,
  setChannelSchemaDef,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [displayFields, setDisplayFields] = useState<boolean>(false);
  const [fileParametersValid, setFileParametersValid] = useState<boolean>(true);
  const [uploadedFile, setUploadedFile] = useState<File>();

  // TODO: Remove console.log statement and pass to api call when created
  console.log(uploadedFile);

  const validateRequiredFields = () => {
    let isValid = true;

    if (
      fileGroup.fileGroupName === '' ||
      fileGroup.fileGroupType === '' ||
      fileGroup.fileProcessingType === '' ||
      fileGroup.fileType === '' ||
      fileParametersValid === false
    ) {
      isValid = false;
    }

    setChannelSchemaDef(isValid);
  };

  useEffect(() => {
    validateRequiredFields();
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={10}>
        <UploadFileDefinition
          regexFiles={/^.*\.(csv|pdf|xsd)$/}
          setUploadedFile={setUploadedFile}
          titleOne={t('pages.manageFormsProcessing.start.createYourFileTitle')}
          subTitleOne={t(
            'pages.manageFormsProcessing.start.createYourFileSubtitle'
          )}
          titleTwo={t('pages.manageFormsProcessing.start.uploadFormTitle')}
          subTitleTwo={t(
            'pages.manageFormsProcessing.start.uploadFormSubtitle'
          )}
          setDisplayFields={setDisplayFields}
        />
      </Grid>
      <Grid item xs={9}>
        <FileDefinitionFields
          displayFields={displayFields}
          fileGroup={fileGroup}
          setFileGroup={setFileGroup}
          fileInput={fileInput}
          setFileInput={setFileInput}
          setFileParametersValid={setFileParametersValid}
        />
      </Grid>
    </Grid>
  );
}

export default FileDefintion;
