import React, { useEffect } from 'react';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Grid, IconButton, SelectChangeEvent, Typography } from '@mui/material';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import useMaskInput from 'hooks/useMaskInput';
import { useTranslation } from 'react-i18next';
import { FileGroupInput, FileInput, fileInputDefault } from 'types/channels';

// TODO : replace hard coded select options with lookup
const fileGroupTypes: SelectType[] = [
  { key: 'fixedLength', desc: 'Fixed Length' },
  { key: 'delimited', desc: 'Delimited' },
  { key: 'xml', desc: 'XML' },
  { key: 'excel', desc: 'Excel' },
];

// TODO : replace hard coded select options with lookup
const fileTypes: SelectType[] = [
  { key: '.txt', desc: '.TXT' },
  { key: '.csv', desc: '.CSV' },
  { key: '.xml', desc: '.XML' },
  { key: '.pdf', desc: '.PDF' },
  { key: '.xlx', desc: '.XLX' },
  { key: '.xlsx', desc: '.XLSX' },
];

// TODO : replace hard coded select options with lookup
const fileProcessingTypes: SelectType[] = [
  { key: 'full', desc: 'Full' },
  { key: 'partial', desc: 'Partial' },
];

interface Props {
  fileGroup: FileGroupInput;
  setFileGroup: React.Dispatch<React.SetStateAction<FileGroupInput>>;
  fileInput: FileInput[];
  setFileInput: React.Dispatch<React.SetStateAction<FileInput[]>>;
  displayFields: boolean;
  setFileParametersValid: React.Dispatch<React.SetStateAction<boolean>>;
}

function FileDefinitionFields({
  fileGroup,
  setFileGroup,
  fileInput,
  setFileInput,
  displayFields,
  setFileParametersValid,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const fileProcessingMask = useMaskInput('99', '', /^[0-9]{2}$/);

  const validateRequiredFields = () => {
    let isValid = true;

    fileInput?.map((value: FileInput) => {
      if (
        value.fileName === '' ||
        value.fileMetadata === '' ||
        value.fileProcessingOrder === null
      ) {
        isValid = false;
      }
    });

    setFileParametersValid(isValid);
  };

  useEffect(() => {
    validateRequiredFields();
  });
  return (
    <>
      {displayFields && (
        <Grid container>
          <Grid item xs={12} mb={2}>
            <Typography variant="h3">
              {t('pages.manageChannel.fileGroupDetails')}
            </Typography>
          </Grid>
          <Grid item mb={3} xs={3.9}>
            <Input
              required
              id="fileGroupName"
              label={t('pages.manageChannel.fileGroupName')}
              value={fileGroup.fileGroupName ?? ''}
              sx={{ minWidth: '20em' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFileGroup({
                  ...fileGroup,
                  fileGroupName: event.target.value as string,
                });
              }}
            />
          </Grid>
          <Grid item mb={3} xs={3.9}>
            <Select
              id="fileGroupType"
              value={fileGroup.fileGroupType}
              options={fileGroupTypes}
              label={t('pages.manageChannel.fileGroupType')}
              width="20em"
              required
              onChange={(event: SelectChangeEvent<string | number>) => {
                setFileGroup({
                  ...fileGroup,
                  fileGroupType: event.target.value as string,
                });
              }}
            />
          </Grid>
          <Grid item mb={3} xs={3.9}>
            <Select
              id="fileType"
              value={fileGroup.fileType}
              options={fileTypes}
              label={t('pages.manageChannel.fileType')}
              width="20em"
              required
              onChange={(event: SelectChangeEvent<string | number>) => {
                setFileGroup({
                  ...fileGroup,
                  fileType: event.target.value as string,
                });
              }}
            />
          </Grid>
          <Grid item mb={3} xs={3.9}>
            <Select
              id="fileProcessingType"
              value={fileGroup.fileProcessingType}
              options={fileProcessingTypes}
              label={t('pages.manageChannel.fileProcessingType')}
              width="20em"
              required
              onChange={(event: SelectChangeEvent<string | number>) => {
                setFileGroup({
                  ...fileGroup,
                  fileProcessingType: event.target.value as string,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} mb={2} mt={2}>
            <Typography variant="h3">
              {t('pages.manageChannel.fileDetails')}
            </Typography>
          </Grid>
          {fileInput.map((file: FileInput, index: number) => (
            <React.Fragment key={`${index}_container`}>
              <Grid item mb={3} xs={3.9}>
                <Input
                  required
                  id="fileName"
                  key={index}
                  label={t('pages.manageChannel.fileName')}
                  value={file.fileName}
                  sx={{ minWidth: '20em' }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const inputData = [...fileInput];
                    inputData[index] = {
                      ...inputData[index],
                      fileName: event.target.value,
                    };
                    setFileInput(inputData);
                  }}
                />
              </Grid>
              <Grid item mb={3} xs={3.9}>
                <Input
                  required
                  id="fileMetaData"
                  label={t('pages.manageChannel.fileMetaData')}
                  value={file.fileMetadata}
                  sx={{ minWidth: '20em' }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const inputData = [...fileInput];
                    inputData[index] = {
                      ...inputData[index],
                      fileMetadata: event.target.value,
                    };
                    setFileInput(inputData);
                  }}
                />
              </Grid>
              <Grid item mb={3} xs={3.9}>
                <Input
                  required
                  id="fileProcessingOrder"
                  label={t('pages.manageChannel.fileProcessingOrder')}
                  value={
                    file.fileProcessingOrder === null
                      ? ''
                      : file.fileProcessingOrder.toString()
                  }
                  sx={{ minWidth: '20em' }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    fileProcessingMask.handleMaskChange(event);
                    const inputData = [...fileInput];
                    inputData[index] = {
                      ...inputData[index],
                      fileProcessingOrder:
                        event.target.value === ''
                          ? null
                          : parseInt(event.target.value),
                    };
                    setFileInput(inputData);
                  }}
                />
              </Grid>
              {index !== 0 && (
                <Grid item xs={0.3} mt={2}>
                  <IconButton
                    onClick={() => {
                      const deleteValue = [...fileInput];
                      deleteValue.splice(index, 1);
                      setFileInput(deleteValue);
                    }}
                    aria-label="delete"
                    color="default"
                    size="small"
                  >
                    <CancelRoundedIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={12} pt={2} mb={2}>
            <Button
              id="addChannelFile"
              onClick={() => {
                const test = [...fileInput, fileInputDefault];
                setFileInput(test);
              }}
            >
              {t('pages.manageChannel.addFile')}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default FileDefinitionFields;
