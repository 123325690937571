import { Fragment, useEffect, useMemo, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  Theme,
  Typography,
} from '@mui/material';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import {
  Button,
  DatePicker,
  Checkbox,
  DataDisplay,
} from '@revenue-solutions-inc/revxcoreui';
import Header from '@revenue-solutions-inc/revxcoreui/material/layout/Header';
import Loading from 'components/Loading';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useTenantById from 'hooks/useTenantById';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { Contact, Modules, TaxTypes } from 'types/tenants';

const cardHeaderTheme = (theme: Theme) => {
  return {
    backgroundColor: theme.palette.cardHeader.main,
    '& .MuiCardHeader-title': {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
  };
};

export default function Tenant(): JSX.Element {
  const [selectedModule, setSelectedModule] = useState('admin');
  const match = useRouteMatch<{ id: string }>();
  const dispatch = useAppDispatch();
  const accessToken = useGetAccessToken();
  const { data, isFetching, error } = useTenantById(
    accessToken,
    match.params.id
  );
  const { t } = useTranslation();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [error, dispatch, t]);

  const sortContacts = (a: Contact, b: Contact) => {
    if (a.userContactType < b.userContactType) return -1;
    if (a.userContactType > b.userContactType) return 1;
    return 0;
  };

  const typeOfContact = (type: string) => {
    let contactTypeTitle = 'Contact Information';

    switch (Number.parseInt(type)) {
      case 0:
        contactTypeTitle = 'pages.tenantView.primaryContact';
        break;
      case 1:
        contactTypeTitle = 'pages.tenantView.secondaryContact';
        break;
      default:
        contactTypeTitle = 'pages.tenantView.thirdContact';
        break;
    }
    return t(contactTypeTitle);
  };

  const typeOfConfigurationStatusIcon = (type: string) => {
    let configurationStatusIcon = (
      <CheckCircleIcon sx={{ fill: 'green', fontSize: '40px' }} />
    );

    switch (type) {
      case 'Success':
        configurationStatusIcon = (
          <CheckCircleIcon sx={{ fill: 'green', fontSize: '40px' }} />
        );
        break;
      case 'Failure':
        configurationStatusIcon = (
          <ErrorIcon sx={{ fill: 'red', fontSize: '40px' }} />
        );
        break;
      default:
        configurationStatusIcon = (
          <PendingIcon sx={{ fill: 'blue', fontSize: '40px' }} />
        );
        break;
    }
    return configurationStatusIcon;
  };

  const tenantContacts = useMemo(() => {
    if (data) {
      const {
        contractualInfo: {
          tenantInfo: { contacts },
        },
      } = data;
      return contacts.sort(sortContacts);
    }
    return [];
  }, [data]);

  const viewRsiContacts = useMemo(() => {
    if (data) {
      const {
        rsiContactInfo: { rsiContacts },
      } = data;
      return rsiContacts.sort(sortContacts);
    }
    return [];
  }, [data]);

  const tenancyConfiguration = useMemo(() => {
    return data?.localeInfo;
  }, [data]);

  const modulesTaxTypes: Modules[] = useMemo(() => {
    if (data) {
      const {
        contractualInfo: { modules },
      } = data;
      return modules;
    }
    return [];
  }, [data]);

  const tenantIdP = useMemo(() => {
    if (data) {
      return data?.securityInfo.tenantIdp;
    }
  }, [data]);

  const frontDoor = useMemo(() => {
    if (data) {
      return data?.securityInfo.frontDoor;
    }
  }, [data]);

  const renderModules = () => {
    return modulesTaxTypes.map((module: Modules) => (
      <>
        <ListItem key={module.moduleId} alignItems="flex-start">
          <Checkbox
            value="module"
            color="primary"
            id={`module-id-moduleId-${module.moduleId}`}
            checked={module.selected}
            label={module.moduleName}
            onChange={() => {}}
          />
        </ListItem>
        {module?.taxTypes &&
          module?.taxTypes?.length > 0 &&
          module?.taxTypes.map((childModule: TaxTypes) => (
            <ListItem
              key={childModule.taxTypeId}
              sx={{ pl: 5 }}
              alignItems="flex-start"
            >
              <Checkbox
                value="test_Checkbox_value"
                color="primary"
                id={`module-id-moduleId-${childModule.taxTypeId}`}
                checked={childModule.selected}
                label={childModule.taxTypeName}
                onChange={() => {}}
              />
            </ListItem>
          ))}
      </>
    ));
  };

  return (
    <>
      {isFetching && <Loading />}
      <Header
        handleModuleClick={(moduleId: string) => {
          if (moduleId !== 'admin') {
            window.location.href = `${process.env.REACT_APP_TAX_URL}/dashboard`;
          } else {
            setSelectedModule(moduleId);
          }
          return null;
        }}
        selectedModule={selectedModule}
        headerTitle={t('pages.tenantView.navTitle')}
        headerIcon={
          <>
            <SettingsIcon sx={{ fill: 'black', fontSize: '40px' }} />
            <DataDisplay
              id="module-header"
              label={t('pages.tenantView.tenant')}
              data={data?.tenantName || ''}
            />
          </>
        }
        dataValues={[
          <Grid item xs={12}>
            {typeOfConfigurationStatusIcon(
              data?.configurationStatus?.type || ''
            )}
          </Grid>,
          <DataDisplay
            id="name-header"
            label={t('pages.tenantView.status')}
            data={data?.configurationStatus?.description || ''}
          />,
        ]}
        prevPageName={t('pages.tenantList.navTitle')}
        width={0}
        goBack={goBack}
      />
      <Grid container spacing={3} sx={{ pr: 2, mt: 0.2 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              sx={cardHeaderTheme}
              title={t('pages.tenantView.tenantContacts')}
            />
            <CardContent>
              <Grid container spacing={3}>
                {tenantContacts.map(
                  (
                    {
                      userFullName,
                      userEmail,
                      userPhone,
                      userContactType,
                      userOrganization,
                    },
                    key
                  ) => (
                    <Fragment key={key}>
                      <Grid item xs={12}>
                        {typeOfContact(userContactType)}
                        <Divider sx={{ pt: 1 }} />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography color="text.secondary">
                          {t('pages.tenantView.name')}
                        </Typography>
                        {userFullName}
                        <Typography color="text.secondary" sx={{ mt: 2 }}>
                          {t('pages.tenantView.userOrganization')}
                        </Typography>
                        {userOrganization}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography color="text.secondary">
                          {t('pages.tenantView.phone')}
                        </Typography>
                        {userPhone}
                        <Typography color="text.secondary" sx={{ mt: 2 }}>
                          {t('pages.tenantView.email')}
                        </Typography>
                        <Link to={{ pathname: '' }}>{userEmail}</Link>
                      </Grid>
                    </Fragment>
                  )
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              sx={cardHeaderTheme}
              title={t('pages.tenantView.tenancyConfiguration')}
            />
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  p: 1,
                  m: 1,
                }}
              >
                <Typography>{t('pages.tenantView.locale')}</Typography>
                <Typography variant="h3">
                  {tenancyConfiguration?.locationName}
                </Typography>
                <Typography sx={{ mt: 4 }}>
                  {t('pages.tenantView.language')}
                </Typography>
                <Typography variant="h3">
                  {tenancyConfiguration?.languages[0].languageName}
                </Typography>
                <Typography sx={{ mt: 4 }}>
                  {t('pages.tenantView.timeZone')}
                </Typography>
                <Typography variant="h3">
                  {tenancyConfiguration?.timeZones[0].timeZoneName}
                </Typography>
                <Typography sx={{ mt: 4 }}>
                  {t('pages.tenantView.currency')}
                </Typography>
                <Typography variant="h3">
                  {tenancyConfiguration?.currency}
                </Typography>
                <Typography sx={{ mt: 4 }}>
                  {t('pages.tenantView.portalURL')}
                </Typography>
                <Typography variant="h3">
                  <a href={frontDoor?.domainName}>{frontDoor?.domainName}</a>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              sx={cardHeaderTheme}
              title={t('pages.tenantView.modulesTax')}
            />
            <CardContent>
              <List disablePadding>{renderModules()}</List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              sx={cardHeaderTheme}
              title={t('pages.tenantView.rsiContacts')}
            />
            <CardContent>
              <Grid container spacing={3}>
                {viewRsiContacts.map(
                  (
                    { userFullName, userEmail, userPhone, userContactType },
                    key
                  ) => (
                    <Fragment key={key}>
                      <Grid item xs={12}>
                        {typeOfContact(userContactType)}
                        <Divider sx={{ pt: 1 }} />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography color="text.secondary">
                          {t('pages.tenantView.name')}
                        </Typography>
                        {userFullName}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography color="text.secondary">
                          {t('pages.tenantView.phone')}
                        </Typography>
                        {userPhone}
                        <Typography color="text.secondary" sx={{ mt: 2 }}>
                          {t('pages.tenantView.email')}
                        </Typography>
                        <Link to={{ pathname: '' }}>{userEmail}</Link>
                      </Grid>
                    </Fragment>
                  )
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              sx={cardHeaderTheme}
              title={t('pages.tenantView.tenantIdP')}
            />
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  p: 1,
                  m: 1,
                }}
              >
                <Typography>
                  {t('pages.tenantView.tenantIdentityProvider')}
                </Typography>
                <Typography variant="h3">
                  {tenantIdP?.openIdMetadataUrl}
                </Typography>
                <Typography sx={{ mt: 4 }}>
                  {t('pages.tenantView.adminName')}
                </Typography>
                <Typography variant="h3">
                  {tenantIdP?.managementAdminName}
                </Typography>
                <Typography sx={{ mt: 4 }}>
                  {t('pages.tenantView.adminEmail')}
                </Typography>
                <Typography variant="h3">
                  <Link to={{ pathname: '' }}>
                    {tenantIdP?.managementAdminEmail}
                  </Link>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              sx={cardHeaderTheme}
              title={t('pages.tenantView.messageHistory')}
            />
            <CardContent>
              <Box sx={{ display: 'flex' }}>
                <DatePicker
                  id="dataSent"
                  label={t('pages.tenantView.dateSent')}
                  value={null}
                  handleChange={() => {}}
                />
                <Button
                  id="btnResend"
                  sx={{
                    mx: 1,
                    height: 40,
                    alignSelf: 'flex-end',
                  }}
                >
                  {t('pages.tenantView.resend')}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
