export const EmptyTemplateRecord = {
  templateId: '',
  fixedId: null,
  name: '',
  contentOpenXml: '',
  contentType: '',
  status: '',
  version: null,
  createdBy: '',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
};
export const EmptyReusableContentRecord = {
  reusableContentId: '',
  fixedId: null,
  title: '',
  contentType: '',
  status: '',
  version: null,
  createdBy: '',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
  contentOpenXml: '',
};
