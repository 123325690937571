import { useState } from 'react';

import { Edit, Delete } from '@mui/icons-material';
import Box from '@mui/material/Box';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import DataTableDateRangeFilter from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DataTableDateRangeFilter';
import DefaultDataTable from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DefaultDataTable';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/TableCells/DateCell';
import Header from '@revenue-solutions-inc/revxcoreui/material/layout/Header';
import { filterByDateRange } from 'common/helpers';
import FileSaver from 'file-saver';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMutationRequest from 'hooks/useMutationRequest';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Column, CellProps } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  DeleteTemplate,
  TemplateResponse,
  ReusableContentsResponse,
  DeleteReusableContentInput,
} from 'types/graphTypes';

import { BASIC_TEMPLATATE_DATA } from '../Basic_Template';
import { deleteReusableContent as deleteReusableGraph } from '../ReusableContents/ReusableContentScript';
import { deleteTemplate as deleteTemplateGraph } from '../Templates/TemplateScript';

type Props = {
  tableData: (TemplateResponse | ReusableContentsResponse)[];
  isFromTemplate: boolean;
  reFetch: () => void;
};

function DisplayRecords(dataProps: Props) {
  const [selectedModule, setSelectedModule] = useState('admin');
  const { t } = useTranslation();
  const history = useHistory();
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const [beginDate, setBeginDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [isInactive, setIsInactive] = useState(false);
  useUpdateTitle(t('pages.reusableContent.title'));
  const deleteData = useMutationRequest<
    DeleteTemplate | DeleteReusableContentInput
  >();

  const downloadSampleFile = () => {
    const blob = new Blob([BASIC_TEMPLATATE_DATA], {
      type: 'xml;charset=utf-8',
    });
    FileSaver.saveAs(blob, 'Sample_Template.xml');
  };
  const dataTableFn = () => {
    if (dataProps.isFromTemplate) {
      if (isInactive)
        return endDate || beginDate
          ? filterByDateRange(dataProps?.tableData, beginDate, endDate)
          : dataProps?.tableData;
      else
        return endDate || beginDate
          ? filterByDateRange(dataProps?.tableData, beginDate, endDate).filter(
              (item) => item.status !== 'INACTIVE'
            )
          : dataProps?.tableData.filter((item) => item.status !== 'INACTIVE');
    } else
      return beginDate || endDate
        ? filterByDateRange(dataProps?.tableData, beginDate, endDate)
        : dataProps?.tableData;
  };

  const deleteTemplateOrContent = (item: string) => {
    deleteData.mutate(
      {
        mutationKey: ['deleteTemplate', item],
        params: dataProps.isFromTemplate
          ? {
              templateId: item,
            }
          : {
              reusableContentId: item,
            },
        query: dataProps.isFromTemplate
          ? deleteTemplateGraph
          : deleteReusableGraph,
        token: accessToken,
        paramsId: dataProps.isFromTemplate ? 'template' : 'content',
      },
      {
        onSuccess: () => {
          dataProps.reFetch();
          dispatch(
            addMessage({
              message: t('pages.manageReusableContent.successMessage'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t('pages.manageReusableContent.networkError'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  };

  const columns: Column[] = [
    dataProps.isFromTemplate
      ? {
          Header: t('pages.templates.id'),
          accessor: 'templateId',
        }
      : {
          Header: t('pages.reusableContent.id'),
          accessor: 'reusableContentId',
        },
    dataProps.isFromTemplate
      ? {
          Header: t('pages.templates.subject'),
          accessor: 'name',
        }
      : {
          Header: t('pages.reusableContent.subject'),
          accessor: 'title',
        },
    {
      Header: t('pages.reusableContent.status'),
      accessor: 'status',
    },
    {
      Header: t('pages.templates.version'),
      accessor: 'version',
    },
    {
      Header: t('pages.reusableContent.createdBy'),
      accessor: 'createdBy',
    },
    {
      Header: t('pages.reusableContent.createdDate'),
      accessor: 'createdDate',
      Cell: (props: CellProps<Record<string, string>, React.ReactNode>) => {
        return <DateCell dateString={props.row.original.createdDate} />;
      },
    },
    {
      Header: t('pages.reusableContent.updatedBy'),
      accessor: 'updatedBy',
    },

    {
      Header: t('pages.reusableContent.updatedDate'),
      accessor: 'updatedDate',
      Cell: (props: CellProps<Record<string, string>, React.ReactNode>) => {
        return <DateCell dateString={props.row.original.updatedDate} />;
      },
    },
    {
      Header: t('pages.reusableContent.edit'),
      id: 'content-edit',
      Cell: (props: CellProps<Record<string, unknown>, React.ReactNode>) => {
        if (`${props.row.original.status}`.toLocaleUpperCase() !== 'INACTIVE') {
          return dataProps.isFromTemplate ? (
            <Link
              to={{
                pathname: `/templates/edit/${props.row.original.templateId}`,
              }}
            >
              {' '}
              <Edit color={'primary'} style={{ cursor: 'pointer' }} />
            </Link>
          ) : (
            <Link
              to={{
                pathname: `/reusableContents/edit/${props.row.original.reusableContentId}`,
              }}
            >
              <Edit color={'primary'} style={{ cursor: 'pointer' }} />
            </Link>
          );
        } else {
          return <Edit color={'disabled'} />;
        }
      },
    },
    {
      Header: t('pages.reusableContent.delete'),
      id: 'content-delete',
      Cell: (props: CellProps<Record<string, unknown>, unknown>) => {
        if (`${props.row.original.status}`.toLocaleUpperCase() == 'DRAFT') {
          return (
            <Delete
              style={{ cursor: 'pointer' }}
              color={'primary'}
              onClick={() =>
                dataProps.isFromTemplate
                  ? deleteTemplateOrContent(`${props.row.original.templateId}`)
                  : deleteTemplateOrContent(
                      `${props.row.original.reusableContentId}`
                    )
              }
            />
          );
        } else {
          return <Delete color={'disabled'} />;
        }
      },
    },
  ];
  return (
    <>
      <Header
        handleModuleClick={(moduleId: string) => {
          if (moduleId !== 'admin') {
            window.location.href = `${process.env.REACT_APP_TAX_URL}/dashboard`;
          } else {
            setSelectedModule(moduleId);
          }
          return null;
        }}
        selectedModule={selectedModule}
        headerTitle={
          dataProps.isFromTemplate
            ? t('pages.templates.title')
            : t('pages.reusableContent.title')
        }
        width={0}
        prevPageName={'Home'}
        goBack={() => {
          history.push('/home');
        }}
        button={
          <Box
            sx={{
              display: 'flex',
              width: '20em',
              justifyContent: 'space-between',
            }}
          >
            {dataProps.isFromTemplate ? (
              <Button
                id="createTemplate_upload"
                onClick={() => history.push('/templates/create')}
              >
                {t('pages.manageReusableContent.buttons.upload')}
              </Button>
            ) : (
              <Button
                id="createContent_upload"
                onClick={() => history.push('/reusableContents/create')}
              >
                {t('pages.manageReusableContent.buttons.upload')}
              </Button>
            )}
            <Button id="newContent" onClick={() => downloadSampleFile()}>
              {dataProps.isFromTemplate
                ? t('pages.templates.buttons.create')
                : t('pages.reusableContent.buttons.create')}
            </Button>
          </Box>
        }
      />
      <Box sx={{ width: '90em' }}>
        {dataProps && (
          <DefaultDataTable
            columns={columns}
            data={dataTableFn()}
            customHeader={
              <>
                {dataProps.isFromTemplate ? (
                  <Checkbox
                    id="inactive"
                    checked={isInactive}
                    onChange={(event) => {
                      setIsInactive(event.target.checked);
                    }}
                    label={t('pages.templates.inactive')}
                  />
                ) : null}

                <DataTableDateRangeFilter
                  data-testid="datatable-date-range"
                  beginDate={beginDate ? beginDate : null}
                  endDate={endDate ? endDate : null}
                  setBeginDate={setBeginDate}
                  setEndDate={setEndDate}
                />
              </>
            }
          />
        )}
      </Box>
    </>
  );
}

export default DisplayRecords;
