import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { RoleUser } from 'types/entities';

const fetchRoleUsers = async (
  token: string | undefined,
  roleId: string | undefined,
  assigned: boolean
): Promise<RoleUser[]> => {
  const result = await axios.get(
    `${process.env.REACT_APP_ROLE}/query/users/role/${roleId}?assigned=${assigned}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

export default function useRoleUsers(
  accessToken: string | undefined,
  roleId: string | undefined,
  assigned: boolean
): UseQueryResult<RoleUser[], Error> {
  return useQuery<RoleUser[], Error>(
    ['roleUsers'],
    () => {
      return fetchRoleUsers(accessToken, roleId, assigned);
    },
    {
      enabled: !!accessToken && !!roleId,
    }
  );
}
