import React from 'react';

import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import AppMenuBar from 'components/layout/AppMenuBar';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import Sidebar from 'components/layout/Sidebar';
import Routes from 'components/routing/Routes';
import { ReactQueryDevtools } from 'react-query/devtools';
import { setDrawerWidth } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

function LayoutInternal(): JSX.Element {
  const drawerWidthOpen = useAppSelector((state) => state.content.sidebarWidth);
  const drawerWidth = useAppSelector((state) => state.content.drawerWidth);
  const dispatch = useAppDispatch();

  const handleWidth = (arg: number) => {
    dispatch(setDrawerWidth(arg));
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: 'secondary.main',
      }}
      data-testid="paper-id"
    >
      <AppMenuBar />
      <Header drawerWidth={0} open />
      <main
        style={{
          marginBottom: '4em',
          marginRight: '2em',
          height: '100%',
          marginTop: '40px',
          marginLeft: '20px',
          width: '100%',
        }}
      >
        <Sidebar handleWidth={handleWidth} width={drawerWidthOpen} />
        <Box
          sx={{
            paddingTop: '5em',
            marginLeft: `${drawerWidth}px`,
            transition: (theme) =>
              theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
          }}
          data-testid="layout-box-id"
        >
          <Routes />
        </Box>
      </main>
      <ReactQueryDevtools />
      <Footer />
    </Paper>
  );
}

export default LayoutInternal;
