import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useRsiContacts from 'hooks/useRsiContacts';
import { useTranslation } from 'react-i18next';
import { Contact, RsiContactInfo } from 'types/tenants';

import RsiContactManager from '../RsiContactManager';

interface TenantInfoProps {
  rsiContactInfo: RsiContactInfo;
  handleChange: (newContactInfo: RsiContactInfo) => void;
  handleValidation: (isValid: boolean) => void;
}

function RsiContacts({
  rsiContactInfo,
  handleChange,
  handleValidation,
}: TenantInfoProps): JSX.Element {
  const accessToken = useGetAccessToken();
  const { data } = useRsiContacts(accessToken);
  const { t } = useTranslation();
  const [selectedContact, setSelectedContact] = useState<Contact>();
  const [contactsHaveErrors, setContactError] = useState<boolean>(false);

  const validateContacts = useCallback(
    (contacts: Contact[]) => {
      if (contacts && !contactsHaveErrors) {
        const requiredFields = contacts.filter(
          ({ userContactType, userEmail, userFirstName }) => {
            return (
              !userContactType.trim() ||
              !userEmail.trim() ||
              !userFirstName.trim()
            );
          }
        );

        const onePrimary = contacts.filter(({ userContactType }) => {
          return userContactType === 'Primary';
        });

        return requiredFields.length === 0 && onePrimary.length === 1;
      }
      return false;
    },
    [contactsHaveErrors]
  );

  /*
   * Need to check that values are not blank strings bc currently our mask
   * does not error on blank strings.
   * We are calling this useEffect w/o a 2nd argument bc this will need to
   * run every time a contact gets updated
   */

  useEffect(() => {
    const isValid = validateContacts(rsiContactInfo.rsiContacts);
    handleValidation(isValid);
  }, [rsiContactInfo.rsiContacts, validateContacts, handleValidation]);

  return (
    <Grid container>
      <Grid item xs={12} data-testid="availableContactSelect">
        <Select
          label={t('pages.tenantConfig.rsiContacts.availableContacts')}
          required
          value={selectedContact?.userId ?? ''}
          id="availableContactSelect"
          options={
            data?.map((contact) => {
              return {
                key: contact.userId ?? '',
                desc: contact.userFirstName + ' ' + contact.userLastName,
              };
            }) ?? [{ key: ' ', desc: 'Select Contact' }]
          }
          onChange={(event) => {
            const contactSelect = data?.find(
              (contact) => contact.userId === event.target.value
            );
            if (contactSelect) {
              setSelectedContact(contactSelect);
            }
          }}
          inputProps={{ 'data-testid': 'select-input' }}
        />
        <Button
          id="addContacts"
          variant="outlined"
          type="secondary"
          onClick={() => {
            if (selectedContact) {
              const tempContacts = [...rsiContactInfo.rsiContacts];
              tempContacts.push({
                ...selectedContact,
                userFullName: `${selectedContact.userFirstName} ${selectedContact.userLastName}`,
                userFirstName:
                  selectedContact.userFirstName +
                  ' ' +
                  selectedContact.userLastName,
                userContactType:
                  tempContacts.length === 0 ? 'Primary' : 'Secondary',
              });
              handleChange({
                ...rsiContactInfo,
                rsiContacts: tempContacts,
              });
            }
          }}
          sx={{ ml: 2, mt: 3 }}
        >
          {t('components.button.addContact')}
        </Button>
      </Grid>
      <Grid item mt={2}>
        <RsiContactManager
          contacts={rsiContactInfo.rsiContacts}
          onChange={(contacts: Contact[]) => {
            handleChange({
              ...rsiContactInfo,
              rsiContacts: contacts,
            });
          }}
          showOrganization={false}
          showThirdPartyType={false}
          allowFirstRowRemoval={true}
          phoneRequired={false}
          isReadOnly={true}
          onError={(hasError: boolean) => {
            setContactError(hasError);
          }}
        />
      </Grid>
    </Grid>
  );
}

export default RsiContacts;
