import { useEffect, useState } from 'react';

import { Box, Card, CardContent, Grid, Stack } from '@mui/material';
import {
  Input,
  MessageType,
  Button,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import AddUserSummary from 'components/AddUserSummary';
import useCreateUser from 'hooks/useCreateUser';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMultiMaskInput, { MaskReturn } from 'hooks/useMultiMaskInput';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';

function AddUser(): JSX.Element {
  const accessToken = useGetAccessToken();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.addUser',
  });
  useUpdateTitle(t('title'));
  const { t: p } = useTranslation();
  const dispatch = useAppDispatch();
  const [userEmail, setUserEmail] = useState<(string | null)[]>(['']);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const emailExp =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const emailMask = useMultiMaskInput(
    '',
    t('errors.emailRequired'),
    emailExp,
    userEmail
  );

  const [userValues, setUserValues] = useState({
    firstName: '',
    lastName: '',
    userEmail: '',
  });
  const [summary, setSummary] = useState(false);
  const [valid, setValid] = useState(false);
  const {
    isSuccess,
    isError,
    data: dataResponse,
    error,
    mutate: createUser,
  } = useCreateUser();

  const addUserIntoPlatform = () => {
    if (!!accessToken && valid)
      createUser({ token: accessToken, user: userValues });
  };

  const handleHelperTextChange = (mask: MaskReturn, index: number) => {
    return mask.maskErrors[index];
  };

  const fullNameLength =
    firstName?.length != undefined && lastName?.length != undefined
      ? firstName?.length + lastName?.length
      : 0;
  const firstNameLength =
    firstName?.length != undefined ? firstName?.length : 0;
  const lastNameLength = lastName?.length != undefined ? lastName?.length : 0;
  const emailLength = email?.length != undefined ? email?.length : 0;

  const handleHelperTextFirstNameChange = (prop: string | null) => {
    return firstNameLength > 150 || lastNameLength > 150 || fullNameLength > 150
      ? t('errors.fullNameLength')
      : prop == ''
      ? t('errors.firstNameRequired')
      : '';
  };
  const handleHelperTextLastNameChange = (prop: string | null) => {
    return prop == '' ? t('errors.lastNameRequired') : '';
  };
  const handleHelperTextEmailChange = (prop: string | null) => {
    return emailLength > 255
      ? t('errors.emailLength')
      : prop == ''
      ? t('errors.emailRequired')
      : handleHelperTextChange(emailMask, 0);
  };

  useEffect(() => {
    if (isSuccess) {
      setSummary(true);
      dispatch(
        addMessage({
          message: t('userAdded'),
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [isSuccess, setSummary, dispatch, t]);

  useEffect(() => {
    if (
      emailMask.hasErrors ||
      userValues.firstName.trim() === '' ||
      userValues.lastName.trim() === '' ||
      userValues.userEmail.trim() === '' ||
      fullNameLength > 150 ||
      emailLength > 255
    )
      setValid(false);
    else {
      setValid(true);
    }
  }, [setValid, emailMask, userValues, fullNameLength, emailLength]);

  useEffect(() => {
    if (isError) {
      if (error instanceof Error) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        error.message.includes('409')
          ? dispatch(
              addMessage({
                type: MessageType.Error,
                message: t('errors.existingUser'),
              })
            )
          : dispatch(
              addMessage({
                type: MessageType.Error,
                message: p('components.message.networkerror'),
              })
            );
      }
    }
  }, [isError, dispatch, p, error, t]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} mb={1}>
        <Card>
          <CardContent>
            {summary ? (
              <AddUserSummary
                userValues={[userValues]}
                setSummary={setSummary}
                dataResponse={dataResponse}
              />
            ) : (
              <>
                <Stack spacing={2} direction="row">
                  <Input
                    id="firstNameInput"
                    label={t('firstName')}
                    required
                    helperText={handleHelperTextFirstNameChange(firstName)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFirstName(event.target.value);
                      setUserValues({
                        ...userValues,
                        firstName: event.target.value,
                      });
                    }}
                  />
                  <Input
                    id="lastNameInput"
                    label={t('lastName')}
                    helperText={handleHelperTextLastNameChange(lastName)}
                    required
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLastName(event.target.value);
                      setUserValues({
                        ...userValues,
                        lastName: event.target.value,
                      });
                    }}
                  />
                  <Input
                    id="emailInput"
                    label={t('emailAddress')}
                    required
                    helperText={handleHelperTextEmailChange(email)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      emailMask.handleMaskChange(event, 0);
                      setUserEmail([event.target.value]);
                      setEmail(event.target.value);
                      setUserValues({
                        ...userValues,
                        userEmail: event.target.value,
                      });
                    }}
                  />
                </Stack>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '.5rem',
                  }}
                >
                  <Button
                    id="addUser-button"
                    onClick={addUserIntoPlatform}
                    disabled={!valid}
                  >
                    {t('addUserButton')}
                  </Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AddUser;
