import React from 'react';

import { Card, Stack, Typography } from '@mui/material';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';

export interface SideScrollType {
  key: string | number | undefined;
  name: string;
}

interface SideScrollProps<T> {
  title: string;
  options: SideScrollType[];
  id: string;
  scrollBoxValues: string[];
  onChangeState: (value: T) => void;
  onChangeValue?: T;
}

function SideScrollBox<T>({
  options,
  scrollBoxValues,
  title,
  onChangeState,
  onChangeValue,
  id,
}: SideScrollProps<T>): JSX.Element {
  return (
    <>
      <Typography>{title}</Typography>
      <Card
        sx={{
          padding: '.7em',
          height: '20em',
          overflow: 'auto',
          width: '20em',
        }}
      >
        <Stack>
          {options.map((option: SideScrollType, index: number) => {
            return (
              <Checkbox
                key={option.key}
                id={id}
                checked={true}
                label={option.name}
                onChange={() => {
                  const newList = scrollBoxValues;
                  newList.splice(index, 1);
                  if (onChangeValue) {
                    onChangeState({
                      ...onChangeValue,
                      scrollBoxValues: [...newList],
                    });
                  } else {
                    onChangeState([...newList] as never);
                  }
                }}
              />
            );
          })}
        </Stack>
      </Card>
    </>
  );
}
export default SideScrollBox;
