import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export interface Content {
  pageTitle: string;
  darkMode: boolean;
  language: string;
  sidebarWidth: number;
  drawerWidth: number;
}

const initialState: Content = {
  pageTitle: '',
  darkMode: false,
  language: 'en',
  sidebarWidth: 160,
  drawerWidth: 160,
};

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    updatePageTitle: (state, action: PayloadAction<string>) => {
      state.pageTitle = action.payload;
    },
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setDrawerWidth: (state, action: PayloadAction<number>) => {
      state.drawerWidth = action.payload;
    },
    clearContentState: (state) => {
      const newState = {
        ...initialState,
        language: state.language,
        darkMode: state.darkMode,
      };
      return newState;
    },
  },
});

export const {
  updatePageTitle,
  clearContentState,
  setDarkMode,
  setLanguage,
  setDrawerWidth,
} = contentSlice.actions;

export const pageTitle = (state: RootState): string => {
  return state.content.pageTitle;
};

export const darkMode = (state: RootState): boolean => {
  return state.content.darkMode;
};

export const sidebarWidth = (state: RootState): number => {
  return state.content.sidebarWidth;
};

export const drawerWidth = (state: RootState): number => {
  return state.content.drawerWidth;
};

export default contentSlice.reducer;
