import { RoleResponse } from './graphTypes';
import { RoleStatus } from './Status/roleStatus';

export interface AuthorizationPolicy {
  authorizationPolicyId: number;
  policyName: string;
  policyDescription: string;
}
export interface AuthorizationPolicies {
  authorizationPolicies: [AuthorizationPolicy];
}

export interface Role {
  roleId?: string;
  roleName: string;
  description?: string;
  statusId: string;
  moduleId: number;
  authorizationPolicyIDs: string[];
}

export interface QueryRole {
  roleId: string;
  roleName: string;
  roleDescription: string;
  statusId: string;
  moduleId: number;
  moduleName: string;
  authorizationPolicy: [AuthorizationPolicy];
}

export interface AssignedUserRole {
  roleId: string;
  statusId: string;
  moduleId: number;
  moduleName: string;
  roleName: string;
  roleDescription: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export interface UserRole {
  userId: string;
  name: string;
  email: string;
  userRole: AssignedUserRole[];
}

const roleDefault: RoleResponse = {
  roleId: '',
  roleName: '',
  roleDescription: '',
  moduleId: 1,
  moduleName: '',
  authorizationPolicy: [],
  statusId: RoleStatus.Inactive,
};

export type StatusType = 'Warning' | 'Error';

export type StatusResponseType = 'Success' | 'Skipped' | 'Error';

interface ValidationsProps {
  status: StatusType;
  description: string;
  code: number;
}

export interface ValidateBulkFileResponse {
  firstName: string;
  lastName: string;
  email: string;
  moduleName: string;
  roleName: string;
  startDate: string;
  endDate: string;
  rowNumber: number;
  isValid: boolean;
  validations: ValidationsProps[];
}

export interface CommitBulkFile {
  firstName: string;
  lastName: string;
  email: string;
  moduleName: string;
  roleName: string;
  startDate: string;
  endDate: string;
  rowNumber: number;
}
export interface CommitBulkFileResponse {
  firstName: string;
  lastName: string;
  email: string;
  moduleName: string;
  roleName: string;
  startDate: string;
  endDate: string;
  rowNumber: number;
  status: string;
  descriptions: string[];
}
export interface User {
  firstName: string;
  lastName: string;
  userEmail: string;
}
export interface UserResponse {
  userId: string;
  isSuccess: boolean;
  descriptions: string[];
}

export { roleDefault };
