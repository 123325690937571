import React from 'react';

import UnderConstruction from 'components/UnderConstruction';
import useUpdateTitle from 'hooks/useUpdatetTitle';

function Dashboard(): JSX.Element {
  useUpdateTitle('Dashboard');

  return <UnderConstruction />;
}

export default Dashboard;
