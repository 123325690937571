import React, { useEffect, useState } from 'react';

import { Card, CardContent, Grid } from '@mui/material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import ProgressLoader from '@revenue-solutions-inc/revxcoreui/material/controls/ProgressLoader';
import Loading from 'components/Loading';
import { gql } from 'graphql-request';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMutationRequest from 'hooks/useMutationRequest';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  ChannelInput,
  channelDefault,
  PipelineInput,
  RecurrenceScheduleInput,
  pipelineDefault,
  ParametersInput,
  PipelineScheduleInput,
  pipelineScheduleDefault,
  recurrenceScheduleDefault,
  Channel,
  channelObject,
  parametersDefault,
  FileGroupInput,
  fileGroupDefault,
  FileInput,
  fileInputDefault,
} from 'types/channels';
import { ErrorResponse } from 'types/graphqlErrors';

import ChannelDetails from '../ChannelDetails';
import ChannelSchedule from '../ChannelSchedule';
import ChannelSchemaDefintion from '../ChannelSchemaDefintion';

const query = gql`
  mutation ($channel: CMChannel!) {
    CreateChannel(channel: $channel) {
      id
      channelName
    }
  }
`;

function CreateChannel(): JSX.Element {
  const { t } = useTranslation();

  const createChannel = useMutationRequest<Channel>();
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  useUpdateTitle(t('pages.manageChannel.newChannel'));
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const [channel, setChannel] = useState<Channel>(channelObject);
  const [channelInput, setChannelInput] =
    useState<ChannelInput>(channelDefault);
  const [pipeline, setPipeline] = useState<PipelineInput>(pipelineDefault);
  const [pipelineSchedule, setPipelineSchedule] = useState<
    PipelineScheduleInput[]
  >([pipelineScheduleDefault]);
  const [recurrenceSchedule, setRecurrenceSchedule] =
    useState<RecurrenceScheduleInput>(recurrenceScheduleDefault);
  const [parameters, setParameters] =
    useState<ParametersInput>(parametersDefault);
  const [currentStep, setCurrentStep] = useState(0);
  const [isPipelineDetailsValid, setPipelineDetailsValid] = useState(false);
  const [isChannelScheduleValid, setChannelScheduleValid] = useState(false);
  const [isChannelSchemaDefValid, setChannelSchemaDefValid] = useState(false);
  const [fileGroup, setFileGroup] = useState<FileGroupInput>(fileGroupDefault);
  const [fileInput, setFileInput] = useState<FileInput[]>([fileInputDefault]);

  const [stepPassedValidation, setStepPassedValidation] =
    useState<boolean>(true);

  function mapChannel() {
    const newChannel = { ...channel };
    newChannel.channel = channelInput;
    newChannel.channel.pipeline = pipeline;
    newChannel.channel.pipeline.pipelineSchedule = pipelineSchedule;
    newChannel.channel.pipeline.pipelineSchedule[0].recurrenceSchedule =
      recurrenceSchedule;
    newChannel.channel.pipeline.pipelineSchedule[0].parameters = parameters;
    newChannel.channel.pipeline.parameters = parameters;
    newChannel.channel.fileGroup = fileGroup;
    newChannel.channel.fileGroup.files = fileInput;

    return newChannel;
  }

  const handleReset = () => {
    setCurrentStep(0);
    setCompleted({});
  };

  const saveChannel = () => {
    createChannel.mutate(
      {
        query,
        params: mapChannel(),
        paramsId: 'channel',
        mutationKey: 'createChannel',
        token: accessToken ?? '',
      },
      {
        onSuccess: function (response: ErrorResponse | Channel) {
          if ((response as ErrorResponse).errors) {
            dispatch(
              addMessage({
                message: t('components.message.networkerror'),
                type: MessageType.Error,
              })
            );
          } else {
            dispatch(
              addMessage({
                message: t('components.message.success'),
                type: MessageType.Success,
              })
            );
            setChannel(channelObject);
            setChannelInput(channelDefault);
            setPipeline(pipelineDefault);
            setPipelineSchedule([pipelineScheduleDefault]);
            setRecurrenceSchedule(recurrenceScheduleDefault);
          }
        },

        onError: () => {
          dispatch(
            addMessage({
              message: t('components.message.networkerror'),
              type: MessageType.Error,
            })
          );
          setChannel(channelObject);
          setChannelInput(channelDefault);
          setPipeline(pipelineDefault);
          setPipelineSchedule([pipelineScheduleDefault]);
        },
      }
    );
  };

  useEffect(() => {
    const newCompleted = completed;
    if (isPipelineDetailsValid) newCompleted[currentStep] = true;

    switch (currentStep) {
      case 0:
        newCompleted[currentStep] = isPipelineDetailsValid;
        break;
      case 1:
        newCompleted[currentStep] = isChannelSchemaDefValid;
        break;
      case 2:
        newCompleted[currentStep] = isChannelScheduleValid;
        break;
    }

    setCompleted(newCompleted);
  }, [
    completed,
    currentStep,
    isPipelineDetailsValid,
    isChannelScheduleValid,
    isChannelSchemaDefValid,
  ]);

  const validateSteps = () => {
    let isValid = true;

    switch (currentStep) {
      case 0:
        isValid = isPipelineDetailsValid;
        break;
      case 1:
        isValid = isChannelSchemaDefValid;
    }
    setStepPassedValidation(isValid);
  };

  useEffect(() => {
    validateSteps();
  });
  return !createChannel.isLoading ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ProgressLoader
                completed={completed}
                handleReset={handleReset}
                setCurrentStep={setCurrentStep}
                steps={[
                  t('pages.manageChannel.channelDetails'),
                  t('pages.manageChannel.sourceSchemaDef'),
                  t('pages.manageChannel.scheduleCreation'),
                ]}
                nonLinear={false}
                currentStep={currentStep}
                handleCurrentStep={(activeStep: number) => {
                  setCurrentStep(activeStep);
                }}
                centerAlign
                handleSave={saveChannel}
                isCompleteStepBtnDisabled={!stepPassedValidation}
                isSaveAndCreateDisabled={!isChannelScheduleValid}
              >
                {currentStep === 0 && (
                  <ChannelDetails
                    pipeline={pipeline}
                    setPipeline={setPipeline}
                    channelInput={channelInput}
                    setChannelInput={setChannelInput}
                    setPipelineDetailsValid={setPipelineDetailsValid}
                    pipelineSchedule={pipelineSchedule}
                    setPipelineSchedule={setPipelineSchedule}
                    parameters={parameters}
                    setParameters={setParameters}
                  />
                )}
                {currentStep === 1 && (
                  <ChannelSchemaDefintion
                    fileGroup={fileGroup}
                    setFileGroup={setFileGroup}
                    setFileInput={setFileInput}
                    fileInput={fileInput}
                    setChannelSchemaDef={setChannelSchemaDefValid}
                  />
                )}
                {currentStep === 2 && (
                  <ChannelSchedule
                    pipelineSchedule={pipelineSchedule}
                    setPipelineSchedule={setPipelineSchedule}
                    recurrenceSchedule={recurrenceSchedule}
                    setRecurrenceSchedule={setRecurrenceSchedule}
                    setChannelScheduleValid={setChannelScheduleValid}
                  />
                )}
              </ProgressLoader>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  ) : (
    <Loading />
  );
}

export default CreateChannel;
