import React from 'react';

import Dashboard from 'components/Dashboard';
import SecureRoute from 'components/routing/SecureRoute';
import AddUser from 'pages/AddUser/AddUser';
import AddUsers from 'pages/AddUsers';
import BulkUserUpload from 'pages/BulkUserUpload/BulkUserUpload';
import ConfigGroupEditor from 'pages/ConfigGroupEditor/ConfigGroupEditor';
import ConfigRecordEditor from 'pages/ConfigRecordEditor/ConfigRecordEditor';
import ConfigTypeEditor from 'pages/ConfigTypeEditor/ConfigTypeEditor';
import ManageReusableContent from 'pages/Correspondence/ManageReusableContent';
import ManageTemplate from 'pages/Correspondence/ManageTemplate';
import ReusableContent from 'pages/Correspondence/ReusableContents';
import Templates from 'pages/Correspondence/Templates';
import CreateChannel from 'pages/createChannel/CreateChannel';
import EditUserRoles from 'pages/EditUserRoles';
import LogixTest from 'pages/LogixTestHarness/LogixPage';
import ManageChannel from 'pages/ManageChannel';
import ManageFormsProcessing from 'pages/ManageFormsProcessing';
import ManageRoles from 'pages/manageRoles/ManageRole';
import ManageRolesDashboard from 'pages/manageRoles/ManageRolesDashboard';
import ViewRolesDashboard from 'pages/manageRoles/ViewRolesDashboard';
import ManageUserRoles from 'pages/ManageUserRoles';
import Tenant from 'pages/Tenant';
import TenantConfig from 'pages/TenantConfig';
import TenantList from 'pages/TenantList';
import UserConfig from 'pages/UserConfig';
import { Redirect, Route, Switch } from 'react-router-dom';

function Routes(): JSX.Element {
  return (
    <Switch>
      <SecureRoute
        exact
        policy="policy19"
        path={['/dashboard', '/', '/home']}
        Component={<Dashboard />}
      />
      <SecureRoute
        policy="policy19"
        path="/userconfig"
        Component={<UserConfig />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/bulkUserUpload/"
        Component={<BulkUserUpload />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/addUser/"
        Component={<AddUser />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/manageroles/"
        Component={<ManageRolesDashboard />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/viewRolesDashboard/"
        Component={<ViewRolesDashboard />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/manageroles/create"
        Component={<ManageRoles />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/manageroles/:action/:roleId"
        Component={<ManageRoles />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/manageuserroles"
        Component={<ManageUserRoles />}
      />
      <SecureRoute
        policy="policy19"
        path="/manageuserroles/edit/:userId"
        Component={<EditUserRoles />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/manageroles/edit/addUsers/:roleId"
        Component={<AddUsers />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/tenant/view/:id"
        Component={<Tenant />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/tenantconfig"
        Component={<TenantConfig />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/tenantlist"
        Component={<TenantList />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/tenantconfig/:id"
        Component={<TenantConfig />}
      />
      <SecureRoute
        policy="policy19"
        path="/manageformsprocessing"
        Component={<ManageFormsProcessing />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/managechannels/"
        Component={<ManageChannel />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/managechannels/:action"
        Component={<CreateChannel />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/manageConfigTool/"
        Component={<ConfigRecordEditor />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/configTypeEditor/"
        Component={<ConfigTypeEditor />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/configGroupEditor/"
        Component={<ConfigGroupEditor />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/templates/"
        Component={<Templates />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/templates/create"
        Component={<ManageTemplate />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/templates/:action/:contentId"
        Component={<ManageTemplate />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/reusableContents/"
        Component={<ReusableContent />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/reusableContents/create"
        Component={<ManageReusableContent />}
      />
      <SecureRoute
        exact
        policy="policy19"
        path="/reusableContents/:action/:contentId"
        Component={<ManageReusableContent />}
      />
      {/*TODO: this is only for test in the futures will be removed */}
      <SecureRoute
        exact
        policy="policy19"
        path="/logixtestharness/"
        Component={<LogixTest />}
      />
      <Route exact path={['/welcome']}>
        <Redirect to="/welcome" />
      </Route>
    </Switch>
  );
}

export default Routes;
