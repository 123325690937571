import React, { useEffect, useState } from 'react';

import { Grid, SelectChangeEvent } from '@mui/material';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import ConnectorParameters from 'components/channelScheduling/ConnectorParameters';
import { gql } from 'graphql-request';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMaskInput from 'hooks/useMaskInput';
import useQueryRequest from 'hooks/useQueryRequest';
import { useTranslation } from 'react-i18next';
import {
  ChannelInput,
  ConnectorInfo,
  ParametersInput,
  PipelineInput,
  PipelineScheduleInput,
} from 'types/channels';
import { CmDataConnector } from 'types/graphTypes';

const channelNameQuery = gql`
  query ($module: String!, $channelName: String!) {
    CheckChannelNameExists(module: $module, channelName: $channelName)
  }
`;

const getDataConnectors = gql`
  query ($connectorType: String!) {
    getDataConnectors(connectorType: $connectorType) {
      name
      displayName
      parameters {
        name
        displayName
        dataType
        required
        description
        fieldLength
      }
    }
  }
`;

interface Props {
  pipeline: PipelineInput;
  setPipeline: React.Dispatch<React.SetStateAction<PipelineInput>>;
  channelInput: ChannelInput;
  setChannelInput: React.Dispatch<React.SetStateAction<ChannelInput>>;
  setPipelineDetailsValid: React.Dispatch<React.SetStateAction<boolean>>;
  pipelineSchedule: PipelineScheduleInput[];
  setPipelineSchedule: React.Dispatch<
    React.SetStateAction<PipelineScheduleInput[]>
  >;
  parameters: ParametersInput;
  setParameters: React.Dispatch<React.SetStateAction<ParametersInput>>;
}

function CreateChannel({
  pipeline,
  setPipeline,
  setPipelineDetailsValid,
  channelInput,
  setChannelInput,
  pipelineSchedule,
  parameters,
  setParameters,
  setPipelineSchedule,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [isCompressed, setIsCompressed] = useState(false);
  const [parametersValid, setParametersValid] = useState<boolean>(true);
  const [isEncrypted, setIsEncrypted] = useState(false);
  const [channelNameExists, setChannelNameExists] = useState<boolean>(false);

  const accessToken = useGetAccessToken();

  const pipelineNameMask = useMaskInput(
    '',
    t('pages.manageChannel.channelNameMask'),
    /^[A-Za-z0-9\s_]*$/,
    pipeline.name ?? ''
  );

  const validateRequiredFields = () => {
    let isValid = true;

    if (
      pipelineNameMask.maskError ||
      pipeline.name === '' ||
      pipeline.inputDataConnector === '' ||
      parametersValid === false
    ) {
      isValid = false;
    }

    setPipelineDetailsValid(isValid);
  };

  const handleHelperTextChange = () => {
    return pipelineNameMask.maskError;
  };

  useEffect(() => {
    validateRequiredFields();
  });

  const { data: channelNameResult, refetch: checkIfChannelNameExists } =
    useQueryRequest<{ CheckChannelNameExists: boolean }>(
      ['channelName', channelInput.channelName],
      accessToken,
      channelNameQuery,
      {
        module: channelInput.module,
        channelName: channelInput.channelName,
      }
    );

  useEffect(() => {
    if (channelNameResult) {
      setChannelNameExists(channelNameResult.CheckChannelNameExists);
    }
  }, [channelNameResult]);

  useEffect(() => {
    if (channelInput.channelName === '') {
      checkIfChannelNameExists();
    }
  }, [channelInput.channelName, checkIfChannelNameExists]);

  const { data: getDataConnectorInfo } = useQueryRequest<{
    getDataConnectors: [CmDataConnector];
  }>('connectorType', accessToken, getDataConnectors, {
    connectorType: 'Inbound',
  });
  const displayValues: SelectType[] = getDataConnectorInfo
    ? getDataConnectorInfo.getDataConnectors.map((connector) => {
        return {
          key: connector.name,
          desc: connector.displayName,
        } as unknown as SelectType;
      })
    : ([{ key: 'empty' }] as unknown as SelectType[]);

  return (
    <>
      <Grid container>
        <Grid item xs={10} mb={3}>
          <Input
            required
            id="channelName"
            label={t('pages.manageChannel.name')}
            value={pipeline.name ?? ''}
            sx={{ minWidth: '20em' }}
            helperText={
              channelNameExists
                ? t('pages.manageChannel.channelNameExists')
                : handleHelperTextChange()
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              pipelineNameMask.handleMaskChange(event);
              setPipeline({
                ...pipeline,
                name: event.target.value as string,
                id: event.target.value as string,
              });
              setChannelInput({
                ...channelInput,
                channelName: event.target.value as string,
              });
              setPipelineSchedule(
                [...pipelineSchedule].map((object) => {
                  return {
                    ...object,
                    pipelineReferenceName: event.target.value as string,
                  };
                })
              );
            }}
          />
        </Grid>
        <Grid item xs={10} mb={3}>
          <Select
            id="connector"
            value={pipeline.inputDataConnector}
            options={displayValues}
            label={t('pages.manageChannel.connector')}
            width="20em"
            required
            onChange={(event: SelectChangeEvent<string | number>) => {
              setPipeline({
                ...pipeline,
                inputDataConnector: event.target.value as string,
              });
            }}
          />
        </Grid>
        <Grid item xs={10} mb={3}>
          <ConnectorParameters
            connectorData={
              getDataConnectorInfo?.getDataConnectors as unknown as ConnectorInfo[]
            }
            connectorClicked={pipeline.inputDataConnector}
            setParameters={setParameters}
            parameters={parameters}
            setParametersValid={setParametersValid}
          />
        </Grid>
        <Grid item xs={10} mb={3}>
          <Checkbox
            id="compressed"
            checked={isCompressed}
            onChange={(event) => {
              setIsCompressed(event.target.checked);
            }}
            label={t('pages.manageChannel.isCompressed')}
          />
        </Grid>
        <Grid item xs={10} mb={3}>
          <Checkbox
            id="encrypted"
            checked={isEncrypted}
            onChange={(event) => {
              setIsEncrypted(event.target.checked);
            }}
            label={t('pages.manageChannel.isEncrypted')}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default CreateChannel;
