import { ConfigurationResponse } from 'types/graphTypes';

const configurationResponseDefault: ConfigurationResponse = {
  __typename: 'ConfigurationResponse',
  configurationDescription: '',
  configurationDomain: '',
  configurationModule: '',
  configurationName: '',
  configurationType: '',
  createDate: '',
  createdBy: '',
  isSchema: false,
  modifiedBy: '',
  modifiedDate: '',
  platformConfigurationId: '',
  platformConfigurationInfo: null,
  stateOf: '',
  tenantID: '',
};

export { configurationResponseDefault };
