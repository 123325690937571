import { useEffect, useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material/';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTable from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DefaultDataTable';
import HeaderColumn from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/HeaderColumn';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import SelectRole from 'components/SelectRole';
import AuthorizationPolicyInfo from 'components/viewRolesDashboard/AuthorizationPolicyInfo';
import UsersByRoleInfo from 'components/viewRolesDashboard/UsersByRoleInfo';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useGetRolesByModules from 'hooks/useGetRolesByModules';
import { useModulesList, useRolesByModule } from 'hooks/useManageRoles';
import useRoleUsers from 'hooks/useRoleUsers';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { AuthorizationPolicy, QueryRole } from 'types/roles';
import { QueryModule } from 'types/tenants';

function ViewRolesDashboard(): JSX.Element {
  const [allModules, setAllModules] = useState<undefined | string>(undefined);
  const { t } = useTranslation();
  useUpdateTitle(t('pages.viewRolesDashboard.title'));
  const dispatch = useAppDispatch();
  const accessToken = useGetAccessToken();
  const {
    data: moduleList,
    isError: isErrorModuleList,
    isLoading: isLoadingModuleList,
    isSuccess: isSuccessModuleList,
    refetch: refetchModuleList,
  } = useModulesList(accessToken);
  const [selectedRoleId, setSelectedRoleId] = useState<string>();
  const [show, setShow] = useState<undefined | 'users' | 'policies'>(undefined);
  const [selectedRoleIdForUsers, setSelectedRoleIdForUsers] =
    useState<string>();
  const {
    data: rolesByModule,
    refetch,
    isError: isErrorRolesByModule,
  } = useRolesByModule(accessToken, selectedRoleId);
  const {
    data: rolesByModules,
    refetch: refetchRolesByModules,
    isError: isErrorRolesByModules,
  } = useGetRolesByModules(accessToken, allModules);
  const {
    data: usersByRoles,
    refetch: refetchUsersByRole,
    isError: isErrorRoleUsersList,
  } = useRoleUsers(accessToken, selectedRoleIdForUsers, true);

  const onSelectRole = (moduleId: string) => {
    setSelectedRoleId(moduleId);
  };
  const onSelectRoleUsers = (roleId: string) => {
    setShow('users');
    setSelectedRoleIdForUsers(roleId);
  };

  /*
    Display an error if any API call fails
   */
  useEffect(() => {
    if (
      isErrorModuleList ||
      isErrorRolesByModule ||
      isErrorRolesByModules ||
      isErrorRoleUsersList
    ) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [
    isErrorModuleList,
    isErrorRolesByModule,
    isErrorRolesByModules,
    isErrorRoleUsersList,
    dispatch,
    t,
  ]);

  /*
    Display the corresponding modules based on user selection
   */
  useEffect(() => {
    if (!!accessToken) {
      refetchModuleList();
    }
  }, [refetchModuleList, accessToken]);

  /*
    Display the corresponding role data based on user role selection
   */
  useEffect(() => {
    if (!!selectedRoleId && !!accessToken && selectedRoleId !== 'all') {
      refetch();
    }
  }, [selectedRoleId, refetch, accessToken]);

  /*
    Display the corresponding users data based on role selection
   */
  useEffect(() => {
    if (!!selectedRoleIdForUsers && !!accessToken) {
      refetchUsersByRole();
    }
  }, [selectedRoleIdForUsers, refetchUsersByRole, accessToken]);

  /*
    Check if the tenant have roles assign and set 'all' as default by the first element on Module List
   */
  useEffect(() => {
    if (isSuccessModuleList && !selectedRoleId && !isEmpty(moduleList)) {
      const firstModule: QueryModule = first(moduleList) ?? ({} as QueryModule);
      if (firstModule?.moduleId) setSelectedRoleId('all');
    }
  }, [isSuccessModuleList, selectedRoleId, setSelectedRoleId, moduleList]);

  /*
    Set Modules id's to make the request
   */
  useEffect(() => {
    setAllModules(
      selectedRoleId === 'all'
        ? moduleList?.map(({ moduleId }) => moduleId).join(',')
        : undefined
    );
  }, [moduleList, selectedRoleId]);

  /*
      Display all the modules availables
     */
  useEffect(() => {
    if (!!accessToken && !!allModules) refetchRolesByModules();
  }, [accessToken, allModules, refetchRolesByModules]);

  const rolesColumns: Column[] = [
    {
      Header: (
        <HeaderColumn
          localization={`${t('pages.viewRolesDashboard.platform')}/${t(
            'pages.viewRolesDashboard.module'
          )}`}
        />
      ),
      accessor: 'moduleName',
    },
    {
      Header: (
        <HeaderColumn localization={t('pages.viewRolesDashboard.name')} />
      ),
      accessor: 'roleName',
    },
    {
      Header: (
        <HeaderColumn
          localization={t('pages.viewRolesDashboard.description')}
        />
      ),
      accessor: 'roleDescription',
    },
    {
      Header: (
        <HeaderColumn localization={t('pages.viewRolesDashboard.policies')} />
      ),
      id: 'expander',
      Cell: ({ row: { getToggleRowExpandedProps, isExpanded } }) => (
        <span {...getToggleRowExpandedProps()}>
          {isExpanded === true && show === 'policies' ? (
            <ExpandLess onClick={() => setShow(undefined)} />
          ) : (
            <ExpandMore onClick={() => setShow('policies')} />
          )}
        </span>
      ),
    },
    {
      Header: (
        <HeaderColumn localization={t('pages.viewRolesDashboard.showUsers')} />
      ),
      id: 'roleUsers',
      Cell: ({ row: { original, getToggleRowExpandedProps, isExpanded } }) => {
        const { roleId } = original as QueryRole;
        return (
          <span {...getToggleRowExpandedProps()}>
            {isExpanded === true && show === 'users' ? (
              <ExpandLess onClick={() => setShow(undefined)} />
            ) : (
              <ExpandMore onClick={() => onSelectRoleUsers(roleId)} />
            )}
          </span>
        );
      },
    },
  ];

  return (
    <Box>
      <Grid sx={{ marginBottom: '-4.2em', marginLeft: '1em' }}>
        <SelectRole
          showAll
          moduleList={moduleList}
          handleChange={onSelectRole}
          selectedRole={selectedRoleId}
        />
      </Grid>
      {isLoadingModuleList && <Loading />}
      {rolesByModule && isEmpty(rolesByModule) && <NoResults />}
      {(!isEmpty(rolesByModule) || !isEmpty(rolesByModules)) && (
        <DefaultDataTable
          columns={rolesColumns}
          data={
            (selectedRoleId !== 'all' ? rolesByModule : rolesByModules) || []
          }
          renderRowSubComponent={(row) => (
            <Box sx={{ width: '60em', margin: 'auto' }}>
              {show === 'policies' && (
                <AuthorizationPolicyInfo
                  authorizationPolicies={
                    row?.original?.authorizationPolicy as [AuthorizationPolicy]
                  }
                />
              )}
              {show === 'users' && (
                <UsersByRoleInfo usersByRole={usersByRoles || []} />
              )}
            </Box>
          )}
        />
      )}
    </Box>
  );
}

export default ViewRolesDashboard;
