import { gql } from 'graphql-request';

const modules = gql`
  query {
    Modules {
      moduleId
      name
    }
  }
`;

export const rolesById = gql`
  query ($roleId: String!) {
    RolesByModule(roleId: $roleId) {
      roleId
      statusId
      moduleId
      moduleName
      roleName
      roleDescription
      authorizationPolicy {
        authorizationPolicyId
        policyName
        policyDescription
      }
    }
  }
`;

export default modules;
