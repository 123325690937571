import { useEffect, useState } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import { Grid, Tabs, Tab } from '@mui/material';
import { Box } from '@mui/system';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import TabPanel from '@revenue-solutions-inc/revxcoreui/material/controls/TabPanel';
import Header from '@revenue-solutions-inc/revxcoreui/material/layout/Header';
import AssignedRolesProvider from 'components/contexts/AssignedRoles';
import AssignedRoles from 'components/editUserRoles/AssignedRoles';
import AvailableRoles from 'components/editUserRoles/AvailableRoles';
import Loading from 'components/Loading';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useGetUserById from 'hooks/useGetUserById';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { UserRole } from 'types/roles';

function EditUserRoles(): JSX.Element {
  const [selectedModule, setSelectedModule] = useState('admin');
  const match = useRouteMatch<{ userId: string }>();
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(
    location.query == undefined ? 0 : 1
  );
  const [tenantId, setTenantId] = useState<string | undefined>(undefined);
  const [userRole, setUserRole] = useState<UserRole | undefined>(undefined);
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.editUserRole',
  });

  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const { data, error, refetch, isFetching } = useGetUserById(
    accessToken,
    match.params.userId
  );

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    if (newValue === 0) refetch();
  };

  useEffect(() => {
    if (data) {
      const [user] = data.user;
      setTenantId(data.tenantId);
      setUserRole(user);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
        })
      );
    }
  }, [error, dispatch, t]);

  return (
    <>
      <Header
        handleModuleClick={(moduleId: string) => {
          if (moduleId !== 'admin') {
            window.location.href = `${process.env.REACT_APP_TAX_URL}/dashboard`;
          } else {
            setSelectedModule(moduleId);
          }
          return null;
        }}
        selectedModule={selectedModule}
        headerTitle={t('title')}
        headerIcon={<PersonIcon sx={{ fill: 'black', fontSize: '40px' }} />}
        dataValues={[
          <DataDisplay
            id="userNameId"
            label={t('userName')}
            data={userRole?.name || ''}
          />,

          <DataDisplay
            id="name"
            label={t('email')}
            data={userRole?.email || ''}
          />,
        ]}
        width={0}
        goBack={goBack}
        prevPageName={
          location.query == undefined ? 'Manage User Roles' : 'Add User'
        }
      />

      <Grid container sx={{ minWidth: '1000px' }}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="role Tabs"
            >
              <Tab label={t('assignedRoles')} />
              <Tab label={t('availableRoles')} />
            </Tabs>
          </Box>
          {userRole && tenantId && (
            <>
              {isFetching && <Loading />}
              <TabPanel selectedIndex={currentTab} index={0}>
                <AssignedRolesProvider
                  userRole={userRole}
                  tenantId={tenantId}
                  refetch={refetch}
                >
                  <AssignedRoles />
                </AssignedRolesProvider>
              </TabPanel>
              <TabPanel selectedIndex={currentTab} index={1}>
                <AvailableRoles
                  tenantId={tenantId}
                  userId={userRole.userId}
                  roles={userRole.userRole}
                  refetchUserRoles={refetch}
                />
              </TabPanel>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default EditUserRoles;
