import { useEffect } from 'react';

import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { ReusableContentsResponse } from 'types/graphTypes';

import { getReusableContents as getReusableContentsGraph } from './ReusableContentScript';

import DisplayRecords from '../Common/DisplayRecords';
import { EmptyReusableContentRecord } from '../Common/InitialTableRecords';

function ReusableContents(): JSX.Element {
  const { t } = useTranslation();
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  useUpdateTitle(t('pages.reusableContent.title'));
  const {
    data: reUsablecnt,
    isFetching: isFetching,
    isError: error,
    refetch,
  } = useQueryRequest<{
    Contents: [ReusableContentsResponse];
  }>(['content'], accessToken, getReusableContentsGraph, {});

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('pages.manageReusableContent.networkError'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [error, dispatch, t]);
  return (
    <>
      {isFetching && <Loading />}

      {!isFetching && reUsablecnt?.Contents && (
        <DisplayRecords
          tableData={reUsablecnt?.Contents ?? [EmptyReusableContentRecord]}
          isFromTemplate={false}
          reFetch={refetch}
        />
      )}

      {!isFetching && isEmpty(reUsablecnt?.Contents) && <NoResults />}
    </>
  );
}

export default ReusableContents;
