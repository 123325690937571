import { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import ManageConfigGroupsProvider from 'components/contexts/ManageConfigGroupsProvider';
import Loading from 'components/Loading';
import ConfigurationGroupsTableManager from 'components/manageConfigTools/ConfigurationGroupsTableManager';
import { getConfigurationGroupsQuery } from 'components/manageConfigTools/queries/getConfigurationGroupsQuery';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { ConfigurationGroupInput } from 'types/graphTypes';

function ConfigGroupEditor(): JSX.Element {
  const { t } = useTranslation();
  useUpdateTitle(t('pages.configGroupEditor.title'));
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const [configData, setConfigData] = useState<ConfigurationGroupInput[]>([]);

  const {
    isRefetching,
    isError,
    data,
    refetch: refetchTypes,
  } = useQueryRequest<{
    getConfigurationGroups: [ConfigurationGroupInput];
  }>(['getConfigurationGroups'], accessToken, getConfigurationGroupsQuery, {});

  useEffect(() => {
    if (data) setConfigData(data?.getConfigurationGroups);
  }, [data]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [dispatch, isError, t]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h2" paddingBottom="1em">
          {t('pages.configGroupEditor.configGroups')}
        </Typography>
        {isRefetching && <Loading />}
        {configData && (
          <ManageConfigGroupsProvider>
            <ConfigurationGroupsTableManager
              configData={configData}
              setConfigData={setConfigData}
              refetchTypes={refetchTypes}
            />
          </ManageConfigGroupsProvider>
        )}
      </Grid>
    </Grid>
  );
}

export default ConfigGroupEditor;
