import React, { useState } from 'react';

import BallotIcon from '@mui/icons-material/Ballot';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import FileUpload from '@revenue-solutions-inc/revxcoreui/material/controls/FileUpload';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  regexFiles: RegExp;
  titleOne: string;
  subTitleOne: string;
  titleTwo: string;
  subTitleTwo: string;
  isLink?: boolean;
  setDisplayFields: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

function FileDefintion({
  regexFiles,
  titleOne,
  subTitleOne,
  titleTwo,
  subTitleTwo,
  setUploadedFile,
  isLink = false,
  setDisplayFields,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [helperMessage, setHelperMessage] = useState<string>('');

  const helperTextChange = (value: File) => {
    const regexValidation = new RegExp(regexFiles);

    if (regexValidation.test(value.name) === false) {
      setHelperMessage(
        value.name + t('pages.manageFormsProcessing.incorrectFileUploaded')
      );
      setUploadedFile(undefined);
    } else if (regexValidation.test(value.name) === true) {
      setHelperMessage(value.name);
      setUploadedFile(value);
    }
  };

  return (
    <Grid container spacing={3} style={{ minWidth: '90em' }}>
      <Grid item xs={12} md={9}>
        <Card
          sx={{
            borderRadius: '5px',
            boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
          }}
        >
          <CardHeader
            sx={(theme: Theme) => {
              return {
                backgroundColor: theme.palette.cardHeader.main,
                '& .MuiCardHeader-title': {
                  fontSize: '0.875rem',
                  fontWeight: 700,
                },
              };
            }}
            title={t('pages.manageFormsProcessing.startSummary')}
          />
          <CardContent>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              mb={3}
            >
              <Grid item xs={1}>
                <BallotIcon />
              </Grid>
              <Grid item xs={11}>
                <Box
                  onClick={() => {
                    setDisplayFields(true);
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <Link
                    to="new"
                    style={
                      isLink === true
                        ? { textDecoration: 'none' }
                        : { pointerEvents: 'none', textDecoration: 'none' }
                    }
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      {titleOne}
                    </Typography>
                    <Typography variant="h6">{subTitleOne}</Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              mb={3}
            >
              <Grid item xs={1}>
                <CloudUploadIcon />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {titleTwo}
                </Typography>
                <Typography variant="h6">
                  {subTitleTwo}
                  <FileUpload
                    id="file-upload"
                    label={t('pages.manageFormsProcessing.start.browse')}
                    variant="text"
                    sx={{
                      color: '#000',
                      fontWeight: '700',
                      textTransform: 'lowercase',
                      padding: '0px',
                    }}
                    onChange={(event) => {
                      helperTextChange(
                        event.target.files
                          ? event.target.files[0]
                          : ({} as File)
                      );
                      event.target.value = null as unknown as string;
                    }}
                  />
                  <br></br>
                </Typography>
              </Grid>
              <Grid item ml={10}>
                <Typography
                  variant="h6"
                  sx={
                    helperMessage.includes('incorrect')
                      ? { color: 'red' }
                      : { color: 'green' }
                  }
                >
                  {helperMessage}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default FileDefintion;
