import { gql } from 'graphql-request';

export const updateConfigurationGroupQuery = gql`
  mutation Mutation($newConfigurationGroup: ConfigurationGroupInput!) {
    updateConfigurationGroup(newConfigurationGroup: $newConfigurationGroup) {
      platformConfigurationGroupId
      name
      description
    }
  }
`;
