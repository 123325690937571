import React, { useState } from 'react';

import { AuthenticatedTemplate } from '@azure/msal-react';
import ChannelCreateButton from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import TenantCreateButton from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import BatchCreateButton from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import RoleCreateButton from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import NavHeader from '@revenue-solutions-inc/revxcoreui/material/layout/Header';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

interface HeaderParams {
  drawerWidth: number;
  open: boolean;
}

function Header({ drawerWidth, open }: HeaderParams): JSX.Element {
  const [selectedModule, setSelectedModule] = useState('admin');
  const { t } = useTranslation();
  const pageTitle = useAppSelector((state) => state.content.pageTitle);
  const history = useHistory();

  const manageCustomHeader = () => {
    // TODO: what about when localised string comes
    // one soln - instead page title, use location/url of page
    switch (pageTitle) {
      case 'Tenant Onboarding':
        return (
          <TenantCreateButton
            id="createTenant_header"
            onClick={() => history.push('/tenantconfig')}
            type="primary"
          >
            {t('pages.tenantConfig.buttons.create')}
          </TenantCreateButton>
        );
      case 'Manage Batches':
        return (
          <BatchCreateButton
            id="createBatch_header"
            onClick={() => history.push('/batch')}
            type="primary"
          >
            {t('pages.createBatch.buttons.create')}
          </BatchCreateButton>
        );
      case 'Manage Channels':
        return (
          <ChannelCreateButton
            id="createChannel_header"
            onClick={() => history.push('/managechannels/create')}
            type="primary"
          >
            {t('pages.manageChannel.buttons.create')}
          </ChannelCreateButton>
        );

      case 'Manage Roles':
        return (
          <RoleCreateButton
            id="createRole_header"
            onClick={() => history.push('/manageRoles/create')}
            type="primary"
          >
            {t('pages.manageRoles.buttons.create')}
          </RoleCreateButton>
        );
      default:
        return false;
    }
  };

  return (
    <NavHeader
      headerTitle={pageTitle}
      handleModuleClick={(moduleId: string) => {
        if (moduleId !== 'admin') {
          window.location.href = `${process.env.REACT_APP_TAX_URL}/dashboard`;
        } else {
          setSelectedModule(moduleId);
        }
        return null;
      }}
      selectedModule={selectedModule}
      button={
        <AuthenticatedTemplate>{manageCustomHeader()}</AuthenticatedTemplate>
      }
      width={drawerWidth}
      open={open}
      prevPageName={'Home'}
      currentPageLocation={history.location.pathname}
      goBack={() => {
        history.push('/home');
      }}
    />
  );
}

export default Header;
