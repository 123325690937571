import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { TenantConsole } from 'types/tenants';

const fetchTenantConsoleList = async (): Promise<TenantConsole[]> => {
  const result = await axios.get(
    `${process.env.REACT_APP_TENANT}/tenantquery/tenantsConsole`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result.data;
};

export default function useTenantList(): UseQueryResult<
  TenantConsole[],
  Error
> {
  return useQuery<TenantConsole[], Error>(['TenantsConsole'], () =>
    fetchTenantConsoleList()
  );
}
