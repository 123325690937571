import axios from 'axios';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

interface Params {
  [key: string]: boolean | string | number | object;
}

const validateInputs = function (params: Params, validateList: string) {
  let isValid = true;
  if (validateList) {
    isValid = params[validateList] !== '' && params[validateList] !== undefined;
  }
  return isValid;
};
const getHeader = function (token: string | undefined) {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

const fetchQuery = async <T>(
  token: string | undefined,
  query: string,
  params: Params
): Promise<T> => {
  const result = await axios.post(
    `${process.env.REACT_APP_GRAPH}`,
    {
      query,
      variables: { ...params },
    },
    {
      headers: getHeader(token),
    }
  );
  return result.data.data;
};

export default function useQueryRequest<T>(
  queryId: QueryKey,
  accessToken: string | undefined,
  query: string,
  params: Params,
  validatedParams = ''
): UseQueryResult<T, Error> {
  const paramsValid = validatedParams
    ? validateInputs(params, validatedParams)
    : true;
  return useQuery<T, Error>(
    [queryId],
    () => {
      return fetchQuery<T>(accessToken, query, params);
    },
    {
      enabled: !!accessToken && paramsValid,
    }
  );
}
