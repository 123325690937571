import { gql } from 'graphql-request';

const createReusableContent = gql`
  mutation CreateReusableContent($content: ReusableContent!) {
    CreateReusableContent(content: $content) {
      reusableContentId
      fixedId
      title
      contentType
      status
      version
      contentOpenXml
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;

const updateReusableContent = gql`
  mutation UpdateReusableContent($content: ReusableContent!) {
    UpdateReusableContent(content: $content) {
      reusableContentId
      fixedId
      title
      contentType
      status
      version
      contentOpenXml
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;

const getReusableContent = gql`
  query Query($reusableContentId: String!) {
    ReusableContent(id: $reusableContentId) {
      reusableContentId
      fixedId
      title
      contentType
      status
      version
      contentOpenXml
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;

const getReusableContentHistory = gql`
  query ReusableContentHistory($reusableContentHistoryId: String!) {
    ReusableContentHistory(id: $reusableContentHistoryId) {
      reusableContentId
      fixedId
      title
      contentType
      status
      version
      contentOpenXml
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;

export {
  createReusableContent,
  updateReusableContent,
  getReusableContent,
  getReusableContentHistory,
};
