import { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { ListBox } from '@revenue-solutions-inc/revxcoreui';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import ManageConfigToolsProvider from 'components/contexts/ManageConfigToolsProvider';
import Loading from 'components/Loading';
import { getConfigurationsRecordsQuery } from 'components/manageConfigTools/queries/getConfigurationRecordsQuery';
import { getConfigurationTypesQuery } from 'components/manageConfigTools/queries/getConfigurationTypesQuery';
import TableManager from 'components/manageConfigTools/TableManager';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { ConfigurationResponse } from 'types/graphTypes';

function ConfigRecordEditor(): JSX.Element {
  const { t } = useTranslation();
  useUpdateTitle(t('pages.configRecordEditor.title'));
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const [configTypeList, setConfigList] = useState<ConfigurationResponse[]>([]);
  const [selectedConfigType, setSelectedConfigType] =
    useState<ConfigurationResponse>();
  const [configData, setConfigData] = useState<ConfigurationResponse[]>([]);
  const {
    isLoading,
    isError,
    data: configListData,
  } = useQueryRequest<{
    getConfigurationTypes: [ConfigurationResponse];
  }>(['getConfigurationLookupTypes'], accessToken, getConfigurationTypesQuery, {
    configurationDomain: '7',
  });
  const {
    isRefetching,
    data,
    refetch: refetchTypes,
  } = useQueryRequest<{
    getConfigurations: [ConfigurationResponse];
  }>(
    ['getConfigurations', selectedConfigType],
    accessToken,
    getConfigurationsRecordsQuery,
    {
      configurationModule: selectedConfigType?.configurationModule ?? '0',
      configurationType: selectedConfigType?.configurationType ?? '',
    }
  );

  useEffect(() => {
    if (data) setConfigData(data.getConfigurations);
    else setConfigData([]);
  }, [data]);

  useEffect(() => {
    if (configListData) setConfigList(configListData.getConfigurationTypes);
  }, [configListData]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [dispatch, isError, t]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={3}>
        <Typography variant="h2" paddingBottom="1em">
          {t('pages.configRecordEditor.configList')}
        </Typography>
        {isLoading && <Loading />}
        {!isLoading && configTypeList.length > 0 && (
          <ListBox
            id="configList-recordeditor"
            options={configTypeList.map((ct) => ct.configurationType)}
            onChange={(configType: string) => {
              setSelectedConfigType(
                configTypeList.find((ct) => ct.configurationType === configType)
              );
              refetchTypes();
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={9}>
        <Typography variant="h2" paddingBottom="1em">
          {t('pages.configRecordEditor.configRecords')}
        </Typography>
        {isRefetching && <Loading />}
        {selectedConfigType && configData && (
          <ManageConfigToolsProvider>
            <TableManager
              configData={configData}
              setConfigData={setConfigData}
              refetchTypes={refetchTypes}
              selectedConfigType={selectedConfigType}
            />
          </ManageConfigToolsProvider>
        )}
      </Grid>
    </Grid>
  );
}

export default ConfigRecordEditor;
