import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, Grid, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { HeaderColumn } from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import DefaultDataTable from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DefaultDataTable';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import TabPanel from '@revenue-solutions-inc/revxcoreui/material/controls/TabPanel';
import TextArea from '@revenue-solutions-inc/revxcoreui/material/controls/TextArea';
import Header from '@revenue-solutions-inc/revxcoreui/material/layout/Header';
import Loading from 'components/Loading';
import SideScrollBox from 'components/SideScrollBox';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMutationRequest from 'hooks/useMutationRequest';
import useQueryRequest from 'hooks/useQueryRequest';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Column, CellProps } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  ModuleResponse,
  PolicyResponse,
  RoleResponse,
  CreateRoleInput,
  User,
  UpdateRoleInput,
} from 'types/graphTypes';
import { roleDefault } from 'types/roles';
import { RoleStatus } from 'types/Status/roleStatus';

import policies, {
  doesRoleExist,
  role as roleGraph,
  createRole as createRoleGraph,
  roleUsers,
  updateRole as updateRoleGraph,
} from './ManageRoleScript';

import modules from '../ManageRolesDashboard/manageRolesDashboardScripts';

enum ActionType {
  EDIT = 'edit',
  CLONE = 'clone',
  CREATE = 'create',
}

function ManageRole(): JSX.Element {
  const [selectedModule, setSelectedModule] = useState('admin');
  const { t } = useTranslation();
  const [role, setRole] = useState<RoleResponse>(roleDefault);
  const match = useRouteMatch<{ roleId: string; action: string }>();
  const [isRoleValid, setIsRoleValid] = useState(false);
  const [roleExists, setRoleExists] = useState(false);
  const [moduleName, setModuleName] = useState('');
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { data: moduleResult, isError } = useQueryRequest<{
    Modules: [ModuleResponse];
  }>('modules', accessToken, modules, {});
  const { data: policiesResult, isError: isPolicyError } = useQueryRequest<{
    PoliciesByModule: [PolicyResponse];
  }>(
    ['policies', role.moduleId],
    accessToken,
    policies,
    {
      moduleId: role.moduleId.toString(),
    },
    'moduleId'
  );

  const {
    data: roleExistsResult,
    refetch: refetchRoleExists,
    isError: roleExistsError,
  } = useQueryRequest<{ RoleExists: boolean }>(
    ['RoleExists', role.moduleId, `${moduleName} ${role.roleName}`],
    accessToken,
    doesRoleExist,
    {
      moduleId: role.moduleId,
      roleName: `${role.roleName}`.trim(),
    },
    'roleName'
  );

  const { data: roleResult } = useQueryRequest<{ Role: RoleResponse }>(
    ['role', match.params.roleId],
    accessToken,
    roleGraph,
    {
      roleId: match.params.roleId,
    },
    'roleId'
  );

  const { data: usersData, isFetching } = useQueryRequest<{
    UsersByRole: [User];
  }>(
    ['roleUsers', match.params.roleId],
    accessToken,
    roleUsers,
    {
      userId: match.params.roleId,
      isAssigned: true,
    },
    'userId'
  );

  const [currentTab, setCurrentTab] = useState(0);
  const createRole = useMutationRequest<CreateRoleInput>();
  const updateRole = useMutationRequest<UpdateRoleInput>();

  const goBack = () => {
    history.goBack();
  };

  const policyColumns: Column[] = [
    {
      Header: () => {
        return (
          <Box sx={{ width: 100 }}>
            <Checkbox
              id={'selectAllCheck'}
              label={t('pages.manageRole.selectAll')}
              checked={
                role.authorizationPolicy.length ===
                policiesResult?.PoliciesByModule?.length
              }
              onChange={(event) => {
                let newPolicies = [...role.authorizationPolicy];
                if (event.target.checked && policiesResult?.PoliciesByModule)
                  newPolicies = policiesResult?.PoliciesByModule?.map((p) => {
                    return {
                      authorizationPolicyId: p.authorizationPolicyId,
                      policyName: '',
                      policyDescription: '',
                    };
                  });
                else newPolicies = [];
                setRole({ ...role, authorizationPolicy: newPolicies });
              }}
            />
          </Box>
        );
      },
      accessor: 'authorizationPolicyId',
      Cell: (props: CellProps<Record<string, unknown>, string>) => {
        return (
          <Checkbox
            id={'policyChk'}
            checked={
              //intentional == here comparing strings to numbers
              role.authorizationPolicy.findIndex(
                (p) => p.authorizationPolicyId == parseInt(props.value)
              ) > -1
            }
            label={''}
            onChange={(event) => {
              let newPolicies = [...role.authorizationPolicy];
              if (event.target.checked)
                newPolicies.push({
                  authorizationPolicyId: parseInt(props.value),
                  policyName: '',
                  policyDescription: '',
                });
              else
                newPolicies = newPolicies.filter(
                  (p) => p.authorizationPolicyId != parseInt(props.value)
                );
              setRole({ ...role, authorizationPolicy: newPolicies });
            }}
          />
        );
      },
      disableSortBy: true,
    },
    {
      Header: <HeaderColumn localization={t('pages.manageRole.policyName')} />,
      accessor: 'policyName',
    },
    {
      Header: (
        <HeaderColumn localization={t('pages.manageRole.policyDescription')} />
      ),
      accessor: 'policyDescription',
    },
  ];

  const userColumns = [
    {
      Header: t('pages.manageRole.userName'),
      accessor: 'name',
    },
    {
      Header: t('pages.manageRole.userEmail'),
      accessor: 'email',
    },
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkRoleExists = useCallback(
    debounce(() => {
      refetchRoleExists();
    }, 200),
    [refetchRoleExists]
  );

  useEffect(() => {
    if (roleResult?.Role) {
      const tempRole = roleResult.Role;
      // TODO parse out module name from the role name because it will be added back in on save.
      setRole({
        roleId: tempRole.roleId,
        roleName:
          match.params.action === ActionType.CLONE
            ? `Clone of ${tempRole.roleName}`
            : tempRole.roleName,
        statusId:
          match.params.action === ActionType.CLONE
            ? RoleStatus.Inactive
            : tempRole.statusId,
        moduleId: tempRole.moduleId,
        moduleName: '',
        roleDescription: tempRole.roleDescription,
        authorizationPolicy: tempRole.authorizationPolicy.map((ap) => {
          return {
            authorizationPolicyId: ap.authorizationPolicyId,
            policyDescription: '',
            policyName: '',
          };
        }),
      });
      setModuleName(tempRole.moduleName);
    }
  }, [match.params.action, roleResult?.Role]);

  useEffect(() => {
    let isValid = !!role.roleName && !!role.moduleId && !roleExists;

    if (isValid && !!accessToken) checkRoleExists();

    isValid = isValid && role.authorizationPolicy.length > 0;
    setIsRoleValid(isValid);
  }, [accessToken, checkRoleExists, role, roleExists]);

  useEffect(() => {
    if (roleExistsResult?.RoleExists === true) {
      if (
        match.params.action === ActionType.EDIT &&
        moduleName + ' ' + role.roleName === roleResult?.Role?.roleName
      ) {
        setRoleExists(false);
      } else {
        setRoleExists(true);
        setIsRoleValid(false);
      }
    } else setRoleExists(false);
  }, [
    match.params.action,
    moduleName,
    role.roleName,
    roleExistsResult?.RoleExists,
    roleResult?.Role?.roleName,
  ]);

  useEffect(() => {
    if (moduleResult?.Modules && moduleResult.Modules.length > 0)
      setModuleName(moduleResult.Modules[0].name);
  }, [moduleResult?.Modules]);

  useEffect(() => {
    if (isError)
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
  }, [isError, dispatch, t]);

  useEffect(() => {
    if (isPolicyError)
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
  }, [isPolicyError, dispatch, t]);

  useEffect(() => {
    if (roleExistsError)
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
  }, [roleExistsError, dispatch, t]);

  const handleBack = () => {
    history.push('/manageroles');
  };

  const handleSaveRole = (createAnother = false) => {
    if (match.params.action === ActionType.EDIT) {
      updateRole.mutate(
        {
          mutationKey: ['updateRole', role.roleId],
          query: updateRoleGraph,
          token: accessToken,
          params: {
            roleId: role.roleId,
            moduleId: role.moduleId,
            roleName: role.roleName,
            description: role.roleDescription,
            statusId: role.statusId,
            authorizationPolicyIds: role.authorizationPolicy.map((policy) => {
              return policy.authorizationPolicyId;
            }),
          },
          paramsId: 'role',
        },
        {
          // content: 'pages.manageRole.successMessage',
          onSuccess: () => {
            dispatch(
              addMessage({
                message: t('pages.manageRole.successMessage'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            if (createAnother) {
              setRole(roleDefault);
            } else handleBack();
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('components.message.networkerror'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    } else {
      setRole({ ...role, roleId: '' });
      createRole.mutate(
        {
          mutationKey: 'saveRole',
          params: {
            moduleId: role.moduleId,
            roleName: role.roleName,
            description: role.roleDescription,
            statusId: role.statusId,
            authorizationPolicyIds: role.authorizationPolicy.map((policy) => {
              return policy.authorizationPolicyId;
            }),
          },
          query: createRoleGraph,
          token: accessToken,
          paramsId: 'role',
        },
        {
          onSuccess: () => {
            dispatch(
              addMessage({
                message: t('pages.manageRole.successMessage'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            if (createAnother) {
              setRole(roleDefault);
            } else handleBack();
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('components.message.networkerror'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const getTitle = () => {
    let title = 'Manage Role';
    switch (match.params.action) {
      case ActionType.EDIT:
        title = 'pages.manageRole.editRole';
        break;
      case ActionType.CLONE:
        title = 'pages.manageRole.cloneRole';
        break;
      default:
        title = 'pages.manageRole.createRole';
        break;
    }
    return t(title);
  };

  return (
    <>
      {match.params.action === ActionType.EDIT && (
        <Header
          handleModuleClick={(moduleId: string) => {
            if (moduleId !== 'admin') {
              window.location.href = `${process.env.REACT_APP_TAX_URL}/dashboard`;
            } else {
              setSelectedModule(moduleId);
            }
            return null;
          }}
          selectedModule={selectedModule}
          headerTitle={getTitle()}
          headerIcon={
            <AssignmentTurnedInIcon sx={{ fill: 'black', fontSize: '40px' }} />
          }
          dataValues={[
            <DataDisplay
              id="module-header"
              label={t('pages.manageRole.module')}
              data={moduleName}
            />,

            <DataDisplay
              id="name-header"
              label={t('pages.manageRole.roleName')}
              data={role.roleName ?? ''}
            />,

            <DataDisplay
              id="description-header"
              label={t('pages.manageRole.roleDescription')}
              data={role.roleDescription ?? ''}
            />,
          ]}
          prevPageName={t('pages.manageRoles.title')}
          width={0}
          goBack={goBack}
        />
      )}
      {match.params.action !== ActionType.EDIT && (
        <Header
          selectedModule={'admin'}
          handleModuleClick={() => {
            return null;
          }}
          headerTitle={getTitle()}
          prevPageName={t('pages.manageRoles.title')}
          width={0}
          goBack={goBack}
        />
      )}
      <Grid container>
        <Grid item xs={4}>
          <Grid item xs={10} mb={2}>
            <Select
              id="roleModule-createRole"
              label={t('pages.manageRole.module')}
              required
              inputProps={{ 'data-testid': 'roleModule-select' }}
              options={
                moduleResult &&
                moduleResult.Modules &&
                Array.isArray(moduleResult.Modules)
                  ? moduleResult?.Modules?.map((m) => {
                      return { key: m.moduleId, desc: m.name };
                    })
                  : [{ key: '', desc: 'Select Module' }]
              }
              value={role.moduleId}
              onChange={(event: SelectChangeEvent<string | number>) => {
                setRole({
                  ...role,
                  authorizationPolicy: [],
                  moduleId: event.target.value as number,
                });
                setModuleName(
                  moduleResult?.Modules?.find(
                    (m) => m.moduleId == event.target.value
                  )?.name ?? ''
                );
              }}
              width="20em"
              disabled={
                match.params.action === ActionType.EDIT ||
                match.params.action === ActionType.CLONE
              }
            />
          </Grid>
          <Grid item xs={10} mb={2}>
            <Input
              id="roleName-createRole"
              data-testid="roleName-input"
              label={t('pages.manageRole.roleName')}
              required
              inputProps={{ 'data-testid': 'roleName-input', maxLength: 100 }}
              value={role?.roleName ?? ''}
              onChange={(event) => {
                setRole({ ...role, roleName: event.target.value });
              }}
              error={roleExists}
              helperText={
                roleExists ? t('pages.manageRole.roleExistsMessage') : ''
              }
              sx={{ width: '20em' }}
            />
          </Grid>
          <Grid item xs={10} mb={2}>
            <TextArea
              id="roleDescription-createRole"
              label={t('pages.manageRole.roleDescription')}
              value={role?.roleDescription ?? ''}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                setRole({ ...role, roleDescription: event.target.value })
              }
              multiline
              sx={{ width: '20em' }}
              inputProps={{
                maxLength: 500,
              }}
            />
          </Grid>
          <Grid item xs={10} mb={2}>
            {role.statusId === RoleStatus.Active && <LockIcon />}
            {role.statusId === RoleStatus.Inactive && <LockOpenIcon />}
          </Grid>
          <Grid item xs={10} mb={2}>
            {role.authorizationPolicy.length > 0 && (
              <>
                <SideScrollBox
                  title={t('pages.manageRole.selectedPolicies')}
                  options={role.authorizationPolicy.map((policyId) => {
                    const name =
                      policiesResult?.PoliciesByModule?.find(
                        (p) =>
                          p.authorizationPolicyId ===
                          policyId.authorizationPolicyId
                      )?.policyName ??
                      policyId.authorizationPolicyId.toString();
                    return { key: policyId.authorizationPolicyId, name: name };
                  })}
                  id="policy-box"
                  scrollBoxValues={role.authorizationPolicy.map((policy) => {
                    return policy.authorizationPolicyId.toString();
                  })}
                  onChangeState={setRole}
                  onChangeValue={role}
                />
              </>
            )}
          </Grid>
          <Grid item xs={10} mb={1}>
            {match.params.action === ActionType.EDIT &&
              isRoleValid &&
              usersData &&
              role.statusId === RoleStatus.Inactive &&
              usersData?.UsersByRole.length > 0 && (
                <Box>{t('pages.manageRole.userWarning')}</Box>
              )}
            <Button
              onClick={() => handleSaveRole()}
              id="saveBtn-createRole"
              data-testid="save-button"
              sx={{ mt: 1, mb: 1 }}
              disabled={!isRoleValid || role.statusId === RoleStatus.Active}
            >
              {t('pages.manageRole.save')}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Box sx={{ borderColor: 'divider' }}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="role Tabs"
            >
              <Tab label={t('pages.manageRole.assignedPolicies')} />
              {/* Only show assigned users on edit */}
              {match.params.action === ActionType.EDIT && (
                <Tab label={t('pages.manageRole.assignedUsers')} />
              )}
            </Tabs>
          </Box>
          <TabPanel selectedIndex={currentTab} index={0}>
            {isFetching && <Loading />}
            {role.moduleId && policiesResult?.PoliciesByModule && (
              <div data-testid="authorization-policies-data">
                <DefaultDataTable
                  columns={policyColumns}
                  data={policiesResult?.PoliciesByModule}
                  data-testid="authorization-policies-data"
                  tableName={t('pages.manageRole.platformPolicies')}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel selectedIndex={currentTab} index={1}>
            {isFetching && <Loading />}
            {usersData && (
              <DefaultDataTable
                columns={userColumns}
                data={usersData.UsersByRole}
              />
            )}
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
}

export default ManageRole;
