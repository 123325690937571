import { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';

function useGetAccessToken(): string | undefined {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (inProgress === 'none') {
      if (instance && accounts && accounts.length) {
        instance
          .acquireTokenSilent({
            scopes: [
              'openid',
              `${process.env.REACT_APP_AUTH_TOKEN_ACQUISITION}`,
            ],
            account: accounts[0],
          })
          .then((response: { accessToken: string }) => {
            setAccessToken(response.accessToken);
          })
          .catch(() => {
            dispatch(
              addMessage({
                message: 'User Token Expired Please Log In Again',
                type: MessageType.Error,
                actionType: MessageActionType.Continue,
              })
            );
          });
      }
    }
  }, [instance, accounts, inProgress, dispatch]);
  return accessToken;
}

export default useGetAccessToken;
