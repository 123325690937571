import React, { useEffect } from 'react';

import { Box, SelectChangeEvent } from '@mui/material';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import useMaskInput from 'hooks/useMaskInput';
import { useTranslation } from 'react-i18next';
import { PipelineScheduleInput } from 'types/channels';

// TODO : replace with lookup
const FrequencyValues: SelectType[] = [
  { key: 'Minute', desc: 'Minute' },
  { key: 'Hour', desc: 'Hour' },
  { key: 'Day', desc: 'Day' },
  { key: 'Week', desc: 'Week' },
  { key: 'Month', desc: 'Month' },
];

interface Props {
  setPipelineSchedule: React.Dispatch<
    React.SetStateAction<PipelineScheduleInput[]>
  >;
  pipelineSchedule: PipelineScheduleInput[];
  setIntervalValid: React.Dispatch<React.SetStateAction<boolean>>;
}

function Frequency({
  setPipelineSchedule,
  pipelineSchedule,
  setIntervalValid,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const intervalMinuteMask = useMaskInput(
    '999999',
    t('pages.manageChannel.minuteRange'),
    /^(?:[1-9]|[1-9][0-9]{1,4}|[1-6][0-9]{5}|7[01][0-9]{4}|720000)$/,
    pipelineSchedule[0].interval === null
      ? ''
      : pipelineSchedule[0].interval.toString()
  );

  const intervalHourMask = useMaskInput(
    '999999',
    t('pages.manageChannel.hourRange'),
    /^([1-9]|[1-9][0-9]{1,3}|1[01][0-9]{3}|12000)$/,
    pipelineSchedule[0].interval === null
      ? ''
      : pipelineSchedule[0].interval.toString()
  );

  const intervalDayMask = useMaskInput(
    '999999',
    t('pages.manageChannel.dayRange'),
    /^([1-9]|[1-9][0-9]|[1-4][0-9]{2}|500)$/,
    pipelineSchedule[0].interval === null
      ? ''
      : pipelineSchedule[0].interval.toString()
  );

  const intervalWeekMask = useMaskInput(
    '999999',
    t('pages.manageChannel.weekRange'),
    /^(?:[1-9]|[1-6][0-9]|7[01])$/,
    pipelineSchedule[0].interval === null
      ? ''
      : pipelineSchedule[0].interval.toString()
  );

  const intervalMonthMask = useMaskInput(
    '999999',
    t('pages.manageChannel.monthRange'),
    /^(?:[1-9]|1[0-6])$/,
    pipelineSchedule[0].interval === null
      ? ''
      : pipelineSchedule[0].interval.toString()
  );
  const handleHelperIntervalChange = () => {
    if (pipelineSchedule[0].frequency === 'Minute') {
      return intervalMinuteMask.maskError;
    } else if (pipelineSchedule[0].frequency === 'Hour') {
      return intervalHourMask.maskError;
    } else if (pipelineSchedule[0].frequency === 'Day') {
      return intervalDayMask.maskError;
    } else if (pipelineSchedule[0].frequency === 'Week') {
      return intervalWeekMask.maskError;
    }
    return intervalMonthMask.maskError;
  };

  const validateRequiredFields = () => {
    let isValid = true;

    if (
      (pipelineSchedule[0].frequency === 'Minute' &&
        intervalMinuteMask.maskError) ||
      (pipelineSchedule[0].frequency === 'Hour' &&
        intervalHourMask.maskError) ||
      (pipelineSchedule[0].frequency === 'Day' && intervalDayMask.maskError) ||
      (pipelineSchedule[0].frequency === 'Week' &&
        intervalWeekMask.maskError) ||
      (pipelineSchedule[0].frequency === 'Month' && intervalMonthMask.maskError)
    ) {
      isValid = false;
    }

    setIntervalValid(isValid);
  };

  useEffect(() => {
    validateRequiredFields();
  });

  return (
    <Box
      sx={{
        display: 'flex',
        width: '42em',
        justifyContent: 'space-between',
      }}
    >
      <Select
        id="channelRecurrence"
        value={pipelineSchedule[0].frequency}
        options={FrequencyValues}
        inputProps={{ 'data-testid': 'frequency-select' }}
        label={t('pages.manageChannel.frequency')}
        width="20em"
        required
        onChange={(event: SelectChangeEvent<string | number>) => {
          setPipelineSchedule(
            [...pipelineSchedule].map((object) => {
              return {
                ...object,
                frequency: event.target.value as string,
              };
            })
          );
        }}
      />

      <Input
        required
        id="channelEvery"
        label={t('pages.manageChannel.every')}
        inputProps={{ 'data-testid': 'frequency-input' }}
        value={
          pipelineSchedule[0].interval === null
            ? ''
            : pipelineSchedule[0].interval.toString()
        }
        sx={{ width: '20em' }}
        helperText={handleHelperIntervalChange()}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          intervalMinuteMask.handleMaskChange(event);
          intervalHourMask.handleMaskChange(event);
          intervalDayMask.handleMaskChange(event);
          intervalWeekMask.handleMaskChange(event);
          intervalMonthMask.handleMaskChange(event);
          setPipelineSchedule(
            [...pipelineSchedule].map((object) => {
              return {
                ...object,
                interval:
                  event.target.value === ''
                    ? null
                    : parseInt(event.target.value),
              };
            })
          );
        }}
      />
    </Box>
  );
}
export default Frequency;
