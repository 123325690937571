import { useEffect } from 'react';

import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { TemplateResponse } from 'types/graphTypes';

import { getTemplates as getTemplatesGraph } from './TemplateScript';

import DisplayRecords from '../Common/DisplayRecords';
import { EmptyTemplateRecord } from '../Common/InitialTableRecords';

function Templates(): JSX.Element {
  const { t } = useTranslation();
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();

  useUpdateTitle(t('pages.templates.title'));

  const {
    data: templateData,
    isFetching: isFetching,
    isError: error,
    refetch,
  } = useQueryRequest<{
    TemplatesList: [TemplateResponse];
  }>(['content'], accessToken, getTemplatesGraph, {});

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('pages.manageReusableContent.networkError'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [error, dispatch, t]);

  return (
    <>
      {isFetching && <Loading />}

      {!isFetching && templateData?.TemplatesList && (
        <DisplayRecords
          tableData={templateData?.TemplatesList ?? [EmptyTemplateRecord]}
          isFromTemplate={true}
          reFetch={refetch}
        />
      )}

      {!isFetching && isEmpty(templateData?.TemplatesList) && <NoResults />}
    </>
  );
}

export default Templates;
