export interface ParameterInfo {
  name: string;
  displayName: string;
  dataType: string;
  required: boolean;
  description: string;
  fieldLength: number;
}

export interface ConnectorInfo {
  name: string;
  displayName: string;
  parameters: ParameterInfo[];
}

export interface Channel {
  channel: ChannelInput;
}

export interface ChannelInput {
  channelName: string;
  pipeline: PipelineInput;
  module: string;
  fileGroup: FileGroupInput;
  isCompressed: boolean;
  isEncrypted: boolean;
}

export interface FileGroupInput {
  fileGroupName: string;
  fileGroupType: string;
  fileType: string;
  fileProcessingType: string;
  files: FileInput[];
}

export interface FileInput {
  fileName: string;
  fileMetadata: string;
  fileProcessingOrder: number | null;
}

export interface PipelineInput {
  id: string;
  name: string;
  activityName: string;
  inputDataConnector: string;
  pipelineSchedule: PipelineScheduleInput[];
  parameters: ParametersInput;
}

export interface PipelineScheduleInput {
  id: string;
  name: string;
  startTime: Date | null;
  endTime: Date | null;
  interval: number | null;
  frequency: string;
  pipelineReferenceName: string;
  parameters: ParametersInput;
  recurrenceSchedule: RecurrenceScheduleInput;
}

export interface RecurrenceScheduleInput {
  minutes: number[];
  hours: number[];
  weekDays: number[];
  monthDays: number[];
  // monthlyOccurrences: MonthlyOccurrencesInput[];
}

export interface ParametersInput {
  [Key: string]: string;
}

export interface MonthlyOccurrencesInput {
  day: number;
  occurrence: number;
}

// TODO: implement this later once we figure out how we are gonna handle monthlyOccurences

// const monthlyOccurrencesDefault: MonthlyOccurrencesInput = {
//   day: 0,
//   occurrence: 0,
// };

const recurrenceScheduleDefault: RecurrenceScheduleInput = {
  minutes: [],
  hours: [],
  weekDays: [],
  monthDays: [],
  // monthlyOccurrences: [monthlyOccurrencesDefault],
};

const parametersDefault: ParametersInput = {};

const pipelineScheduleDefault: PipelineScheduleInput = {
  id: '',
  name: '',
  startTime: new Date(),
  endTime: null,
  interval: 15,
  frequency: 'Minute',
  pipelineReferenceName: '',
  parameters: parametersDefault,
  recurrenceSchedule: recurrenceScheduleDefault,
};

const pipelineDefault: PipelineInput = {
  id: '',
  name: '',
  activityName: 'inbound',
  inputDataConnector: '',
  pipelineSchedule: [pipelineScheduleDefault],
  parameters: parametersDefault,
};

const fileInputDefault: FileInput = {
  fileName: '',
  fileMetadata: '',
  fileProcessingOrder: null,
};

const fileGroupDefault: FileGroupInput = {
  fileGroupName: '',
  fileGroupType: '',
  fileType: '',
  fileProcessingType: '',
  files: [fileInputDefault],
};

const channelDefault: ChannelInput = {
  channelName: 'testing',
  pipeline: pipelineDefault,
  module: 'Property',
  fileGroup: fileGroupDefault,
  isCompressed: true,
  isEncrypted: true,
};

const channelObject: Channel = {
  channel: channelDefault,
};

export {
  channelDefault,
  channelObject,
  pipelineDefault,
  pipelineScheduleDefault,
  recurrenceScheduleDefault,
  parametersDefault,
  fileGroupDefault,
  fileInputDefault,
};
