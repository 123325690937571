import { Box, CardActions, Typography } from '@mui/material';
import BasicTable from '@revenue-solutions-inc/revxcoreui/material/controls/BasicTable';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { User, UserResponse } from 'types/roles';

const columns = [
  {
    Header: 'First Name',
    accessor: 'firstName',
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
  },
  {
    Header: 'User Email',
    accessor: 'userEmail',
  },
];

interface Props {
  userValues: User[];
  setSummary: React.Dispatch<React.SetStateAction<boolean>>;
  dataResponse: UserResponse | undefined;
}

export default function AddUserSummary({
  userValues,
  setSummary,
  dataResponse,
}: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.addUser',
  });

  return (
    <>
      <Typography color="text.primary" sx={{ mb: 1 }}>
        {t('userAdded')}
      </Typography>
      <Box sx={{ ml: '1.5em', mb: '1.5em' }}>
        <BasicTable data={userValues} columns={columns} />
      </Box>
      <CardActions sx={{ justifyContent: 'flex-end', py: 3, px: 2 }}>
        <Button id="assignRoleButton">
          <Link
            style={{ color: '#fff', textDecoration: 'none' }}
            to={{
              pathname: `/manageuserroles/edit/${dataResponse?.userId}`,
              query: { from: 'addUserSummary' },
            }}
          >
            {t('buttons.assignRole')}
          </Link>
        </Button>
        <Button
          id="createAnotherNewUserButton"
          onClick={() => {
            setSummary(false);
          }}
        >
          {t('buttons.createAnotherNewUser')}
        </Button>
        <Button id="cancelButton">
          <Link
            style={{ color: '#fff', textDecoration: 'none' }}
            to={{
              pathname: `/dashboard`,
            }}
          >
            {t('buttons.cancel')}
          </Link>
        </Button>
      </CardActions>
    </>
  );
}
