import { gql } from 'graphql-request';

export const getConfigurationTypesQuery = gql`
  query ($configurationDomain: String!) {
    getConfigurationTypes(configurationDomain: $configurationDomain) {
      platformConfigurationId
      configurationName
      configurationDescription
      configurationModule
      configurationDomain
      configurationType
      platformConfigurationInfo {
        platformConfigurationId
        configurationSection {
          configurationTemplate
          configurationSectionOrder
          configurationSectionName
          group {
            groupName
            groupOrder
            groupAttributeType
            repeatingActionLabel
            attribute {
              attributeName
              attributeDisplayName
              attributeType
              dataSource
              repeatingProperties
              possibleValues
              attributeValue
              layoutInfo {
                layoutOrder
                layoutStyle
                format
                mask
                validation
              }
              extensibleBusinessDriver {
                driverNameType
                driverName
                driverDataType
                driverValue
              }
            }
          }
        }
      }
    }
  }
`;
