import React from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function ActionChannel(): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box>
        <Button
          id="action-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </Button>
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'action-button',
          }}
        >
          <MenuItem onClick={() => history.push('/managechannels/clone')}>
            {t('pages.manageChannel.clone')}
          </MenuItem>
          <MenuItem onClick={handleClose}>
            {t('pages.manageChannel.delete')}
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
}
export default ActionChannel;
