import React, { useContext, useEffect, useState } from 'react';

import { Box, SelectChangeEvent } from '@mui/material';
import { Input, Select } from '@revenue-solutions-inc/revxcoreui';
import { ManageConfigToolsContext } from 'components/contexts/ManageConfigToolsProvider/ManageConfigToolsProvider';
import { gql } from 'graphql-request';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import { Attribute, ConfigurationResponse } from 'types/graphTypes';

const getLookupsQuery = gql`
  query GetConfigurations(
    $configurationType: String!
    $configurationModule: String!
  ) {
    getConfigurations(
      configurationType: $configurationType
      configurationModule: $configurationModule
    ) {
      configurationName
      platformConfigurationId
      configurationType
    }
  }
`;

interface Props {
  currentData: ConfigurationResponse;
}

export default function DynamicAttributes({ currentData }: Props) {
  const { selectedRecord, setSelectedRecord } = useContext(
    ManageConfigToolsContext
  );
  const handleAttrChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | SelectChangeEvent<string | number>,
    attr: Attribute
  ) => {
    if (selectedRecord) {
      attr.attributeValue = event.target.value.toString();
      const temp = { ...selectedRecord };
      setSelectedRecord(temp);
    }
  };
  const accessToken = useGetAccessToken();
  // We join all the data sources of any attributes which have them together,
  // so that we can make a bulk call to the lookups api to get all the lookup values at once
  const dataSources =
    currentData?.platformConfigurationInfo?.configurationSection
      .map((configSect) =>
        configSect?.group
          .map((group) =>
            group.attribute
              .filter((a) => !!a.dataSource)
              .map((a) => a.dataSource)
              .join(',')
          )
          .join(',')
      )
      .join(',') ?? '';

  const [lookups, setLookups] = useState<{
    [key: string]: [ConfigurationResponse];
  }>();
  const { data } = useQueryRequest<{
    getConfigurations: ConfigurationResponse[];
  }>(['getLookups'], accessToken, getLookupsQuery, {
    configurationModule: '0',
    configurationType: dataSources,
  });

  useEffect(() => {
    if (data) {
      const tempLookups: { [key: string]: [ConfigurationResponse] } = {};
      data.getConfigurations.forEach((lookup) => {
        if (tempLookups[lookup.configurationType])
          tempLookups[lookup.configurationType].push(lookup);
        else tempLookups[lookup.configurationType] = [lookup];
      });
      setLookups(tempLookups);
    }
  }, [data]);

  return (
    <Box
      sx={{
        justifyContent: 'space-around',
        pl: '8em',
        backgroundColor: 'common.white',
      }}
    >
      {currentData?.platformConfigurationInfo?.configurationSection.map(
        (configSect) =>
          configSect.group.map((group) =>
            group.attribute.map((attr) => (
              <Box key={attr.attributeName}>
                {attr.attributeType?.toLowerCase() === 'referencedata' &&
                attr.dataSource &&
                lookups ? (
                  <Select
                    data-testid="select-dynamicattributes"
                    width="15em "
                    label={attr.attributeDisplayName ?? attr.attributeName}
                    value={attr.attributeValue}
                    onChange={(event) => handleAttrChange(event, attr)}
                    options={lookups[attr.dataSource].map((option) => {
                      return {
                        key: option.configurationName,
                        desc: option.configurationName,
                      };
                    })}
                    id="select-attribute"
                  />
                ) : (
                  <Input
                    label={attr.attributeDisplayName ?? attr.attributeName}
                    sx={{ mr: '5em' }}
                    value={attr.attributeValue}
                    onChange={(event) => handleAttrChange(event, attr)}
                  />
                )}
              </Box>
            ))
          )
      )}
    </Box>
  );
}
