import { useContext } from 'react';

import { Typography } from '@mui/material';
import { Input } from '@revenue-solutions-inc/revxcoreui';
import { ManageConfigGroupsContext } from 'components/contexts/ManageConfigGroupsProvider/ManageConfigGroupsProvider';
import { CellProps } from 'react-table';
import { ConfigurationGroupInput } from 'types/graphTypes';

export default function ConfigurationGroupDescription({
  row,
}: CellProps<ConfigurationGroupInput>) {
  const { selectedGroup, setSelectedGroup } = useContext(
    ManageConfigGroupsContext
  );
  return (
    <>
      {row.original.platformConfigurationGroupId ===
      selectedGroup?.platformConfigurationGroupId ? (
        <Input
          label={''}
          value={selectedGroup?.description || ''}
          onChange={(event) => {
            if (selectedGroup)
              setSelectedGroup({
                ...selectedGroup,
                description: event?.target.value,
              });
          }}
        />
      ) : (
        <Typography>{row.original.description}</Typography>
      )}
    </>
  );
}
