import { useContext, useState } from 'react';

import { Checkbox, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { default as RsiCheckbox } from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import DefaultDataTable from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DefaultDataTable';
import { AssignedRolesContext } from 'components/contexts/AssignedRoles/AssignedRolesProvider';
import { AssignAction } from 'hooks/useAssignRoleUsers';
import useAssignRoleUsers from 'hooks/useAssignRoleUsers';
import useGetAccessToken from 'hooks/useGetAccessToken';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';

import EditableButtonCell from '../EditableButtonCell/EditableButtonCell';
import EditableDatesCell from '../EditableDatesCell/EditableDatesCell';

interface RowProp {
  values: ValueProp;
}
interface ValueProp {
  roleId: string;
}
interface Row {
  value: string;
  id: string;
  row: RowProp;
  selectedFlatRows: [];
}

export default function AssignedRoles() {
  const dispatch = useAppDispatch();
  const { assignedRoles } = useContext(AssignedRolesContext);
  const [selectedUserRolesRows, setSelectedUserRolesRows] = useState<string[]>(
    []
  );
  const [notifyUser, setNotifyUser] = useState<boolean>(true);
  const accessToken = useGetAccessToken();
  const { mutate: removeRole } = useAssignRoleUsers();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.editUserRole',
  });
  const { t: n } = useTranslation();

  const unAssignRoles = () => {
    if (assignedRoles && assignedRoles.userRole) {
      const {
        refetch,
        tenantId,
        userRole: { userId },
      } = assignedRoles;
      const userRoleMapEntries = selectedUserRolesRows.map((roleId) => {
        return {
          roleId,
          userId,
        };
      });

      removeRole(
        {
          userCommand: {
            tenantId,
            userRoleMapEntries,
            mapAction: AssignAction.UNASSIGN,
            notifyUser: notifyUser,
          },
          token: accessToken,
        },
        {
          onSuccess: () => {
            setSelectedUserRolesRows([]);
            dispatch(
              addMessage({
                message: n('components.message.success'),
                type: MessageType.Info,
                actionType: MessageActionType.None,
              })
            );
            refetch();
          },
          onError: () => {
            dispatch(
              addMessage({
                message: n('components.message.networkerror'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    }
  };

  const userRolesColumn = [
    {
      Header: () => {
        return (
          <Checkbox
            id={'selectAllCheck'}
            checked={
              selectedUserRolesRows.length === 0 &&
              assignedRoles?.userRole?.userRole.length !== 0
            }
            disabled={assignedRoles?.userRole?.userRole.length === 0}
            onChange={(event) => {
              let newSelectedRows = [...selectedUserRolesRows];
              if (!event.target.checked && assignedRoles?.userRole?.userRole)
                newSelectedRows = assignedRoles?.userRole.userRole.map(
                  (d) => d.roleId
                );
              else newSelectedRows = [];
              setSelectedUserRolesRows(newSelectedRows);
            }}
          />
        );
      },
      disableSortBy: true,
      accessor: 'roleId',
      Cell: ({ row }: Row) => {
        return (
          <Checkbox
            checked={
              selectedUserRolesRows.findIndex(
                (p) => p === row.values.roleId
              ) === -1
            }
            onChange={(event) => {
              let newRows = [...selectedUserRolesRows];
              if (!event.target.checked) {
                setSelectedUserRolesRows([
                  ...selectedUserRolesRows,
                  row.values.roleId,
                ]);
              } else {
                newRows = selectedUserRolesRows.filter(
                  (p) => p !== row.values.roleId
                );
                setSelectedUserRolesRows(newRows);
              }
            }}
          />
        );
      },
    },
    {
      Header: t('roleName'),
      accessor: 'roleName',
    },
    {
      Header: t('description'),
      accessor: 'roleDescription',
    },
    {
      Header: t('module'),
      accessor: 'moduleName',
    },
    {
      Header: t('startEndDate'),
      id: 'startEndDate',
      Cell: EditableDatesCell,
    },
    {
      Header: '',
      id: 'buttons',
      Cell: EditableButtonCell,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <DefaultDataTable
          columns={userRolesColumn as Column<object>[]}
          data-testid="user-roles-data"
          data={assignedRoles?.userRole?.userRole || []}
        />

        {selectedUserRolesRows.length !== 0 && (
          <Grid
            sx={{
              marginBottom: '-4.2em',
              marginLeft: '1em',
              paddingTop: '2.2em',
            }}
            data-testid="remove-button"
          >
            <Box
              sx={{
                marginBottom: '4em',
              }}
            >
              <Typography>{t('changesEffectiveMessage')}</Typography>
              <Button
                id="button-assignSelectedRoles"
                onClick={unAssignRoles}
                sx={{ mr: '2em' }}
              >
                {t('removeRoles')}
              </Button>
              <RsiCheckbox
                checked={notifyUser}
                onChange={(e) => setNotifyUser(e.target.checked)}
                id={'notifyUser-assignedRoles'}
                label={`${t('sendEmailMessage')} ${
                  assignedRoles?.userRole?.name
                }`}
              />
              <Typography></Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
