import React from 'react';

import { Box, Grid } from '@mui/material';

function LogixTest(): JSX.Element {
  return (
    <Box sx={{ width: '90em' }}>
      <Grid container>
        <Grid item xs={12}></Grid>
      </Grid>
    </Box>
  );
}

export default LogixTest;
