import { useState } from 'react';

import BallotIcon from '@mui/icons-material/Ballot';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  SelectChangeEvent,
  Theme,
  Typography,
} from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DefaultDataTable from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DefaultDataTable';
import HeaderColumn from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/HeaderColumn';
import FileUpload from '@revenue-solutions-inc/revxcoreui/material/controls/FileUpload';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Column, CellProps } from 'react-table';

interface TestResultSearch {
  id?: string;
  name: string;
}

function ManageFormsProcessing(): JSX.Element {
  const { t } = useTranslation();
  const [actionFormCategoy, setActionFormCategoy] = useState('');
  const [actionFormYear, setActionFormYear] = useState('');
  const [formName, setFormName] = useState('');
  const [searchResults, setSearchResult] = useState<TestResultSearch[]>();

  const searchForms = () => {
    //TODO: search forms in the database "missing service"
    setSearchResult([
      {
        id: '1',
        name: 'Form 720 2021',
      },
      {
        id: '2',
        name: 'Form 720 Payment Voucher 2021',
      },
    ]);
  };

  const handleClone = (value: string) => {
    //TODO: clone the form selected and get a new one with a new version
    console.log(value);
  };

  const formsColumns: Column[] = [
    {
      Header: (
        <HeaderColumn localization={t('pages.manageFormsProcessing.name')} />
      ),
      accessor: 'name',
    },
    {
      Header: (
        <HeaderColumn localization={t('pages.manageFormsProcessing.actions')} />
      ),
      id: 'form-clone',
      Cell: (props: CellProps<Record<string, unknown>, unknown>) => {
        return (
          <>
            <FileUpload
              id={`addNewFormBtn_${props.row.original.roleId}`}
              label={t('pages.manageFormsProcessing.start.addNewForm')}
              sx={{
                textTransform: 'capitalize',
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const nameFile: string = event.target.value.substring(
                  12,
                  event.target.value.length
                );
                console.log(nameFile);
                //history.push(`form/${nameFile}`);
              }}
            />{' '}
            <Button
              id={`cloneFormBtn_${props.row.original.roleId}`}
              onClick={() => handleClone(props.row.original.roleId as string)}
              size="small"
            >
              {t('pages.manageFormsProcessing.start.clone')}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Grid container spacing={3} style={{ minWidth: '90em' }}>
      <Grid item xs={12} md={3}>
        <Card
          sx={{
            borderRadius: '5px',
            boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
          }}
        >
          <CardHeader
            sx={(theme: Theme) => {
              return {
                backgroundColor: theme.palette.cardHeader.main,
                '& .MuiCardHeader-title': {
                  fontSize: '0.875rem',
                  fontWeight: 700,
                },
              };
            }}
            title={t('pages.manageFormsProcessing.latestStatusSummary')}
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={1}>
                <Typography variant="h5">
                  {t('pages.manageFormsProcessing.latestStatus.new')}
                </Typography>
                <Typography variant="h1">{'21'}</Typography>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Typography variant="h5">
                  {t('pages.manageFormsProcessing.latestStatus.inProgress')}
                </Typography>
                <Typography variant="h1">{'5'}</Typography>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Typography variant="h5">
                  {t('pages.manageFormsProcessing.latestStatus.error')}
                </Typography>
                <Typography variant="h1">{'0'}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={9}>
        <Card
          sx={{
            borderRadius: '5px',
            boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
          }}
        >
          <CardHeader
            sx={(theme: Theme) => {
              return {
                backgroundColor: theme.palette.cardHeader.main,
                '& .MuiCardHeader-title': {
                  fontSize: '0.875rem',
                  fontWeight: 700,
                },
              };
            }}
            title={t('pages.manageFormsProcessing.startSummary')}
          />
          <CardContent>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              mb={3}
            >
              <Grid item xs={1}>
                <BallotIcon />
              </Grid>
              <Grid item xs={11}>
                <Link to="new" style={{ textDecoration: 'none' }}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {t('pages.manageFormsProcessing.start.createYourFormTitle')}
                  </Typography>
                  <Typography variant="h6">
                    {t(
                      'pages.manageFormsProcessing.start.createYourFormSubtitle'
                    )}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              mb={3}
            >
              <Grid item xs={1}>
                <CloudUploadIcon />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {t('pages.manageFormsProcessing.start.uploadFormTitle')}
                </Typography>
                <Typography variant="h6">
                  {t('pages.manageFormsProcessing.start.uploadFormSubtitle')}
                  <FileUpload
                    id="fileUpload"
                    label={t('pages.manageFormsProcessing.start.browse')}
                    variant="text"
                    sx={{
                      color: '#000',
                      fontWeight: '700',
                      textTransform: 'lowercase',
                      padding: '0px',
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const nameFile: string = event.target.value.substring(
                        12,
                        event.target.value.length
                      );
                      console.log(nameFile);
                      //history.push(`form/${nameFile}`);
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" alignItems="center">
              <Grid item xs={1}>
                <ManageSearchIcon />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {t('pages.manageFormsProcessing.start.searchTemplateTitle')}
                </Typography>
                <Grid container direction="row" alignItems="flex-end">
                  <Grid item xs={3}>
                    <Select
                      label={t(
                        'pages.manageFormsProcessing.start.searchForm.formCategory'
                      )}
                      id="formCategorySelect"
                      value={actionFormCategoy}
                      onChange={(event: SelectChangeEvent<string | number>) => {
                        setActionFormCategoy(
                          (event.target as HTMLSelectElement).value
                        );
                      }}
                      options={[
                        {
                          key: 'administrativeForms',
                          desc: 'Administrative Forms',
                        },
                        { key: 'aircraft', desc: 'Aircraft' },
                        { key: 'alcoholTax', desc: 'Alcohol Tax' },
                        { key: 'bankFranchiseTax', desc: 'Bank Franchise Tax' },
                        {
                          key: 'businessRegistration',
                          desc: 'Business Registration',
                        },
                        { key: 'coalSeveranceTax', desc: 'Coal Severance Tax' },
                        { key: 'collections', desc: 'Collections' },
                        { key: 'consumerUseTax', desc: 'Consumer Use Tax' },
                        {
                          key: 'corporationIncomeTax',
                          desc: 'Corporation Income Tax',
                        },
                        { key: 'fiduciaryTax', desc: 'Fiduciary Tax' },
                        {
                          key: 'healthCareProviderTax',
                          desc: 'Health Care Provider Tax',
                        },
                        { key: 'incomeTax', desc: 'Income Tax' },
                        {
                          key: 'inheritanceAndEstateTax',
                          desc: 'Inheritance and Estate Tax',
                        },
                        {
                          key: 'insurancePremiumsTaxSurcharge',
                          desc: 'Insurance Premiums Tax / Surcharge',
                        },
                        { key: 'intangibleForms', desc: 'Intangible Forms' },
                        { key: 'legalProcess', desc: 'Legal Process' },
                        { key: 'motorFuelsTax', desc: 'Motor Fuels Tax' },
                        {
                          key: 'motorVehicleUsageTax',
                          desc: 'Motor Vehicle Usage Tax',
                        },
                        {
                          key: 'passThroughEntityTax',
                          desc: 'Pass Through Entity Tax',
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      label={t(
                        'pages.manageFormsProcessing.start.searchForm.formYear'
                      )}
                      id="formYearSelect"
                      value={actionFormYear}
                      onChange={(event: SelectChangeEvent<string | number>) => {
                        setActionFormYear(
                          (event.target as HTMLSelectElement).value
                        );
                      }}
                      options={[
                        { key: '2018', desc: '2018' },
                        { key: '2019', desc: '2019' },
                        { key: '2020', desc: '2020' },
                        { key: '2021', desc: '2021' },
                        { key: '2022', desc: '2022' },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Input
                      value={formName}
                      id="formNameInput"
                      label={t(
                        'pages.manageFormsProcessing.start.searchForm.formName'
                      )}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFormName(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      id="formSearchBtn"
                      onClick={searchForms}
                      disabled={
                        actionFormCategoy === '' &&
                        actionFormYear === '' &&
                        formName === ''
                      }
                    >
                      {t('pages.search.searchBtn')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {searchResults && searchResults.length > 0 && (
        <Grid item xs={12} md={12}>
          <DefaultDataTable
            columns={formsColumns}
            data-testid="forms-data"
            data={searchResults ?? []}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default ManageFormsProcessing;
