import React, { useState, useMemo, createContext } from 'react';

import { Message, MessageCtx } from 'common/messages';

interface ContextProps {
  children?: JSX.Element | JSX.Element[];
}

const initialContext: MessageCtx = {
  message: { content: '', type: 0 },
  setMessage() {},
};

export const MessageContext = createContext(initialContext);

function Messaging({ children }: ContextProps) {
  const [message, setMessage] = useState<Message>();

  const ctx = useMemo(
    () => ({
      message: message,
      setMessage: setMessage,
    }),
    [message]
  );

  return (
    <MessageContext.Provider value={ctx}>{children}</MessageContext.Provider>
  );
}

export default Messaging;
