import { Dispatch, SetStateAction } from 'react';

import DescriptionIcon from '@mui/icons-material/Description';
import { Box, Typography } from '@mui/material';
import { Button, ListBox } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import { Attribute, ConfigurationResponse } from 'types/graphTypes';

interface Props {
  selectedConfigType: ConfigurationResponse;
  setSelectedConfigType: Dispatch<
    SetStateAction<ConfigurationResponse | undefined>
  >;
  setSelectedAttribute: Dispatch<SetStateAction<Attribute | undefined>>;
}
const attributeDefault: Attribute = {
  attributeDisplayName: 'New Attribute',
  attributeName: 'newAttribute',
  attributeType: 'string',
  extensibleBusinessDriver: [],
  repeatingProperties: false,
  attributeValue: '',
  layoutInfo: {
    format: '',
    mask: '',
    layoutOrder: '0',
    layoutStyle: '',
    validation: '',
  },
};

function AttributeList({
  selectedConfigType,
  setSelectedConfigType,
  setSelectedAttribute,
}: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configTypeEditor',
  });

  const addAttribute = () => {
    if (!!selectedConfigType) {
      const newConfig: ConfigurationResponse = {
        ...selectedConfigType,
      };
      // if there is no layout section or group, we add them now so that attributes can be added
      // TODO: allow user to explicitly add/create groups and layout sections in the UI
      if (
        !newConfig.platformConfigurationInfo ||
        newConfig.platformConfigurationInfo?.configurationSection?.length === 0
      ) {
        newConfig.platformConfigurationInfo = {
          configurationSection: [
            {
              configurationSectionName: 'Default Layout Section',
              configurationSectionOrder: '0',
              configurationTemplate: '',
              group: [
                { groupName: 'Default Group', groupOrder: '0', attribute: [] },
              ],
            },
          ],
        };
      }
      const numAttributes =
        newConfig.platformConfigurationInfo?.configurationSection[0].group[0]
          .attribute.length + 1;
      newConfig.platformConfigurationInfo?.configurationSection[0].group[0].attribute.push(
        {
          ...attributeDefault,
          attributeDisplayName: 'New Attribute' + numAttributes,
          attributeName: 'New Attribute' + numAttributes,
        }
      );
      setSelectedConfigType(newConfig);
    }
  };

  return (
    <>
      <Box sx={{ pb: '1em' }}>
        <Typography variant="h2" paddingBottom="1em" display="inline">
          <DescriptionIcon />
          {t('attributes')}
        </Typography>
        <Button
          sx={{ float: 'right' }}
          id={'addAttributeBtn-configTypeEditor'}
          onClick={addAttribute}
        >
          {t('add')}
        </Button>
      </Box>
      {selectedConfigType?.platformConfigurationInfo?.configurationSection[0]
        .group[0].attribute && (
        <ListBox
          id="attributeList-configTypeEditor"
          options={
            selectedConfigType?.platformConfigurationInfo?.configurationSection[0].group[0].attribute.map(
              (attr) => attr?.attributeDisplayName
            ) ?? []
          }
          onChange={(attributeName: string) => {
            setSelectedAttribute(
              selectedConfigType?.platformConfigurationInfo?.configurationSection[0].group[0].attribute.find(
                (attr) => attr.attributeDisplayName === attributeName
              )
            );
          }}
          sx={{
            maxHeight: '22em',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        />
      )}
    </>
  );
}

export default AttributeList;
