import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { QueryRole } from 'types/roles';
import { QueryModule } from 'types/tenants';

/**
 * Module List
 * @param token
 */
const getModuleList = async (
  token: string | undefined
): Promise<QueryModule[]> => {
  const result = await axios.get(
    `${process.env.REACT_APP_ROLE}/query/modules`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

function useModulesList(
  token: string | undefined
): UseQueryResult<QueryModule[], Error> {
  return useQuery<QueryModule[], Error>(
    ['modules'],
    () => {
      return getModuleList(token);
    },
    {
      enabled: !!token,
    }
  );
}

/**
 * RolesByModule
 * @param token
 * @param moduleId
 */

const getRolesByModule = async (
  token: string | undefined,
  moduleId: string | undefined
): Promise<QueryRole[]> => {
  const result = await axios.get(
    `${process.env.REACT_APP_ROLE}/query/roles/module/${moduleId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

function useRolesByModule(
  token: string | undefined,
  moduleId: string | undefined
): UseQueryResult<QueryRole[], Error> {
  return useQuery<QueryRole[], Error>(
    ['roles', moduleId],
    () => {
      return getRolesByModule(token, moduleId);
    },
    {
      enabled: !!token && !!moduleId && !isNaN(Number(moduleId)),
    }
  );
}

export { useModulesList, useRolesByModule };
