import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { Contact } from 'types/tenants';

const fetchContactList = async (
  token: string | undefined
): Promise<Contact[]> => {
  const result = await axios.get(
    `${process.env.REACT_APP_LOOKUP}/rsicontacts`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

export default function useRsiContacts(
  accessToken: string | undefined
): UseQueryResult<Contact[], Error> {
  return useQuery<Contact[], Error>(
    ['Contacts'],
    () => {
      return fetchContactList(accessToken);
    },
    {
      enabled: !!accessToken,
    }
  );
}
