import React, { useState } from 'react';

import { Box, FormLabel, Theme } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { useTranslation } from 'react-i18next';
import { RecurrenceScheduleInput } from 'types/channels';

export const labelStyle = (theme: Theme) => {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#333',
    borderBottom: 'none',
    '& .MuiFormLabel-asterisk': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};

interface Days {
  value: number;
  label: string;
}

interface Props {
  setRecurrenceSchedule: React.Dispatch<
    React.SetStateAction<RecurrenceScheduleInput>
  >;
  recurrenceSchedule: RecurrenceScheduleInput;
}
// TODO: replace with lookup once created
const daysOfWeek: Days[] = [
  { value: 0, label: 'Sun' },
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tue' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thu' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' },
];

function WeekDays({
  setRecurrenceSchedule,
  recurrenceSchedule,
}: Props): JSX.Element {
  const [weekDaysValue, setWeekDaysValue] = useState<number[]>([]);
  const [buttonValues, setButtonValues] = useState<number[]>([]);
  const { t } = useTranslation();

  const handleButton = (buttonId: number, value: number) => {
    const newSelectedValues = buttonValues;

    if (!newSelectedValues.includes(buttonId)) {
      newSelectedValues.push(buttonId);
    } else {
      newSelectedValues.splice(newSelectedValues.indexOf(buttonId), 1);
    }

    setButtonValues(newSelectedValues);
    setWeekDaysValue([...weekDaysValue, value]);
    setRecurrenceSchedule({
      ...recurrenceSchedule,
      weekDays: buttonValues,
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <FormLabel sx={labelStyle} id="weekDays">
            {t('pages.manageChannel.runDays')}
          </FormLabel>
        </Box>
        <Box>
          {daysOfWeek.map((day, i) => (
            <Button
              key={day.value}
              id={day.label}
              onClick={() => handleButton(i, day.value)}
              sx={[
                (theme) =>
                  buttonValues.includes(i)
                    ? { backgroundColor: theme.palette.primary.main }
                    : { backgroundColor: theme.palette.grey5.main },
                { minWidth: '7em' },
                { marginLeft: '.2em' },
              ]}
            >
              {day.label}
            </Button>
          ))}
        </Box>
      </Box>
    </>
  );
}
export default WeekDays;
