import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { QueryRole } from 'types/roles';

const fetchRolesByModules = async (
  token: string | undefined,
  moduleIds: string | undefined
): Promise<QueryRole[]> => {
  const result = await axios.get(
    `${process.env.REACT_APP_ROLE}/query/roles/modules?moduleids=${moduleIds}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

export default function useGetRolesByModules(
  accessToken: string | undefined,
  moduleIds: string | undefined
): UseQueryResult<QueryRole[], Error> {
  return useQuery<QueryRole[], Error>(
    ['roles'],
    () => {
      return fetchRolesByModules(accessToken, moduleIds);
    },
    {
      enabled: false,
    }
  );
}
