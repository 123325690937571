import axios from 'axios';
import { useMutation } from 'react-query';
import { CommitBulkFile, CommitBulkFileResponse } from 'types/roles';

interface UploadValidFileCommand {
  file: CommitBulkFile[];
  token: string;
}

export default function useCommitBulkFile() {
  return useMutation(async ({ file, token }: UploadValidFileCommand) => {
    return axios
      .post(`${process.env.REACT_APP_ROLE}/command/users/bulk/commit`, file, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.data as CommitBulkFileResponse[]);
  });
}
