import { useState, useMemo, createContext, ReactNode } from 'react';

import { ConfigurationGroupInput } from 'types/graphTypes';

interface Props {
  children: ReactNode;
}

interface ManageConfigGroupsCtx {
  selectedGroup: ConfigurationGroupInput | undefined;
  setSelectedGroup: (
    selectedGroup: ConfigurationGroupInput | undefined
  ) => void;
}

const initialContext: ManageConfigGroupsCtx = {
  selectedGroup: undefined,
  setSelectedGroup: () => {},
};

export const ManageConfigGroupsContext = createContext(initialContext);

export default function ManageConfigGroupsProvider({ children }: Props) {
  const [selectedGroup, setSelectedGroup] = useState<
    undefined | ConfigurationGroupInput
  >(undefined);

  const ctx = useMemo(() => {
    return {
      selectedGroup,
      setSelectedGroup,
    };
  }, [selectedGroup]);

  return (
    <ManageConfigGroupsContext.Provider value={ctx}>
      {children}
    </ManageConfigGroupsContext.Provider>
  );
}
