import React, { useState, useEffect } from 'react';

import { SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Radio from '@revenue-solutions-inc/revxcoreui/material/controls/Radio';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select/Select';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { setDarkMode, setLanguage } from 'redux/contentSlice';
import { useAppSelector, useAppDispatch } from 'redux/hooks';

const languageTypes: SelectType[] = [
  { key: 'en', desc: 'English' },
  { key: 'pl', desc: 'Pig Latin' },
  { key: 'es', desc: 'Español' },
];

function UserConfig(): JSX.Element {
  const { t } = useTranslation();

  useUpdateTitle(t('pages.configuration.title'));
  const darkMode = useAppSelector((state) => state.content.darkMode);
  const language = useAppSelector((state) => state.content.language);
  const [mode, setMode] = useState(darkMode);
  const [selectedValue, setSelectedValue] = useState('light');
  const [lang, setLang] = useState(language);
  const dispatch = useAppDispatch();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    if (event.target.value === 'dark') {
      setMode(true);
    } else {
      setMode(false);
    }
  };

  const handleLanguageChange = (event: SelectChangeEvent<string | number>) => {
    setLang((event.target as HTMLSelectElement).value);
  };

  useEffect(() => {
    dispatch(setDarkMode(mode));
  }, [mode, dispatch]);

  useEffect(() => {
    dispatch(setLanguage(lang));
  }, [lang, dispatch]);

  return (
    <>
      <Grid container>
        <Grid sm={6} xs={12} item>
          <>
            <Radio
              checked={selectedValue === 'light'}
              onChange={handleRadioChange}
              value="light"
              color="primary"
              id="lightRadio"
              label={t('pages.configuration.lightOption') as string}
            />
            <Radio
              checked={selectedValue === 'dark'}
              onChange={handleRadioChange}
              value="dark"
              color="primary"
              id="darkRadio"
              label={t('pages.configuration.darkOption') as string}
            />
          </>
        </Grid>
        <Grid sm={6} xs={12} item>
          <Select
            options={languageTypes}
            value={lang}
            id="lang-select"
            label={t('pages.configuration.language')}
            onChange={handleLanguageChange}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default UserConfig;
