import { useMemo, useState } from 'react';

import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CommitResponse from 'components/bulkUserUpload/CommitResponse';
import Preview from 'components/bulkUserUpload/Preview';
import UploadFile from 'components/bulkUserUpload/UploadFile';
import { useTranslation } from 'react-i18next';
import { CommitBulkFileResponse, ValidateBulkFileResponse } from 'types/roles';

const useStyles = makeStyles(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ palette: { cardHeader, primary } }: any) => ({
    cardHeader: {
      backgroundColor: cardHeader.main,
      '& .MuiCardHeader-title': {
        fontSize: '0.875rem',
        fontWeight: 700,
        color: primary.main,
      },
    },
  })
);

function BulkUserUpload(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.bulkUserUpload',
  });
  const [isPreview, setIsPreview] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  const [data, setData] = useState<undefined | ValidateBulkFileResponse[]>(
    undefined
  );
  const [comittedDataResponse, setComittedDataResponse] = useState<
    undefined | CommitBulkFileResponse[]
  >(undefined);

  const startOver = () => {
    setIsPreview(false);
    setIsSummary(false);
    setData(undefined);
    setComittedDataResponse(undefined);
  };

  const handleDone = (validateBulkFileResponse: ValidateBulkFileResponse[]) => {
    setIsPreview(true);
    setData(validateBulkFileResponse);
  };

  const handleFileCommited = (
    commitBulkFileResponse: CommitBulkFileResponse[]
  ) => {
    setIsSummary(true);
    setIsPreview(false);
    setComittedDataResponse(commitBulkFileResponse);
  };

  const title = useMemo(() => {
    if (isPreview) return t('previewTitle');
    if (isSummary) return t('summaryTitle');

    return t('cardTitle');
  }, [isPreview, isSummary, t]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader className={classes.cardHeader} title={title} />
          <CardContent>
            {!isPreview && !isSummary ? (
              <UploadFile handleDone={handleDone} />
            ) : isPreview ? (
              <Preview
                dataResponse={data}
                startOver={startOver}
                handleFileCommited={handleFileCommited}
              />
            ) : (
              <CommitResponse
                comittedDataResponse={comittedDataResponse}
                startOver={startOver}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default BulkUserUpload;
