import { gql } from 'graphql-request';

export const searchByName = gql`
  query GlobalSearch($searchType: String!, $searchName: String!) {
    GlobalSearch(searchType: $searchType, searchName: $searchName) {
      entityId
      entityName
      entityType
    }
  }
`;

export default searchByName;
