import React, { useState, useEffect } from 'react';

import { useIsAuthenticated } from '@azure/msal-react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

interface Props {
  exact?: boolean;
  path?: string | string[];
  policy?: string;
  Component: JSX.Element;
}
const SecureRoute = ({
  exact = false,
  path = '',
  Component,
  policy = '',
}: Props): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const policies = useAppSelector((state) => state.user.user.policies);
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (loading && policy && policies) {
      setLoading(false);
    }
    if (!loading && !policies.includes(policy)) {
      history.push('/welcome');
    }
  }, [loading, policy, policies, history]);
  if (!loading && policies.includes(policy)) {
    if (isAuthenticated) {
      return <Route exact={exact} path={path} render={() => Component} />;
    }
    return <Redirect to="/login" />;
  }

  return <></>;
};

export default SecureRoute;
