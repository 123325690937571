import { useContext, useState, CSSProperties, SetStateAction } from 'react';

import { Delete, ExpandLess, ExpandMore } from '@mui/icons-material/';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import {
  Button,
  HeaderColumn,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTable from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DefaultDataTable';
// TODO: go back and Change this control back to revxcoreui once updated
import { ManageConfigGroupsContext } from 'components/contexts/ManageConfigGroupsProvider/ManageConfigGroupsProvider';
import MessageAlert from 'components/MessageAlert';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMutationRequest from 'hooks/useMutationRequest';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { Column, CellProps, Renderer } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { ErrorResponse } from 'types/graphqlErrors';
import { ConfigurationGroupInput } from 'types/graphTypes';

import ConfigurationGroupDescription from '../ConfigurationGroupDescription';
import ConfigurationGroupName from '../ConfigurationGroupName';
import { addConfigurationGroupQuery } from '../queries/addConfigurationGroupQuery';
import { deleteConfigurationGroupQuery } from '../queries/deleteConfigurationGroupQuery';
import { updateConfigurationGroupQuery } from '../queries/updateConfigurationGroupQuery';

const widthHeight: CSSProperties = {
  width: '1.5em',
  height: '1.5em',
};
const fontSize = '1em';

const useStyles = makeStyles(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ palette: { grey1, error, secondaryGreen, white, primary } }: any) => ({
    cancelButton: {
      ...widthHeight,
      background: error.main,
      '&:hover': {
        background: error.main,
      },
    },
    checkButton: {
      ...widthHeight,
      background: secondaryGreen.main,
      '&:hover': {
        background: secondaryGreen.main,
      },
      '&:disabled': {
        background: grey1.main,
      },
    },
    editButton: {
      border: `${grey1.main} 1px solid`,
      ...widthHeight,
    },
    icon: {
      color: white.main,
      fontSize,
    },
    copyButton: {
      color: primary.main,
    },
    deleteButton: {
      color: primary.main,
    },
  })
);

interface Props {
  configData: ConfigurationGroupInput[];
  setConfigData: (configData: ConfigurationGroupInput[]) => void;
  refetchTypes: () => void;
}

function ConfigurationGroupsTableManager({
  configData,
  setConfigData,
  refetchTypes,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  useUpdateTitle(t('pages.configGroupEditor.title'));
  const { selectedGroup, setSelectedGroup } = useContext(
    ManageConfigGroupsContext
  );
  const accessToken = useGetAccessToken();
  const [groupToDelete, setGroupToDelete] = useState('');
  const [open, setOpen] = useState(false);
  const createConfigurationGroup =
    useMutationRequest<ConfigurationGroupInput>();
  const deleteConfigurationGroup = useMutationRequest<{
    platformConfigurationGroupId: string;
  }>();
  const dispatch = useAppDispatch();
  const updateConfigurationGroup =
    useMutationRequest<ConfigurationGroupInput>();
  const removeConfigurationGroup = (platformConfigurationGroupId: string) => {
    deleteConfigurationGroup.mutate(
      {
        token: accessToken,
        mutationKey: 'remove-Group',
        query: deleteConfigurationGroupQuery,
        params: { platformConfigurationGroupId },
        paramsId: 'deleteConfigGroupInput',
      },
      {
        onSuccess: () => {
          refetchTypes();
          setSelectedGroup(undefined);
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('components.message.success'),
              actionType: MessageActionType.None,
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('components.message.networkerror'),
            })
          );
        },
      }
    );
  };

  const saveConfigurationGroup = () => {
    if (selectedGroup == undefined) return;
    if (selectedGroup?.platformConfigurationGroupId) {
      updateConfigurationGroup.mutate(
        {
          token: accessToken,
          mutationKey: 'update-Group',
          query: updateConfigurationGroupQuery,
          params: {
            platformConfigurationGroupId:
              selectedGroup.platformConfigurationGroupId,
            description: selectedGroup.description ?? '',
            name: selectedGroup.name,
          },
          paramsId: 'newConfigurationGroup',
        },
        {
          onSuccess: function (
            response: ErrorResponse | ConfigurationGroupInput
          ) {
            if ((response as ErrorResponse)?.errors) {
              dispatch(
                addMessage({
                  type: MessageType.Error,
                  message: (response as ErrorResponse).errors[0].extensions
                    .response.body.message,
                  actionType: MessageActionType.None,
                })
              );
            } else {
              refetchTypes();
              setSelectedGroup(undefined);
              addMessage({
                type: MessageType.Success,
                message: t('components.message.success'),
              });
            }
          },
          onError: () => {
            addMessage({
              type: MessageType.Error,
              message: t('components.message.networkerror'),
            });
          },
        }
      );
    } else {
      createConfigurationGroup.mutate(
        {
          token: accessToken,
          mutationKey: 'add-Group',
          query: addConfigurationGroupQuery,
          params: {
            description: selectedGroup.description ?? '',
            name: selectedGroup.name,
          },
          paramsId: 'newConfigurationGroup',
        },
        {
          onSuccess: function (
            response: ErrorResponse | ConfigurationGroupInput
          ) {
            if ((response as ErrorResponse)?.errors) {
              addMessage({
                type: MessageType.Error,
                message: (response as ErrorResponse).errors[0].extensions
                  .response.body.message,
              });
            } else {
              setSelectedGroup(undefined);
              refetchTypes();
              addMessage({
                type: MessageType.Success,
                message: t('components.message.success'),
              });
            }
          },
          onError: () => {
            addMessage({
              type: MessageType.Error,
              message: t('components.message.networkerror'),
            });
          },
        }
      );
    }
  };

  const handleAddRow = () => {
    if (
      configData.filter((row) => !row.platformConfigurationGroupId).length === 0
    ) {
      const temp: ConfigurationGroupInput = {
        name: '',
        description: '',
      };
      setConfigData([temp, ...configData]);
      setSelectedGroup(temp);
    }
  };

  const handleEditRow = (configHeader: ConfigurationGroupInput) => {
    setSelectedGroup({ ...configHeader });
  };

  const handleCancel = () => {
    //if we were creating a new record and cancelled before saving, take it back out of the data.
    setSelectedGroup(undefined);
    setConfigData([
      ...configData.filter((row) => !!row.platformConfigurationGroupId),
    ]);
  };

  const configHeaderColumns: Column[] = [
    {
      Header: <HeaderColumn localization={t('components.actions.remove')} />,
      id: 'remove',
      Cell: ({ row }: CellProps<Record<string, unknown>, unknown>) => {
        if (
          row.original.platformConfigurationGroupId !==
          selectedGroup?.platformConfigurationGroupId
        ) {
          return (
            <IconButton
              size="medium"
              className={classes.deleteButton}
              onClick={() => {
                if (row.original) {
                  setGroupToDelete(
                    row.original
                      .platformConfigurationGroupId as SetStateAction<string>
                  );
                }
                setOpen(true);
              }}
              aria-label="delete-button"
              disabled={selectedGroup !== undefined}
            >
              <Delete sx={{ fontSize }} />
            </IconButton>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      Header: (
        <HeaderColumn localization={t('pages.configGroupEditor.configName')} />
      ),
      id: 'name',
      Cell: ConfigurationGroupName as Renderer<CellProps<object, unknown>>,
    },
    {
      Header: (
        <HeaderColumn localization={t('pages.configGroupEditor.configDesc')} />
      ),
      id: 'description',
      Cell: ConfigurationGroupDescription as Renderer<
        CellProps<object, unknown>
      >,
    },

    {
      Header: <HeaderColumn localization={t('components.actions.edit')} />,
      id: 'edit',
      Cell: ({ row }: CellProps<Record<string, unknown>, unknown>) => {
        if (
          row.original.platformConfigurationGroupId !==
          selectedGroup?.platformConfigurationGroupId
        ) {
          return (
            <IconButton
              size="small"
              onClick={() =>
                handleEditRow(row.original as ConfigurationGroupInput)
              }
              className={classes.editButton}
              aria-label="edit-button"
              disabled={selectedGroup !== undefined}
            >
              <EditIcon sx={{ fontSize }} />
            </IconButton>
          );
        } else if (open) {
          return <></>;
        } else {
          return (
            <>
              <Stack spacing={1} direction="row">
                <IconButton
                  size="small"
                  onClick={saveConfigurationGroup}
                  className={classes.checkButton}
                  aria-label="check-button"
                  disabled={selectedGroup?.name.trim() === ''}
                >
                  <CheckIcon className={classes.icon} />
                </IconButton>
                <IconButton
                  size="small"
                  className={classes.cancelButton}
                  onClick={handleCancel}
                >
                  <CloseIcon fontSize="small" className={classes.icon} />
                </IconButton>
              </Stack>
            </>
          );
        }
      },
    },
    {
      Header: <HeaderColumn localization={t('')} />,
      id: 'expander',
      Cell: ({ row }: CellProps<Record<string, unknown>, unknown>) => {
        if (row.original.platformConfigurationInfo) {
          return (
            <Box {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
            </Box>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  const buttonStyle = (theme: Theme) => {
    return {
      border: `1px solid ${theme.palette.white.main}`,
      ':hover': {
        border: `1px solid ${theme.palette.white.main}`,
        backgroundColor: 'transparent',
      },
      color: theme.palette.white.main,
      backgroundColor: 'transparent',
    };
  };

  const ConfirmButton = (
    <Button
      id="confirmBtn"
      variant="outlined"
      type="secondary"
      sx={buttonStyle}
      onClick={() => {
        if (groupToDelete) {
          removeConfigurationGroup(groupToDelete);
          setOpen(false);
        }
      }}
    >
      {t('pages.configGroupEditor.confirmButton')}
    </Button>
  );

  return (
    <>
      <MessageAlert
        message={t('pages.configGroupEditor.confirmButtonMsg')}
        type="info"
        content={ConfirmButton}
        open={open}
        setOpen={setOpen}
      />
      <DefaultDataTable
        columns={configHeaderColumns}
        data={configData}
        customHeader={
          <Button
            onClick={() => handleAddRow()}
            id={'tableManager-AddBtn'}
            disabled={selectedGroup !== undefined}
            sx={{ mr: '1em', mt: '.5em' }}
          >
            {t('components.button.add')}
          </Button>
        }
      />
    </>
  );
}

export default ConfigurationGroupsTableManager;
