import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid, Link, Typography, CardActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  DefaultDataTable,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import { formatNumber } from 'common/helpers';
import useCommitBulkFile from 'hooks/useCommitBulkFile';
import useGetAccessToken from 'hooks/useGetAccessToken';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  ValidateBulkFileResponse,
  StatusType,
  CommitBulkFile,
  CommitBulkFileResponse,
} from 'types/roles';

const useStyles = makeStyles(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ palette: { cardHeader, medBlue, white, primary } }: any) => ({
    cardHeader: {
      backgroundColor: cardHeader.main,
      '& .MuiCardHeader-title': {
        fontSize: '0.875rem',
        fontWeight: 700,
        color: primary.main,
      },
    },
    text: {
      fontSize: '1.1em',
    },
    box: {
      margin: '0.5em',
      padding: '0 2.5em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      paddingTop: '0.5em !important',
      cursor: 'pointer',
    },
    labelBox: {
      fontWeight: 600,
      fontSize: '1.3em',
    },
    active: {
      backgroundColor: medBlue.main,
      color: white.main,
    },
    numberBox: {
      fontSize: '3em',
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    showAllText: {
      fontSize: '1.1em',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  })
);

const resultColums: Column[] = [
  {
    Header: 'Row',
    accessor: 'rowNumber',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Details',
    accessor: 'details',
  },
  {
    Header: 'Data',
    accessor: 'data',
  },
];

const resultValidColums: Column[] = [
  {
    Header: 'Row',
    accessor: 'rowNumber',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Data',
    accessor: 'data',
  },
];

interface TempProps {
  rowNumber: number;
  status: string;
  details?: string;
  data: string;
}

interface Props {
  dataResponse: undefined | ValidateBulkFileResponse[];
  startOver: () => void;
  handleFileCommited: (
    commitBulkFileResponse: CommitBulkFileResponse[]
  ) => void;
}

export default function Preview({
  dataResponse,
  startOver,
  handleFileCommited,
}: Props) {
  const accessToken = useGetAccessToken();
  const classes = useStyles();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.bulkUserUpload',
  });
  const { t: p } = useTranslation();
  const dispatch = useAppDispatch();
  const [statusType, setStatusType] = useState<StatusType | 'Valid'>('Error');
  const [isShowAll, setIsShowAll] = useState(false);

  const { isSuccess, isError, data, mutate: upload } = useCommitBulkFile();

  const getDataByStatus = useCallback(
    (statusParam: StatusType | 'Valid') => {
      const temp: TempProps[] = [];

      dataResponse?.forEach(
        ({
          rowNumber,
          firstName,
          lastName,
          email,
          moduleName,
          roleName,
          validations,
          isValid,
        }) => {
          const dataBuilder: string[] = [`${firstName} ${lastName}`, email];
          if (moduleName) dataBuilder.push(moduleName);
          if (roleName) dataBuilder.push(roleName);
          if (statusParam === 'Valid' && isValid) {
            temp.push({
              rowNumber,
              status: 'Valid',
              data: dataBuilder.join(' - '),
            });
          } else {
            validations.forEach(({ status, description }) => {
              if (statusParam === status)
                temp.push({
                  rowNumber,
                  status,
                  details: description,
                  data: dataBuilder.join(' - '),
                });
            });
          }
        }
      );

      return temp;
    },
    [dataResponse]
  );

  const { validTotal, warningTotal, errorTotal } = useMemo(() => {
    return {
      validTotal: formatNumber(getDataByStatus('Valid').length),
      warningTotal: formatNumber(getDataByStatus('Warning').length),
      errorTotal: formatNumber(getDataByStatus('Error').length),
    };
  }, [getDataByStatus]);

  const dataSource = useMemo(() => {
    const valids: TempProps[] = [];
    const warnings: TempProps[] = [];
    const errors: TempProps[] = [];

    if (statusType === 'Valid' || isShowAll) {
      getDataByStatus('Valid').forEach((row) => {
        valids.push(row);
      });
    }

    if (statusType === 'Warning' || isShowAll) {
      getDataByStatus('Warning').forEach((row) => {
        warnings.push(row);
      });
    }

    if (statusType === 'Error' || isShowAll) {
      getDataByStatus('Error').forEach((row) => {
        errors.push(row);
      });
    }

    return [...valids, ...warnings, ...errors];
  }, [isShowAll, statusType, getDataByStatus]);

  const handleStatus = (param: StatusType | 'Valid') => () => {
    setStatusType(param);
    setIsShowAll(false);
  };

  const boxClass = (param: StatusType | 'Valid') => {
    const classesName = [classes.box];
    if (param === statusType && !isShowAll) {
      classesName.push(classes.active);
    }

    return classesName.join(' ');
  };

  const commitBulkFile = () => {
    const commitFile: CommitBulkFile[] = [];

    dataResponse?.forEach(
      ({
        rowNumber,
        firstName,
        lastName,
        email,
        moduleName,
        roleName,
        startDate,
        endDate,
      }) => {
        commitFile.push({
          rowNumber,
          firstName,
          lastName,
          email,
          moduleName,
          roleName,
          startDate,
          endDate,
        });
      }
    );

    if (!!accessToken && !!dataResponse)
      upload({ token: accessToken, file: commitFile });
  };

  useEffect(() => {
    if (isSuccess) handleFileCommited(data);
  }, [data, isSuccess, handleFileCommited]);

  useEffect(() => {
    if (isError)
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: p('components.message.networkerror'),
        })
      );
  }, [isError, dispatch, p]);

  return (
    <>
      <Grid item xs={12}>
        <Typography color="text.primary" className={classes.text}>
          {t('checkedFileText')}
        </Typography>
        <Typography color="text.primary" className={classes.text}>
          {t('continueText')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2, mb: '4em' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <Box
            className={boxClass('Valid')}
            onClick={handleStatus('Valid')}
            data-testid="validTotal"
          >
            <Box className={classes.labelBox}>{t('boxes.valids')}</Box>
            <Box className={classes.numberBox}>{validTotal}</Box>
          </Box>
          <Box
            className={boxClass('Warning')}
            onClick={handleStatus('Warning')}
            data-testid="warningTotal"
          >
            <Box className={classes.labelBox}>{t('boxes.warnings')}</Box>
            <Box className={classes.numberBox}>{warningTotal}</Box>
          </Box>
          <Box
            className={boxClass('Error')}
            onClick={handleStatus('Error')}
            data-testid="errorTotal"
          >
            <Box className={classes.labelBox}>{t('boxes.errors')}</Box>
            <Box className={classes.numberBox}>{errorTotal}</Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <Grid sx={{ mb: '-4.2em', ml: '1em' }}>
          {!isShowAll && (
            <Link
              id="showAllResults"
              className={classes.showAllText}
              onClick={() => setIsShowAll(true)}
            >
              {t('showAllResults')}
            </Link>
          )}
        </Grid>
        <DefaultDataTable
          columns={statusType === 'Valid' ? resultValidColums : resultColums}
          data-testid="result-data"
          data={dataSource}
        />
      </Grid>
      <CardActions sx={{ justifyContent: 'flex-end', py: 3, px: 2 }}>
        <Button id="startOver" type="ghost" onClick={startOver}>
          {t('buttons.startOver')}
        </Button>
        <Button id="applyChanges" onClick={commitBulkFile}>
          {t('buttons.applyChanges')}
        </Button>
      </CardActions>
      {warningTotal != '0' || errorTotal != '0' ? (
        <CardActions sx={{ justifyContent: 'flex-end', px: 2 }}>
          <Typography color="red" className={classes.text}>
            {t('commitWithErrors')}
          </Typography>
        </CardActions>
      ) : (
        ''
      )}
    </>
  );
}
