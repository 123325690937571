import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

const fetchBulkTemplate = async (
  token: string | undefined
): Promise<void | null> => {
  const fileName = 'BulkUserTemplate.csv';
  await axios
    .get(`${process.env.REACT_APP_ROLE}/query/users/bulk/template`, {
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: 'blob',
        'content-type': 'text/csv',
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
};

export default function useBulkTemplate(
  accessToken: string | undefined,
  downloadBulkTemplate: boolean
): UseQueryResult<void | null, Error> {
  return useQuery<void | null, Error>(
    ['bulktemplate'],
    () => {
      return fetchBulkTemplate(accessToken);
    },
    {
      enabled: !!accessToken && downloadBulkTemplate,
    }
  );
}
