/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';

import {
  Configuration,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import axios from 'axios';
import Error from 'components/Error';
import Loading from 'components/Loading';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { useAppDispatch } from 'redux/hooks';

const query = gql`
  query ($domain: String!) {
    TenantCredentials(domain: $domain) {
      clientId
      authorityDomain
      scope
      authorities {
        login {
          name
          url
        }
      }
    }
  }
`;

interface Props {
  children: React.ReactNode;
}
function Security({ children }: Props): JSX.Element {
  const [msalInstance, setMsalInstance] = useState<IPublicClientApplication>();
  const dispatch = useAppDispatch();
  //using out of the box
  const { data, isError, isLoading } = useQuery<{
    TenantCredentials: {
      clientId: string;
      authorityDomain: string;
      scope: string;
      authorities: { login: { url: string } };
    };
  }>(['queryId'], async () => {
    const result = await axios.post(`${process.env.REACT_APP_GRAPH}`, {
      query,
      variables: { domain: window.location.hostname },
    });
    return result.data.data;
  });
  useEffect(() => {
    if (!msalInstance && !isLoading && data?.TenantCredentials) {
      const elconfig: Configuration = {
        auth: {
          clientId: data.TenantCredentials.clientId,
          authority: data.TenantCredentials.authorities.login.url,
          knownAuthorities: [data.TenantCredentials.authorityDomain],
          redirectUri: `${window.location.origin}/dashboard`,
          postLogoutRedirectUri: `${window.location.origin}/welcome`,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      };
      const newInstance = new PublicClientApplication(elconfig);
      setMsalInstance(newInstance);
    }
  }, [msalInstance, dispatch, isLoading, data]);

  if (isError) {
    return <Error />;
  }
  if (msalInstance) {
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
  }
  return <Loading />;
}

export default Security;
