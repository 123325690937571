import { Buffer } from 'buffer';

import { useState, useEffect } from 'react';

import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import {
  BasicTable,
  DateCell,
  Dropzone,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import Header from '@revenue-solutions-inc/revxcoreui/material/layout/Header';
import FileSaver from 'file-saver';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMutationRequest from 'hooks/useMutationRequest';
import useQueryRequest from 'hooks/useQueryRequest';
import useValidatebulkfile from 'hooks/useValidatebulkfile';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  ReusableContent as CreateReusableContent,
  ReusableContent as UpdateReusableContent,
  ReusableContentsResponse,
} from 'types/graphTypes';
import {
  ContentStatus,
  ContentType,
  reusableContentDefault,
} from 'types/reusableContent';

import {
  createReusableContent as createReusableGraph,
  updateReusableContent as updateReusableGraph,
  getReusableContent as getReusableContentGraph,
  getReusableContentHistory as getReusableContentHistoryGraph,
} from './ManageReusableContentScript';

function ManageReusableContent(): JSX.Element {
  const [selectedModule, setSelectedModule] = useState('admin');
  const [contentFile, setContentFile] = useState(new File([''], ''));
  const history = useHistory();
  const { t } = useTranslation();
  const accessToken = useGetAccessToken();
  const match = useRouteMatch<{ contentId: string; action: string }>();
  const dispatch = useAppDispatch();
  const createContent = useMutationRequest<CreateReusableContent>();
  const updateContent = useMutationRequest<UpdateReusableContent>();

  const { data: historyData } = useQueryRequest<{
    ReusableContentHistory: [ReusableContentsResponse];
  }>(['history'], accessToken, getReusableContentHistoryGraph, {
    reusableContentHistoryId: match.params.contentId
      ? match.params.contentId
      : '',
  });
  const goBack = () => {
    history.goBack();
  };
  const statusList: SelectType[] = [
    { key: 'DRAFT', desc: 'Draft' },
    { key: 'ACTIVE', desc: 'Active' },
    { key: 'INACTIVE', desc: 'Inactive' },
  ];
  const draftList: SelectType[] = [
    { key: 'DRAFT', desc: 'Draft' },
    { key: 'INACTIVE', desc: 'Inactive' },
  ];
  const activeList: SelectType[] = [{ key: 'DRAFT', desc: 'Draft' }];

  const typeList: SelectType[] = [
    { key: 'HEADER', desc: 'Header' },
    { key: 'BODY', desc: 'Body' },
    { key: 'FOOTER', desc: 'Footer' },
  ];

  const [content, setContent] = useState<ReusableContentsResponse>(
    reusableContentDefault
  );
  const saveXMLFile = () => {
    const blobPart = Buffer.from(content.contentOpenXml, 'base64').toString();
    const initialString = blobPart.substring(0, 1);
    let blob;
    if (initialString === '<') {
      blob = new Blob([blobPart], {
        type: 'xml;charset=utf-8',
      });
    } else {
      blob = new Blob([JSON.parse(blobPart)], {
        type: 'xml;charset=utf-8',
      });
    }

    FileSaver.saveAs(blob, `${content.title}.xml`);
  };
  const [status, setStatus] = useState(ContentStatus.Draft);
  const { data } = useQueryRequest<{
    ReusableContent: ReusableContentsResponse;
  }>(['content'], accessToken, getReusableContentGraph, {
    reusableContentId: match.params.contentId ? match.params.contentId : '',
  });

  const { isLoading: dropzoneLoading, mutate: upload } = useValidatebulkfile();

  enum ActionType {
    EDIT = 'edit',
    CLONE = 'clone',
    CREATE = 'create',
  }
  const columns: Column[] = [
    {
      Header: t('pages.reusableContent.subject'),
      accessor: 'title',
    },
    {
      Header: t('pages.templates.version'),
      accessor: 'version',
    },
    {
      Header: t('pages.reusableContent.createdBy'),
      accessor: 'createdBy',
    },
    {
      Header: t('pages.reusableContent.createdDate'),
      accessor: 'createdDate',
      Cell: (props: CellProps<Record<string, string>, React.ReactNode>) => {
        return <DateCell dateString={props.row.original.createdDate} />;
      },
    },
    {
      Header: t('pages.reusableContent.updatedDate'),
      accessor: 'updatedDate',
      Cell: (props: CellProps<Record<string, string>, React.ReactNode>) => {
        return <DateCell dateString={props.row.original.updatedDate} />;
      },
    },
    {
      Header: t('pages.reusableContent.type'),
      accessor: 'contentType',
    },
    {
      Header: t('pages.reusableContent.status'),
      accessor: 'status',
    },
    {
      Header: t('pages.reusableContent.download'),
      id: 'content-download',
      Cell: (props: CellProps<Record<string, unknown>, React.ReactNode>) => {
        if (
          match.params.contentId === `${props.row.original.reusableContentId}`
        ) {
          return (
            <DownloadRoundedIcon
              style={{ cursor: 'pointer' }}
              onClick={() => saveXMLFile()}
            />
          );
        }
        return <DownloadRoundedIcon color={'disabled'} />;
      },
    },
  ];

  useEffect(() => {
    async function updateXML() {
      if (contentFile.type === 'text/xml') {
        const fileData = await contentFile.text();
        setContent((PrevState) => ({
          ...PrevState,
          contentOpenXml: fileData,
        }));
      } else {
        dispatch(
          addMessage({
            message: t('pages.manageReusableContent.errorMessage'),
            type: MessageType.Warning,
            actionType: MessageActionType.None,
          })
        );
      }
    }
    if (contentFile.size > 1) updateXML();
  }, [contentFile, dispatch, t]);

  useEffect(() => {
    if (data && data.ReusableContent) {
      if (data.ReusableContent.reusableContentId === null)
        setContent(reusableContentDefault);
      else setContent(data?.ReusableContent);
      let Status = ContentStatus.Active;
      switch (data?.ReusableContent.status) {
        case 'ACTIVE':
          Status = ContentStatus.Active;
          break;
        case 'INACTIVE':
          Status = ContentStatus.Inactive;
          break;
        default:
          Status = ContentStatus.Draft;
          break;
      }
      setStatus(Status);
    }
  }, [data, data?.ReusableContent]);

  const newVersionHandler = () => {
    setStatus(ContentStatus.Draft);
    history.push('/reusableContents/create');
  };

  const handleSaveContent = () => {
    if (!content.contentOpenXml) {
      dispatch(
        addMessage({
          message: t('pages.manageReusableContent.errorMessage'),
          type: MessageType.Warning,
          actionType: MessageActionType.None,
        })
      );
    } else {
      if (match.params.action === ActionType.EDIT) {
        updateContent.mutate(
          {
            mutationKey: ['updateContent', content.reusableContentId],
            params: {
              reusableContentId: String(content.reusableContentId),
              contentType: content.contentType,
              title: content.title,
              status: status,
              contentOpenXml: content.contentOpenXml
                .toUpperCase()
                .includes('XML')
                ? Buffer.from(JSON.stringify(content.contentOpenXml)).toString(
                    'base64'
                  )
                : content.contentOpenXml,
            },
            query: updateReusableGraph,
            token: accessToken,
            paramsId: 'content',
          },
          {
            onSuccess: () => {
              dispatch(
                addMessage({
                  message: t('pages.manageReusableContent.successMessage'),
                  type: MessageType.Success,
                  actionType: MessageActionType.None,
                })
              );
            },
            onError: () => {
              dispatch(
                addMessage({
                  message: t('pages.manageReusableContent.networkError'),
                  type: MessageType.Error,
                  actionType: MessageActionType.None,
                })
              );
            },
          }
        );
      } else {
        createContent.mutate(
          {
            mutationKey: 'saveContent',
            params: {
              contentType: content.contentType,
              title: content.title,
              status: status,
              contentOpenXml: Buffer.from(
                JSON.stringify(content.contentOpenXml)
              ).toString('base64'),
              fixedId: content.fixedId,
            },
            query: createReusableGraph,
            token: accessToken,
            paramsId: 'content',
          },
          {
            onSuccess: () => {
              dispatch(
                addMessage({
                  message: t('pages.manageReusableContent.successMessage'),
                  type: MessageType.Success,
                  actionType: MessageActionType.None,
                })
              );
            },
            onError: () => {
              dispatch(
                addMessage({
                  message: t('pages.manageReusableContent.networkError'),
                  type: MessageType.Error,
                  actionType: MessageActionType.None,
                })
              );
            },
          }
        );
      }
    }
  };

  const handleStatus = (event: SelectChangeEvent<string | number>) => {
    let Status = ContentStatus.Active;
    switch ((event.target as HTMLSelectElement).value) {
      case 'DRAFT':
        Status = ContentStatus.Draft;
        break;
      case 'ACTIVE':
        Status = ContentStatus.Active;
        break;
      default:
        Status = ContentStatus.Inactive;
        break;
    }
    setStatus(Status);
  };
  const handleType = (event: SelectChangeEvent<string | number>) => {
    let Type = ContentType.Header;
    switch ((event.target as HTMLSelectElement).value) {
      case 'HEADER':
        Type = ContentType.Header;
        break;
      case 'BODY':
        Type = ContentType.Body;
        break;
      default:
        Type = ContentType.Footer;
        break;
    }
    setContent({
      ...content,
      contentType: Type,
    });
  };
  return (
    <>
      <Header
        selectedModule={selectedModule}
        handleModuleClick={(moduleId: string) => {
          if (moduleId !== 'admin') {
            window.location.href = `${process.env.REACT_APP_TAX_URL}/dashboard`;
          } else {
            setSelectedModule(moduleId);
          }
          return null;
        }}
        headerTitle={
          match.params.action === ActionType.EDIT
            ? t('pages.manageReusableContent.editContent')
            : t('pages.manageReusableContent.title')
        }
        prevPageName={t('pages.reusableContent.title')}
        width={0}
        goBack={goBack}
        button={<></>}
      />
      <Grid container spacing={3} style={{ minWidth: '90em' }}>
        <Grid item xs={12} md={3.5}>
          <Card
            sx={{
              borderRadius: '5px',
              boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
            }}
          >
            <CardHeader
              sx={(theme: Theme) => {
                return {
                  backgroundColor: theme.palette.cardHeader.main,
                  '& .MuiCardHeader-title': {
                    fontSize: '0.875rem',
                    fontWeight: 700,
                  },
                };
              }}
              title={t('pages.manageReusableContent.details')}
            />
            <CardContent>
              <Grid item xs={12} mb={2}>
                <Input
                  id="createContent"
                  data-testid="content-input"
                  label={t('pages.manageReusableContent.name')}
                  required
                  inputProps={{
                    'data-testid': 'content-input',
                    maxLength: 100,
                  }}
                  value={content?.title ?? ''}
                  onChange={(event) => {
                    setContent({ ...content, title: event.target.value });
                  }}
                  sx={{ width: '15em' }}
                  disabled={
                    content.status !== 'DRAFT' &&
                    match.params.action === ActionType.EDIT
                  }
                />
              </Grid>
              <Grid item xs={10} mb={2}>
                {match.params.action === ActionType.EDIT &&
                  content.status === 'DRAFT' && (
                    <Select
                      options={statusList}
                      id="status"
                      data-testid="content-select"
                      label={t('pages.manageReusableContent.status')}
                      onChange={handleStatus}
                      value={status}
                      disabledOptions={draftList}
                      width="15em"
                    />
                  )}
                {match.params.action === ActionType.EDIT &&
                  content.status === 'ACTIVE' && (
                    <>
                      <Select
                        options={statusList}
                        id="status"
                        data-testid="content-select"
                        label={t('pages.manageReusableContent.status')}
                        onChange={handleStatus}
                        value={status}
                        disabledOptions={activeList}
                        width="15em"
                      />
                      <Button
                        id="new_Version"
                        onClick={() => newVersionHandler()}
                        sx={{ mt: 2, ml: 1 }}
                      >
                        {t('pages.manageReusableContent.newVersion')}
                      </Button>
                    </>
                  )}
                {match.params.action !== ActionType.EDIT && (
                  <Select
                    options={statusList}
                    id="status"
                    data-testid="content-select"
                    label={t('pages.manageReusableContent.status')}
                    onChange={handleStatus}
                    value={status}
                    disabled
                    width="15em"
                  />
                )}
              </Grid>
              <Grid item xs={10} mb={2}>
                <Select
                  options={typeList}
                  id="type"
                  label={t('pages.manageReusableContent.type')}
                  onChange={handleType}
                  value={content.contentType}
                  width="15em"
                />
              </Grid>
              <Grid item xs={5} mb={1}>
                <Dropzone
                  id={'dropzone'}
                  accessToken={accessToken}
                  acceptedFiles={[{ 'text/xml': ['.xml'] }]}
                  acceptFormat={'.xml'}
                  maxFiles={1}
                  noClick={true}
                  noKeyboard={true}
                  dropzoneWithButton={true}
                  labelButton={'Upload'}
                  setFile={setContentFile}
                  upload={upload}
                  isLoading={dropzoneLoading}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Button
                  onClick={() => handleSaveContent()}
                  id="saveBtn-updateContent"
                  data-testid="update-button"
                  sx={{ mt: 1, mb: 1 }}
                  disabled={content.title.length < 1 || dropzoneLoading}
                  size="medium"
                >
                  {match.params.action === ActionType.EDIT
                    ? t('pages.manageReusableContent.buttons.update')
                    : t('pages.manageReusableContent.buttons.save')}
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8.5}>
          <Card
            sx={{
              borderRadius: '5px',
              boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
            }}
          >
            <CardHeader
              sx={(theme: Theme) => {
                return {
                  backgroundColor: theme.palette.cardHeader.main,
                  '& .MuiCardHeader-title': {
                    fontSize: '0.875rem',
                    fontWeight: 700,
                  },
                };
              }}
              title={t('pages.manageReusableContent.history')}
            />
            <CardContent>
              <>
                {historyData?.ReusableContentHistory && (
                  <BasicTable
                    columns={columns}
                    data={historyData?.ReusableContentHistory || []}
                  />
                )}
              </>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default ManageReusableContent;
