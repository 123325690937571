import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { Grid } from '@mui/material';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import { textNotEmptyExp, emailExp } from 'common/regexp';
import useMaskInput from 'hooks/useMaskInput';
import { useTranslation } from 'react-i18next';
import { TenantIdp } from 'types/tenants';

interface TenantIdpProps {
  tenantIdp: TenantIdp;
  handleValidation: Dispatch<SetStateAction<boolean>>;
}

function TenantIdentityProvider({
  tenantIdp,
  handleValidation,
}: TenantIdpProps): JSX.Element {
  const { t } = useTranslation();

  const adminNameMask = useMaskInput(
    '',
    '',
    textNotEmptyExp,
    tenantIdp.managementAdminName ?? ''
  );
  const adminEmailMask = useMaskInput(
    '',
    t('pages.tenantConfig.errors.mgtAdminEmail'),
    emailExp,
    tenantIdp.managementAdminEmail ?? ''
  );

  const handleAdminEmailHelperText = (): string => {
    return adminEmailMask.disabledValue &&
      tenantIdp.managementAdminEmail.length > 0
      ? adminEmailMask.maskError
      : '';
  };

  useEffect(() => {
    const isValid =
      !adminNameMask.disabledValue && !adminEmailMask.disabledValue;
    handleValidation(isValid);
  }, [adminNameMask, adminEmailMask, handleValidation]);

  useEffect(() => {}, []);

  return (
    <Grid id="tidp" container spacing={1}>
      <Grid item xs={10} mb={0.5}>
        <Input
          id="openIdMetadataUrl"
          label={t('pages.tenantConfig.tenantIdp.identityProvider')}
          required
          value={'RSI Identity Provider'}
          disabled
        />
      </Grid>
      <Grid item xs={10} mb={0.5}>
        <Input
          id="managementAdminName"
          label={t('pages.tenantConfig.tenantIdp.mgtAdminName')}
          required
          value={tenantIdp.managementAdminName}
        />
      </Grid>
      <Grid item xs={10} mb={0.5}>
        <Input
          id="managementAdminEmail"
          label={t('pages.tenantConfig.tenantIdp.mgtAdminEmail')}
          required
          value={tenantIdp.managementAdminEmail}
          helperText={handleAdminEmailHelperText()}
        />
      </Grid>
    </Grid>
  );
}

export default TenantIdentityProvider;
