import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { UserRole } from 'types/roles';

const fetchUniqueTenantName = async (search: string): Promise<UserRole[]> => {
  const result = await axios.get(
    `${process.env.REACT_APP_TENANT}/tenant/validate/name?tenantName=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return result.data.user;
};

export default function useUniqueTenantName(
  search: string
): UseQueryResult<UserRole[], Error> {
  return useQuery<UserRole[], Error>(
    ['users'],
    () => {
      return fetchUniqueTenantName(search);
    },
    {
      enabled: false,
      retry: 1,
    }
  );
}
