import React, { useState } from 'react';

import { Grid, SelectChangeEvent } from '@mui/material';
import DefaultDataTable from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DefaultDataTable';
import HeaderColumn from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/HeaderColumn';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/TableCells/DateCell';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select/Select';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select/Select';
import ActionMenu from 'components/ActionMenu';
import Loading from 'components/Loading';
import { gql } from 'graphql-request';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { CmGetChannels } from 'types/graphTypes';

const getChannels = gql`
  query ($fetchSchedules: Boolean!, $module: String!) {
    getChannels(fetchSchedules: $fetchSchedules, module: $module) {
      id
      channelName
      createdDttm
    }
  }
`;

function ManageChannel(): JSX.Element {
  const [platform, setPlatform] = useState<string>('');
  const { t } = useTranslation();

  const accessToken = useGetAccessToken();

  const { data: data, isLoading } = useQueryRequest<{
    getChannels: [CmGetChannels];
  }>('channelInfo', accessToken, getChannels, {
    module: 'Property',
    fetchSchedules: false,
  });

  useUpdateTitle(t('pages.manageChannel.title'));

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    setPlatform((event.target as HTMLSelectElement).value);
  };

  const channelColumns: Column[] = [
    {
      Header: <HeaderColumn localization={t('pages.manageChannel.name')} />,
      accessor: 'channelName',
      Cell: (props: CellProps<Record<string, unknown>, React.ReactNode>) => {
        return (
          <Link
            to={{
              pathname: `/managechannels/edit`,
            }}
          >
            {props.value}
          </Link>
        );
      },
    },
    {
      Header: <HeaderColumn localization={t('pages.manageChannel.created')} />,
      accessor: 'createdDttm',
      Cell: ({ value }) => {
        return <DateCell dateString={value} />;
      },
    },
    {
      Header: <HeaderColumn localization={t('pages.manageChannel.action')} />,
      id: 'channel-action',
      Cell: () => {
        return <ActionMenu />;
      },
    },
  ];
  //TODO: replace hardcoded platformTypes with a Lookup - Jira action item documented for product team
  const platformTypes: SelectType[] = [
    { key: 'select', desc: 'Select' },
    { key: 'platform', desc: 'Platform' },
    { key: 'revenue', desc: 'Revenue (ITS)' },
    { key: 'property', desc: 'Property' },
    { key: 'labor', desc: 'Labor' },
  ];

  if (!isLoading && data) {
    return (
      <>
        <Grid container>
          <Grid item lg={12}>
            <DefaultDataTable
              columns={channelColumns}
              data={data.getChannels}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
              customHeader={
                <Select
                  options={platformTypes}
                  value={platform}
                  id="type"
                  label={t('pages.manageChannel.sortChannels')}
                  onChange={handleChange}
                />
              }
            />
          </Grid>
        </Grid>
      </>
    );
  }

  if (isLoading) {
    return <Loading />;
  }
  return <></>;
}
export default ManageChannel;
