import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Card,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import {
  Button,
  DefaultDataTable,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import SelectRole from 'components/SelectRole';
import { isBefore, isToday } from 'date-fns';
import { AssignAction } from 'hooks/useAssignRoleUsers';
import useAssignRoleUsers from 'hooks/useAssignRoleUsers';
import useGetAccessToken from 'hooks/useGetAccessToken';
import { useModulesList, useRolesByModule } from 'hooks/useManageRoles';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { AssignedUserRole, QueryRole } from 'types/roles';
import { QueryModule } from 'types/tenants';
import { checkDatesEqual, isDateInvalid } from 'utils/date-util';

import RowSubTable from '../RowSubTable';

interface SelectedUserRolesProps {
  values: QueryRole;
  index: number;
  toggleRowExpanded: (id: string[], value?: boolean | undefined) => void;
}

interface RoleDates {
  roleId: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  assignedDates?: true;
}

interface Props {
  tenantId: string;
  userId: string;
  roles: AssignedUserRole[];
  refetchUserRoles: () => void;
}

export default function AvailableRoles({
  tenantId,
  userId,
  roles,
  refetchUserRoles,
}: Props) {
  const dispatch = useAppDispatch();
  const [selectedRoleId, setSelectedRoleId] = useState<string>();
  const [selectedUserRoles, setSelectedUserRoles] = useState<
    SelectedUserRolesProps[]
  >([]);
  const [rolesDate, setRolesDate] = useState<RoleDates[]>([]);
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.editUserRole',
  });
  const { t: n } = useTranslation();
  const { mutate: addUsers } = useAssignRoleUsers();
  const accessToken = useGetAccessToken();
  const { data: moduleList, isSuccess: isSuccessModuleList } =
    useModulesList(accessToken);
  const {
    data: rolesByModule,
    refetch: refetchRolesByModule,
    isFetching,
  } = useRolesByModule(accessToken, selectedRoleId);
  const onSelectRole = (moduleId: string) => setSelectedRoleId(moduleId);
  const onResetRoleDate = (roleId: string) =>
    setRolesDate(rolesDate.filter((r) => r.roleId !== roleId));

  const onCheckSelectedRoles =
    (row: SelectedUserRolesProps) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newRows = [...selectedUserRoles];
      if (!event.target.checked) {
        newRows = selectedUserRoles.filter(
          (p) => p.values.roleId !== row.values.roleId
        );
        row.toggleRowExpanded([row.index.toString()], false);
        setSelectedUserRoles(newRows);
        onResetRoleDate(row.values.roleId);
      } else {
        row.toggleRowExpanded([row.index.toString()], true);
        setSelectedUserRoles([...selectedUserRoles, row]);
      }
    };

  /**
   * Creates Header component
   * @param {roleId}.
   * @param {'startDate' | 'endDate'} key - kind of date will be changed
   * @return {function(Date | null): void}
   */
  const onChangeDates = useCallback(
    (roleId: string, key: 'startDate' | 'endDate') => (date: Date | null) => {
      const roleDate = rolesDate.find((r) => r.roleId === roleId);
      if (roleDate) {
        setRolesDate(
          rolesDate.map((r) =>
            r.roleId !== roleId ? r : { ...r, [key]: date }
          )
        );
      } else {
        let startDate = undefined;
        let endDate = undefined;

        if (key === 'startDate' && date) startDate = date;
        if (key === 'endDate' && date) endDate = date;

        setRolesDate([
          ...rolesDate,
          {
            roleId,
            startDate,
            endDate,
            assignedDates: true,
          },
        ]);
      }
    },
    [rolesDate, setRolesDate]
  );

  const handleRadio = (roleId: string, value: 'true' | 'false') => {
    if (value === 'false') {
      setRolesDate([
        ...rolesDate.map((role) =>
          role.roleId !== roleId
            ? role
            : {
                ...role,
                startDate: undefined,
                endDate: undefined,
                assignedDates: undefined,
              }
        ),
      ]);
    } else {
      setRolesDate([
        ...rolesDate,
        {
          roleId,
          startDate: undefined,
          endDate: undefined,
          assignedDates: true,
        },
      ]);
    }
  };

  const filterRolesByModule = useCallback(
    (roleByModule: QueryRole) => {
      const role = roles.find((r) => r.roleId === roleByModule.roleId);
      return role === undefined;
    },
    [roles]
  );

  const roleList = useMemo(() => {
    return isFetching ? [] : rolesByModule?.filter(filterRolesByModule) || [];
  }, [isFetching, rolesByModule, filterRolesByModule]);

  const checkSelectedAll = useMemo(() => {
    return (
      roleList &&
      roleList.length !== 0 &&
      selectedUserRoles.length == roleList.length
    );
  }, [roleList, selectedUserRoles]);

  const noRolesSelected = useMemo(() => {
    if (selectedUserRoles.length === 0) return t('noRolesSelected');
  }, [selectedUserRoles, t]);

  const assignRoles = () => {
    const userRoleMapEntries = selectedUserRoles.map(({ values }) => {
      const roleDate = rolesDate.find((r) => r.roleId === values.roleId);
      return {
        userId,
        roleId: values.roleId,
        startDate: roleDate?.startDate,
        endDate: roleDate?.endDate,
      };
    });

    addUsers(
      {
        userCommand: {
          tenantId,
          userRoleMapEntries,
          mapAction: AssignAction.ASSIGN,
        },
        token: accessToken,
      },
      {
        onSuccess: () => {
          setSelectedUserRoles([]);
          setRolesDate([]);
          refetchRolesByModule();
          refetchUserRoles();
          dispatch(
            addMessage({
              message: n('components.message.success'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              message: n('components.message.networkerror'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  };

  const rolesColumns: Column[] = [
    {
      Header: ({ toggleRowExpanded, toggleAllRowsExpanded }) => (
        <Checkbox
          id={'selectAllCheck'}
          checked={checkSelectedAll}
          onChange={(event) => {
            let newSelectedRows = [...selectedUserRoles];
            if (event.target.checked && roleList)
              newSelectedRows = roleList.map((r, index) => ({
                index,
                toggleRowExpanded,
                values: { ...r },
              }));
            else {
              newSelectedRows = [];
              toggleAllRowsExpanded(false);
              setRolesDate([]);
            }
            setSelectedUserRoles(newSelectedRows);
          }}
        />
      ),
      disableSortBy: true,
      accessor: 'roleId',
      Cell: ({ row, toggleRowExpanded }) => (
        <Checkbox
          {...row.getToggleRowExpandedProps()}
          checked={
            selectedUserRoles.findIndex(
              (p) => p.values.roleId === row.values.roleId
            ) > -1
          }
          onChange={onCheckSelectedRoles({
            toggleRowExpanded,
            index: row.index,
            values: { ...(row.values as QueryRole) },
          })}
        />
      ),
    },
    {
      Header: () => t('roleName'),
      accessor: 'roleName',
    },
    {
      Header: () => t('description'),
      accessor: 'roleDescription',
    },
    {
      Header: () => t('module'),
      accessor: 'moduleName',
    },
    {
      Header: () => t('rolesAssigned'),
      accessor: 'rolesAssigned',
    },
  ];

  const areSelectedDates = useMemo(() => {
    const status =
      rolesDate.filter(
        ({ startDate, endDate, assignedDates }) =>
          assignedDates && !startDate && !endDate
      ).length !== 0;

    return status;
  }, [rolesDate]);

  const areValidStartDates = useMemo(() => {
    let status = true;

    rolesDate.forEach(({ startDate }) => {
      if (startDate) {
        if (isDateInvalid(startDate)) {
          status = false;
          return;
        }

        if (!isToday(startDate) && isBefore(startDate, new Date())) {
          status = false;
          return;
        }
      }
    });

    return status;
  }, [rolesDate]);

  const areValidEndDates = useMemo(() => {
    let status = true;

    rolesDate.forEach(({ startDate, endDate }) => {
      if (endDate) {
        if (isDateInvalid(endDate)) {
          status = false;
          return;
        }

        const today = new Date();

        if (
          !checkDatesEqual(startDate || today, endDate) &&
          isBefore(endDate, startDate || today)
        ) {
          status = false;
          return;
        }
      }
    });

    return status;
  }, [rolesDate]);

  const areValidDates = useMemo(() => {
    return !areValidStartDates || !areValidEndDates || areSelectedDates;
  }, [areValidStartDates, areValidEndDates, areSelectedDates]);

  useEffect(() => {
    if (
      isSuccessModuleList &&
      !selectedRoleId &&
      moduleList &&
      moduleList.length > 0
    ) {
      const firstModule: QueryModule = moduleList[0];
      setSelectedRoleId(`${firstModule.moduleId}`);
    }
  }, [isSuccessModuleList, selectedRoleId, setSelectedRoleId, moduleList]);

  useEffect(() => {
    if (selectedRoleId) setSelectedUserRoles([]);
  }, [selectedRoleId]);

  return (
    <Grid container sx={{ width: '95vw' }}>
      <Grid item xs={4}>
        <Box sx={{ p: 2 }}>
          <Typography sx={{ mt: 1, mb: 2 }} variant="h4">
            {t('textAvailableRoles')}
          </Typography>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {t('labelAvailableSelectRoles')}
          </Typography>
          <Card>
            <List
              dense
              sx={{
                bgcolor: 'background.paper',
                overflow: 'auto',
                height: 400,
                px: 1,
              }}
            >
              {noRolesSelected}
              {selectedUserRoles.map((s, key) => (
                <ListItem key={key} disablePadding>
                  <ListItemButton sx={{ p: 0, m: 0 }}>
                    <ListItemIcon>
                      <Checkbox
                        disableRipple
                        checked={
                          selectedUserRoles.findIndex(
                            (p) => p.values.roleId === s.values.roleId
                          ) > -1
                        }
                        onChange={onCheckSelectedRoles(s)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={s.values.roleName} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Card>
          <Button
            id="assignSelectedRoles"
            sx={{ mt: 2 }}
            onClick={assignRoles}
            disabled={selectedUserRoles.length === 0 || areValidDates}
          >
            {t('assignSelectedRolesButton')}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Grid sx={{ mb: '-4.2em', ml: '1em' }}>
          {moduleList && (
            <SelectRole
              moduleList={moduleList}
              handleChange={onSelectRole}
              selectedRole={selectedRoleId}
              showAll={false}
            />
          )}
        </Grid>
        {isFetching && <Loading />}
        {!isEmpty(roleList) && (
          <DefaultDataTable
            columns={rolesColumns}
            data-testid="user-roles-data"
            data={roleList}
            renderRowSubComponent={(row) => {
              const roleDates = rolesDate.find(
                (r) => r.roleId === row.values.roleId
              );
              return (
                <RowSubTable
                  roleId={row.values.roleId}
                  startDate={roleDates?.startDate || null}
                  endDate={roleDates?.endDate || null}
                  onChangeDates={onChangeDates}
                  handleRadio={handleRadio}
                />
              );
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
