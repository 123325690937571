import axios from 'axios';
import { useMutation } from 'react-query';
import { ErrorResponse } from 'types/graphqlErrors';

interface SaveData<T> {
  mutationKey: string | string[];
  params: T;
  query: string;
  token: string | undefined;
  paramsId: string;
}

export default function useMutationRequest<T>() {
  return useMutation((data: SaveData<T>) => {
    return axios
      .post(
        `${process.env.REACT_APP_GRAPH}`,
        {
          query: data.query,
          variables: { [data.paramsId]: { ...data.params } },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${data.token}`,
          },
        }
      )
      .then((response) => response.data as T | ErrorResponse);
  });
}
