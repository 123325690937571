import { ChangeEvent } from 'react';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { DataDisplay, Input, Select } from '@revenue-solutions-inc/revxcoreui';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { ConfigurationResponse } from 'types/graphTypes';

interface Props {
  selectedConfigType: ConfigurationResponse;
  handleChange: (config: ConfigurationResponse) => void;
}

const mockConfigDomains: SelectType[] = [
  { key: '0', desc: 'EntityManagement' },
  { key: '1', desc: 'EntityAccounting' },
  { key: '2', desc: 'Asset' },
  { key: '3', desc: 'TenantManagement' },
  { key: '4', desc: 'RoleManagement' },
  { key: '5', desc: 'Correspondence' },
  { key: '6', desc: 'WorkflowManagement' },
  { key: '7', desc: 'ReferenceSchema' },
];

const mockConfigModules: SelectType[] = [
  { key: '0', desc: 'Property' },
  { key: '1', desc: 'Revenue' },
  { key: '2', desc: 'UnemploymentTax' },
  { key: '3', desc: 'Platform' },
];

function ConfigurationDetails({ selectedConfigType, handleChange }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configTypeEditor',
  });

  const handleDomainChange = (event: SelectChangeEvent<string | number>) => {
    const newConfig = { ...selectedConfigType };
    newConfig.configurationDomain = (event.target as HTMLSelectElement).value;
    handleChange(newConfig);
  };

  const handleModuleChange = (event: SelectChangeEvent<string | number>) => {
    const newConfig = { ...selectedConfigType };
    newConfig.configurationModule = (event.target as HTMLSelectElement).value;
    handleChange(newConfig);
  };

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newConfig = { ...selectedConfigType };
    newConfig.configurationType = event.target.value;
    handleChange(newConfig);
  };

  return (
    <>
      {selectedConfigType.platformConfigurationId !== '' && (
        <>
          <Box sx={{ pb: '1em' }}>
            <Typography variant="h2" paddingBottom="1em" display="inline">
              <TextSnippetIcon />
              {t('configDetails')}
            </Typography>
          </Box>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2.5}
          >
            <DataDisplay
              id="configurationType"
              label={t('configType')}
              data={selectedConfigType.configurationType || ''}
            />
            <DataDisplay
              id="configurationModule"
              label={t('configModule')}
              data={
                mockConfigModules.find(
                  (configModule) =>
                    configModule.key === selectedConfigType.configurationModule
                )?.desc ?? selectedConfigType.configurationModule
              }
            />
            <DataDisplay
              id="configurationDomain"
              label={t('configDomain')}
              data={
                mockConfigDomains.find(
                  (configDomain) =>
                    configDomain.key === selectedConfigType.configurationDomain
                )?.desc ?? selectedConfigType.configurationDomain
              }
            />
          </Stack>
        </>
      )}

      {selectedConfigType.platformConfigurationId === '' && (
        <>
          <Box sx={{ pb: '1em' }}>
            <Typography variant="h2" paddingBottom="1em" display="inline">
              <TextSnippetIcon />
              {t('configDetails')}
            </Typography>
          </Box>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1.5}
          >
            <Input
              id="configurationType"
              label={t('configType')}
              value={selectedConfigType.configurationType || ''}
              onChange={handleTypeChange}
              sx={{ minWidth: '200px' }}
              required={true}
            />
            <Select
              id="configurationModule"
              label={t('configModule')}
              options={mockConfigModules}
              value={selectedConfigType.configurationModule}
              onChange={handleModuleChange}
              sx={{ minWidth: '200px' }}
              required={true}
            />
            <Select
              id="configurationDomain"
              label={t('configDomain')}
              options={mockConfigDomains}
              value={selectedConfigType.configurationDomain}
              onChange={handleDomainChange}
              sx={{ minWidth: '200px' }}
              required={true}
            />
          </Stack>
        </>
      )}
    </>
  );
}

export default ConfigurationDetails;
