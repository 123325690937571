import { useEffect, useState } from 'react';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Card, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import ListBox from '@revenue-solutions-inc/revxcoreui/material/controls/ListBox';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import Loading from 'components/Loading';
import AttributeDetails from 'components/manageConfigTools/AttributeDetails';
import AttributeList from 'components/manageConfigTools/AttributeList';
import ConfigurationDetails from 'components/manageConfigTools/ConfigurationDetails';
import { addConfigurationQuery } from 'components/manageConfigTools/queries/addConfigurationQuery';
import { deleteConfigurationQuery } from 'components/manageConfigTools/queries/deleteConfigurationQuery';
import { getConfigurationTypesQuery } from 'components/manageConfigTools/queries/getConfigurationTypesQuery';
import { updateConfigurationQuery } from 'components/manageConfigTools/queries/updateConfigurationQuery';
import MessageAlert from 'components/MessageAlert';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMutationRequest from 'hooks/useMutationRequest';
import useQueryRequest from 'hooks/useQueryRequest';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { configurationResponseDefault } from 'types/configurations';
import { ErrorResponse } from 'types/graphqlErrors';
import {
  ConfigurationResponse,
  Attribute,
  CreateConfigurationInput,
  UpdateConfigurationInput,
} from 'types/graphTypes';

function ConfigTypeEditor(): JSX.Element {
  const { t } = useTranslation();
  useUpdateTitle(t('pages.configTypeEditor.title'));
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();

  const [selectedConfigType, setSelectedConfigType] =
    useState<ConfigurationResponse>();
  const [configTypes, setConfigTypes] = useState<ConfigurationResponse[]>([]);
  const [selectedAttribute, setSelectedAttribute] = useState<Attribute>();
  const [open, setOpen] = useState(false);
  const {
    isLoading,
    isError,
    data,
    refetch: refetchTypes,
  } = useQueryRequest<{
    getConfigurationTypes: [ConfigurationResponse];
  }>(['getConfigurationTypes'], accessToken, getConfigurationTypesQuery, {
    configurationDomain: '',
  });

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);

  const addConfiguration = useMutationRequest<CreateConfigurationInput>();
  const updateConfiguration = useMutationRequest<UpdateConfigurationInput>();
  const deleteConfiguration = useMutationRequest<{
    platformConfigurationId: string;
  }>();

  const removeConfiguration = (platformConfigurationId: string) => {
    deleteConfiguration.mutate(
      {
        token: accessToken,
        mutationKey: 'remove-Record',
        query: deleteConfigurationQuery,
        params: { platformConfigurationId },
        paramsId: 'deleteConfigInput',
      },
      {
        onSuccess: () => {
          refetchTypes();
          setSelectedConfigType(undefined);
          dispatch(
            addMessage({
              message: t('components.message.success'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t('components.message.networkerror'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  };

  const deleteAttribute = () => {
    if (!!selectedConfigType && !!selectedAttribute) {
      const newConfig: ConfigurationResponse = {
        ...selectedConfigType,
      };
      if (newConfig.platformConfigurationInfo) {
        newConfig.platformConfigurationInfo.configurationSection[0].group[0].attribute =
          newConfig?.platformConfigurationInfo?.configurationSection[0].group[0].attribute?.filter(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (attr) => attr.attributeName! !== selectedAttribute.attributeName
          ) ?? [];
        setSelectedConfigType(newConfig);
      }
      setSelectedAttribute(undefined);
    }
  };

  const updateAttribute = (newAttribute: Attribute) => {
    if (!!selectedConfigType && !!selectedAttribute) {
      const newConfig: ConfigurationResponse = {
        ...selectedConfigType,
      };
      if (newConfig.platformConfigurationInfo) {
        const oldInd =
          newConfig?.platformConfigurationInfo?.configurationSection[0].group[0].attribute?.findIndex(
            (attr) => attr.attributeName === selectedAttribute.attributeName
          );

        const attributes = [
          ...newConfig?.platformConfigurationInfo?.configurationSection[0]
            .group[0].attribute,
        ];

        attributes[oldInd] = newAttribute;
        newConfig.platformConfigurationInfo.configurationSection[0].group[0].attribute =
          attributes;

        setSelectedAttribute(newAttribute);
        setSelectedConfigType(newConfig);
      }
    }
  };

  const saveConfiguration = () => {
    if (selectedConfigType == undefined) return;
    if (selectedConfigType?.platformConfigurationId) {
      updateConfiguration.mutate(
        {
          token: accessToken,
          mutationKey: 'update-Record',
          query: updateConfigurationQuery,
          params: {
            configurationDomain: parseInt(
              selectedConfigType.configurationDomain
            ),
            configurationModule: parseInt(
              selectedConfigType.configurationModule
            ),
            isSchema: true,
            configurationName: selectedConfigType.configurationName,
            configurationType: selectedConfigType.configurationType,
            platformConfigurationId: selectedConfigType.platformConfigurationId,
            platformConfigurationInfo:
              selectedConfigType.platformConfigurationInfo,
          },
          paramsId: 'newConfiguration',
        },
        {
          onSuccess: function (
            response: ErrorResponse | CreateConfigurationInput
          ) {
            if ((response as ErrorResponse)?.errors) {
              dispatch(
                addMessage({
                  message: (response as ErrorResponse).errors[0].extensions
                    .response.body.message,
                  type: MessageType.Error,
                })
              );
            } else {
              refetchTypes();
              dispatch(
                addMessage({
                  message: t('components.message.success'),
                  type: MessageType.Success,
                })
              );
            }
          },
          onError: () => {
            dispatch(
              addMessage({
                message: 'components.message.networkerror',
                type: MessageType.Error,
              })
            );
          },
        }
      );
    } else {
      addConfiguration.mutate(
        {
          token: accessToken,
          mutationKey: 'add-Record',
          query: addConfigurationQuery,
          params: {
            configurationDomain: parseInt(
              selectedConfigType.configurationDomain
            ),
            configurationModule: parseInt(
              selectedConfigType.configurationModule
            ),
            isSchema: true,
            configurationName: selectedConfigType.configurationType,
            configurationType: selectedConfigType.configurationType,
            platformConfigurationInfo:
              selectedConfigType.platformConfigurationInfo,
          },
          paramsId: 'newConfiguration',
        },
        {
          onSuccess: function (
            response: ErrorResponse | CreateConfigurationInput
          ) {
            if ((response as ErrorResponse)?.errors) {
              dispatch(
                addMessage({
                  message: (response as ErrorResponse).errors[0].extensions
                    .response.body.message,
                  type: MessageType.Error,
                })
              );
            } else {
              refetchTypes();
              dispatch(
                addMessage({
                  message: t('components.message.success'),
                  type: MessageType.Success,
                })
              );
            }
          },
          onError: () => {
            dispatch(
              addMessage({
                message: 'components.message.networkerror',
                type: MessageType.Error,
              })
            );
          },
        }
      );
    }
  };

  useEffect(() => {
    if (data) setConfigTypes(data.getConfigurationTypes);
  }, [data]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [dispatch, isError, t]);

  const ConfirmButton = (
    <Button
      id="confirmBtn"
      variant="outlined"
      type="secondary"
      onClick={() => {
        if (!!selectedConfigType) {
          removeConfiguration(selectedConfigType?.platformConfigurationId);
          setOpen(false);
        }
      }}
    >
      {t('pages.configTypeEditor.confirmButton')}
    </Button>
  );

  useEffect(() => {
    if (selectedConfigType?.configurationType == '') {
      setSaveDisabled(true);
    } else if (selectedConfigType?.configurationModule == '') {
      setSaveDisabled(true);
    } else if (selectedConfigType?.configurationDomain == '') {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [selectedConfigType]);
  return (
    <>
      {/* TODO: change this when we switch to new messaging way */}
      <MessageAlert
        message={t('pages.configTypeEditor.confirmButtonMsg')}
        type="info"
        content={ConfirmButton}
        open={open}
        setOpen={setOpen}
      />
      <Card
        sx={{
          padding: '1em',
          minHeight: '32em',
        }}
      >
        <Grid container sx={{}} spacing={2}>
          <Grid item xs={12} md={3}>
            <Box sx={{ pb: '1em' }}>
              <Typography variant="h2" paddingBottom="1em" display="inline">
                <AccountTreeIcon />
                {t('pages.configTypeEditor.configTypes')}
              </Typography>
              <Button
                id={'addBtn-configTypeEditor'}
                onClick={() => {
                  const newConfig: ConfigurationResponse = {
                    ...configurationResponseDefault,
                  };
                  setSelectedConfigType(newConfig);
                }}
                sx={{ float: 'right' }}
              >
                {t('pages.configTypeEditor.add')}
              </Button>
            </Box>
            {isLoading && <Loading />}
            {!isLoading && configTypes.length > 0 && (
              <ListBox
                id="configList-configTypeEditor"
                options={configTypes.map((ct) => ct.configurationName)}
                onChange={(configType: string) => {
                  setSelectedConfigType(
                    configTypes.find(
                      (ct) => ct.configurationName === configType
                    )
                  );
                  setSelectedAttribute(undefined);
                }}
                sx={{
                  maxHeight: '26em',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            {selectedConfigType && (
              <Card
                sx={{
                  padding: '1em',
                  minHeight: '32em',
                  position: 'relative',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <ConfigurationDetails
                      selectedConfigType={selectedConfigType}
                      handleChange={(newConfig: ConfigurationResponse) => {
                        setSelectedConfigType(newConfig);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <AttributeList
                      selectedConfigType={selectedConfigType}
                      setSelectedConfigType={setSelectedConfigType}
                      setSelectedAttribute={setSelectedAttribute}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {selectedAttribute && (
                      <AttributeDetails
                        attribute={selectedAttribute}
                        handleChange={updateAttribute}
                        handleDelete={deleteAttribute}
                      />
                    )}
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '5em',
                    right: '40%',
                    bottom: '0',
                  }}
                >
                  <Button
                    id={'saveButton-configurationDetails'}
                    onClick={saveConfiguration}
                    sx={{ mr: '1em' }}
                    disabled={saveDisabled}
                  >
                    {t('save')}
                  </Button>
                  <Button
                    id={'cancelButton-configurationDetails'}
                    onClick={() => {
                      setSelectedConfigType(undefined);
                    }}
                  >
                    {t('cancel')}
                  </Button>
                </Box>
                <Button
                  id={'deleteButton-configurationDetails'}
                  onClick={() => {
                    setOpen(true);
                  }}
                  type={'secondary'}
                  sx={{ position: 'absolute', bottom: '1.5em', right: '1.5em' }}
                >
                  {t('delete')}
                </Button>
              </Card>
            )}
            {!selectedConfigType && (
              <>
                <Typography variant="h1" sx={{ pt: '2em' }}>
                  {t('pages.configTypeEditor.howToUse')}
                </Typography>
                <Typography variant="h2" sx={{ pt: '2em' }}>
                  {t('pages.configTypeEditor.instructions')}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default ConfigTypeEditor;
