import axios from 'axios';
import { useMutation } from 'react-query';
import { TenantCommand } from 'types/tenants';

interface SaveTenantCommand {
  tenantCommand: TenantCommand;
  token: string;
}

export default function useSaveTenant() {
  return useMutation(async (saveTenant: SaveTenantCommand) => {
    const result = await axios.post(
      `${process.env.REACT_APP_TENANT}/tenantcommand/save`,
      saveTenant.tenantCommand,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return result.data as SaveTenantCommand;
  });
}
