import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { UserRole } from 'types/roles';

interface ResultData {
  tenantId: string;
  user: UserRole[];
}

const fetchUser = async (
  token: string | undefined,
  userId: string
): Promise<ResultData> => {
  const result = await axios.get(
    `${process.env.REACT_APP_ROLE}/query/users/${userId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

export default function useGetUserById(
  accessToken: string | undefined,
  userId: string
): UseQueryResult<ResultData, Error> {
  return useQuery<ResultData, Error>(
    ['userRole'],
    () => {
      return fetchUser(accessToken, userId);
    },
    {
      enabled: !!accessToken && !!userId,
    }
  );
}
