import axios from 'axios';
import get from 'lodash/get';
import { useQuery, UseQueryResult } from 'react-query';
import { ContractualInfo } from 'types/tenants';

function fetchLookupModules(
  token: string | undefined
): Promise<ContractualInfo['modules'] | undefined> {
  return axios
    .get<ContractualInfo['modules']>(
      `${process.env.REACT_APP_LOOKUP}/modules`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return get(response, 'data');
    });
}

export default function useLookupModules(
  accessToken: string | undefined
): UseQueryResult<ContractualInfo['modules'], Error> {
  return useQuery(
    ['search'],
    () => {
      return fetchLookupModules(accessToken);
    },
    {
      enabled: !!accessToken,
      cacheTime: 0,
    }
  );
}
