import React, { useEffect, useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import SingleSearch from '@revenue-solutions-inc/revxcoreui/material/layout/MenuBar/SingleSearch';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GlobalSearchResponse } from 'types/graphTypes';

import searchByName from './singleSearchScript';

const linkStyle = {
  p: 0,
  pl: 1,
  minWidth: 166,
  ':hover': {
    textDecoration: 'underline',
  },
  '.MuiTypography-root': {
    color: 'lightBlue1.main',
    cursor: 'pointer',
    fontSize: 14,
  },
};

// TODO: replace this with actual data
const listItems = [
  {
    id: 'Entity_ID',
    label: 'Entity',
  },
];

function SingleSearchWrapper(): JSX.Element {
  const { t } = useTranslation();
  const [searchName, setSearchName] = useState<string>('');
  const [searchType, setSearchType] = useState<string>('');
  const [results, setResults] = useState<GlobalSearchResponse[]>();
  const accessToken = useGetAccessToken();
  const history = useHistory();

  const { data: searchResults } = useQueryRequest<{
    GlobalSearch: GlobalSearchResponse[];
  }>(
    ['searchName', searchName],
    accessToken,
    searchByName,
    {
      searchName: searchName,
      searchType: searchType,
    },
    'searchName'
  );

  useEffect(() => {
    setResults(searchResults?.GlobalSearch);
  }, [searchResults]);

  return (
    <SingleSearch
      label={t('pages.appMenuBar.search')}
      placeholder={t('pages.appMenuBar.searchPlaceHolder')}
      searchResults={
        // TODO: This component needs to update according to search results JSON
        results?.length ? (
          <List>
            {results.map((item, index) => (
              <ListItem key={index} sx={{ p: 0 }}>
                {Object.values(item).map((val) => {
                  return (
                    <ListItemText
                      sx={linkStyle}
                      primary={val}
                      key={val}
                      onClick={() => history.push('/manageroles')}
                    />
                  );
                })}
              </ListItem>
            ))}
          </List>
        ) : (
          results?.length === 0 && (
            <Typography sx={{ pl: 1 }}>No Display Data</Typography>
          )
        )
      }
      onSearch={(val: string) => {
        setSearchName(val);
      }}
      searchOptions={listItems}
      handleSelect={setSearchType}
      displayValue={searchType}
      setResults={() => setResults(undefined)}
    />
  );
}

export default SingleSearchWrapper;
