import axios from 'axios';
import { useMutation } from 'react-query';
import { User, UserResponse } from 'types/roles';

interface SaveUser {
  user: User;
  token: string;
}

export default function useCreateUser() {
  return useMutation((saveUser: SaveUser) => {
    return axios
      .post(`${process.env.REACT_APP_ROLE}/command/user`, saveUser.user, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${saveUser.token}`,
        },
      })
      .then((response) => response.data as UserResponse);
  });
}
