import { useContext } from 'react';

import { Typography } from '@mui/material';
import { Input } from '@revenue-solutions-inc/revxcoreui';
import { ManageConfigToolsContext } from 'components/contexts/ManageConfigToolsProvider/ManageConfigToolsProvider';
import { CellProps } from 'react-table';
import { ConfigurationResponse } from 'types/graphTypes';

export default function ConfigurationName({
  row,
}: CellProps<ConfigurationResponse>) {
  const { selectedRecord, setSelectedRecord } = useContext(
    ManageConfigToolsContext
  );
  return (
    <>
      {row.original.platformConfigurationId ===
      selectedRecord?.platformConfigurationId ? (
        <Input
          label={''}
          value={selectedRecord?.configurationName}
          onChange={(event) => {
            if (selectedRecord)
              setSelectedRecord({
                ...selectedRecord,
                configurationName: event?.target.value,
              });
          }}
        />
      ) : (
        <Typography>{row.original.configurationName}</Typography>
      )}
    </>
  );
}
