import axios from 'axios';
import { useMutation } from 'react-query';

interface RoleEntry {
  roleId: string;
  userId: string;
  startDate?: Date;
  endDate?: Date;
}

export enum AssignAction {
  ASSIGN = 'assigned',
  UNASSIGN = 'unassigned',
}

//TODO: remove tenant id from contract
interface UpdateAssignRoleUsersCommand {
  tenantId: string;
  mapAction: AssignAction;
  userRoleMapEntries: RoleEntry[];
  notifyUser?: boolean;
}

interface UpdateRoleUserRequest {
  userCommand: UpdateAssignRoleUsersCommand;
  token: string | undefined;
}
export default function useAssignRoleUsers() {
  return useMutation(async ({ userCommand, token }: UpdateRoleUserRequest) => {
    const result = await axios.post(
      `${process.env.REACT_APP_ROLE}/command/userRoleMap`,
      userCommand,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data as UpdateAssignRoleUsersCommand;
  });
}
