import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { UserRole } from 'types/roles';

const fetchUsersWithRoles = async (
  token: string | undefined,
  search: string
): Promise<UserRole[]> => {
  const result = await axios.get(
    `${process.env.REACT_APP_ROLE}/query/users?search=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data.user;
};

export default function useGetUsersWithRoles(
  token: string | undefined,
  search: string
): UseQueryResult<UserRole[], Error> {
  return useQuery<UserRole[], Error>(
    ['users'],
    () => {
      return fetchUsersWithRoles(token, search);
    },
    {
      enabled: false,
    }
  );
}
