import { ReusableContentsResponse } from './graphTypes';

export enum ContentType {
  Header = 'HEADER',
  Body = 'BODY',
  Footer = 'FOOTER',
}
export enum ContentStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}
export interface ReusableContent {
  reusableContentId: string;
  fixedId: number | null;
  title: string;
  contentType: ContentType;
  status: ContentStatus;
  version: number;
  createdBy: string;
  updatedBy: string;
  createdDate: string;
  updatedDate: string;
  contentOpenXml: string;
}

const reusableContentDefault: ReusableContentsResponse = {
  reusableContentId: '',
  fixedId: 0,
  title: '',
  contentType: ContentType.Header,
  status: ContentStatus.Draft,
  version: 1,
  createdBy: '',
  updatedBy: '',
  createdDate: '',
  updatedDate: '',
  contentOpenXml: '',
};

export { reusableContentDefault };
