import { gql } from 'graphql-request';

export const getConfigurationGroupsQuery = gql`
  query GetConfigurationGroups {
    getConfigurationGroups {
      platformConfigurationGroupId
      name
      description
    }
  }
`;
