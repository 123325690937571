import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import NoResults from 'components/NoResults';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { RoleUser } from 'types/entities';
interface UsersByRole {
  usersByRole: RoleUser[];
}

function UsersByRoleInfo({ usersByRole }: UsersByRole): JSX.Element {
  const { t } = useTranslation();
  return !isEmpty(usersByRole) ? (
    <>
      <Typography
        variant="h3"
        sx={{ fontSize: 16, fontWeight: 'bold', marginBottom: 1 }}
      >
        {t('pages.viewRolesDashboard.usersByRole')}
      </Typography>
      {usersByRole.map(({ userId, name, email }) => (
        <ListItem
          key={`${userId}-${name}`}
          sx={{ padding: '0.1em 0' }}
          data-testid="user-role-list"
        >
          <ListItemText>{name}</ListItemText>
          <ListItemText>{email}</ListItemText>
        </ListItem>
      ))}
    </>
  ) : (
    <NoResults />
  );
}

export default UsersByRoleInfo;
