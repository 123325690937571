import { useContext, useMemo, CSSProperties } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { AssignedRolesContext } from 'components/contexts/AssignedRoles/AssignedRolesProvider';
import { isBefore, isToday } from 'date-fns';
import useAssignRoleUsers, { AssignAction } from 'hooks/useAssignRoleUsers';
import useGetAccessToken from 'hooks/useGetAccessToken';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { AssignedUserRole } from 'types/roles';
import { checkDatesEqual, isDateInvalid, toDate } from 'utils/date-util';

const widthHeight: CSSProperties = {
  width: '1.5em',
  height: '1.5em',
};

const fontSize = '1em';

interface UserRoleMapEntriesDates {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

const useStyles = makeStyles(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ palette: { grey1, error, secondaryGreen, white } }: any) => ({
    cancelButton: {
      ...widthHeight,
      background: error.main,
      '&:hover': {
        background: error.main,
      },
    },
    checkButton: {
      ...widthHeight,
      background: secondaryGreen.main,
      '&:hover': {
        background: secondaryGreen.main,
      },
      '&:disabled': {
        background: grey1.main,
      },
    },
    editButton: {
      border: `${grey1.main} 1px solid`,
      ...widthHeight,
    },
    icon: {
      color: white.main,
      fontSize,
    },
  })
);

export default function EditableButtonCell({
  row: { original },
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
CellProps<any>): JSX.Element {
  const classes = useStyles();
  const { assignedRoles, setAssignedRoles } = useContext(AssignedRolesContext);

  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { mutate: updateRoles } = useAssignRoleUsers();

  const { initialStartDate, initialEndDate, roleId } = useMemo(() => {
    const { startDate, endDate } = original as AssignedUserRole;
    return {
      roleId: original.roleId,
      initialStartDate: toDate(startDate),
      initialEndDate: toDate(endDate),
    };
  }, [original]);

  const { startDateValue, endDateValue, isEdit } = useMemo(() => {
    const role = assignedRoles?.roles.find((r) => r.roleId === roleId);
    return {
      ...role,
      startDateValue: role?.startDate || null,
      endDateValue: role?.endDate || null,
    };
  }, [assignedRoles, roleId]);

  const handleEditDates = (edit: boolean) => () => {
    if (assignedRoles) {
      if (edit)
        setAssignedRoles({
          ...assignedRoles,
          roles: [
            ...assignedRoles.roles,
            {
              roleId,
              isEdit: edit,
              startDate: initialStartDate,
              endDate: initialEndDate,
            },
          ],
        });
      else
        setAssignedRoles({
          ...assignedRoles,
          roles: [...assignedRoles.roles.filter((r) => r.roleId !== roleId)],
        });
    }
  };

  const isStartDateInvalid = useMemo(() => {
    if (startDateValue) {
      if (checkDatesEqual(initialStartDate, startDateValue)) return false;

      if (isDateInvalid(startDateValue)) return true;

      if (!isToday(startDateValue) && isBefore(startDateValue, new Date()))
        return true;
    }
    return false;
  }, [initialStartDate, startDateValue]);

  const isEndDateInvalid = useMemo(() => {
    if (endDateValue) {
      if (isDateInvalid(endDateValue)) return true;

      const today = new Date();
      if (
        !checkDatesEqual(startDateValue || today, endDateValue) &&
        isBefore(endDateValue, startDateValue || today)
      )
        return true;
    }
    return false;
  }, [startDateValue, endDateValue]);

  const isStartEndDatesValid = useMemo(() => {
    if (isStartDateInvalid || isEndDateInvalid) return false;
    return true;
  }, [isStartDateInvalid, isEndDateInvalid]);

  const handleUpdateDates = () => {
    if (assignedRoles && assignedRoles.userRole) {
      const {
        tenantId,
        userRole: { userId },
        roles,
      } = assignedRoles;

      const dates: UserRoleMapEntriesDates = {
        startDate: undefined,
        endDate: undefined,
      };

      if (startDateValue) dates.startDate = startDateValue;
      if (endDateValue) dates.endDate = endDateValue;

      updateRoles(
        {
          userCommand: {
            tenantId,
            mapAction: AssignAction.ASSIGN,
            userRoleMapEntries: [
              {
                roleId,
                userId,
                ...dates,
              },
            ],
          },
          token: accessToken,
        },
        {
          onSuccess: () => {
            setAssignedRoles({
              ...assignedRoles,
              isReset: true,
              roles: [...roles.filter((r) => r.roleId !== roleId)],
            });
            dispatch(
              addMessage({
                message: t('components.message.success'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('components.message.networkerror'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    }
  };

  return (
    <Box sx={{ flexDirection: 'row', flexShrink: 2, px: 1 }}>
      {!isEdit ? (
        <IconButton
          size="small"
          onClick={handleEditDates(true)}
          className={classes.editButton}
          aria-label="edit-button"
        >
          <EditIcon sx={{ fontSize }} />
        </IconButton>
      ) : (
        <Stack spacing={1} direction="row">
          <IconButton
            size="small"
            onClick={handleEditDates(false)}
            className={classes.cancelButton}
            aria-label="cancel-button"
          >
            <CloseIcon fontSize="small" className={classes.icon} />
          </IconButton>
          <IconButton
            size="small"
            className={classes.checkButton}
            disabled={!isStartEndDatesValid}
            onClick={handleUpdateDates}
            aria-label="check-button"
          >
            <CheckIcon className={classes.icon} />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
}
