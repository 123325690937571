import React from 'react';

import { useMsal } from '@azure/msal-react';
import { Grid, Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';

function Footer(): JSX.Element {
  const { t } = useTranslation();
  const { accounts } = useMsal();

  if (accounts.length > 0) {
    return (
      <MuiAppBar
        color="secondary"
        position="fixed"
        elevation={0}
        sx={{
          top: 'auto',
          bottom: 0,
          // TODO: below neutral.main color is not exist, so background color is not applying,
          // adding existing color so bgcolor will work, and later on we can change bgcolor when it is final
          backgroundColor: 'common.white',
          height: '48px',
        }}
        data-testid="footer-id"
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={6}>
              <Typography align="right">
                {t('pages.footer.loggedIn')}
                {accounts[0].name}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </MuiAppBar>
    );
  }
  return <Loading />;
}

export default Footer;
