import { gql } from 'graphql-request';

const deleteReusableContent = gql`
  mutation DeleteReusableContent($content: DeleteReusableContentInput!) {
    DeleteReusableContent(content: $content)
  }
`;

const getReusableContents = gql`
  query Query {
    Contents {
      reusableContentId
      fixedId
      title
      contentType
      status
      version
      contentOpenXml
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;

export { deleteReusableContent, getReusableContents };
