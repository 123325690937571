import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { Grid } from '@mui/material';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useGetLocale from 'hooks/useGetLocale';
import { useTranslation } from 'react-i18next';
import { LocaleInfo, SecurityInfo, Language, TimeZone } from 'types/tenants';

interface TenancyConfigProps {
  securityInfo: SecurityInfo;
  localeInfo: LocaleInfo;
  handleChange: (newLocaleInfo: LocaleInfo, securityInfo: SecurityInfo) => void;
  handleValidation: Dispatch<SetStateAction<boolean>>;
}

function TenancyConfig({
  securityInfo,
  localeInfo,
  handleChange,
  handleValidation,
}: TenancyConfigProps): JSX.Element {
  const { t } = useTranslation();
  const accessToken = useGetAccessToken();
  const { data: localeData } = useGetLocale(accessToken);

  const [languages, setLanguages] = useState<Language[]>([]);
  const [timezones, setTimezones] = useState<TimeZone[]>([]);

  useEffect(() => {
    const isValid =
      !!localeInfo.locationName &&
      !!localeInfo.timeZones &&
      !!localeInfo.languages &&
      !!securityInfo.frontDoor.domainName;

    handleValidation(isValid);
  }, [localeInfo, handleValidation, securityInfo.frontDoor.domainName]);

  useEffect(() => {
    const selectedLocation = localeData?.find(
      (locale) => locale.locationName === localeInfo.locationName
    );

    if (selectedLocation) {
      setLanguages(selectedLocation.languages);
      setTimezones(selectedLocation.timeZones);
    }
  }, [localeData, localeInfo]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={10} mb={0.5}>
        <Select
          label={t('pages.tenantConfig.tenancyConfiguration.locale')}
          required
          value={localeInfo.locationName}
          id="localeSelect"
          inputProps={{ 'data-testid': 'locale-select' }}
          options={
            localeData?.map((locale) => {
              return {
                key: locale.locationName ?? '',
                desc: locale.locationName ?? '',
              };
            }) ?? [{ key: ' ', desc: 'Select Locale' }]
          }
          onChange={(event) => {
            const selectedLocation = localeData?.find(
              (locale) => locale.locationName === event.target.value
            );

            if (selectedLocation) {
              handleChange(
                {
                  ...localeInfo,
                  locationName: selectedLocation.locationName,
                  currency: selectedLocation.currency,
                  languages: [
                    { ...selectedLocation.languages[0], selected: true },
                  ],
                  timeZones: [
                    { ...selectedLocation.timeZones[0], selected: true },
                  ],
                },
                securityInfo
              );
            }
          }}
        />
      </Grid>
      <Grid item xs={10} mb={0.5}>
        <Select
          label={t('pages.tenantConfig.tenancyConfiguration.language')}
          required
          value={
            languages.length > 0 && localeInfo.languages.length > 0
              ? localeInfo.languages[0].languageName
              : ''
          }
          id="languageSelect"
          options={
            languages?.map((language) => {
              return {
                key: language.languageName ?? '',
                desc: language.languageName ?? '',
              };
            }) ?? [{ key: ' ', desc: 'Select Language' }]
          }
          onChange={(event) => {
            const selectedLanguage = event.target.value as string;
            handleChange(
              {
                ...localeInfo,
                languages: [{ languageName: selectedLanguage, selected: true }],
              },
              securityInfo
            );
          }}
        />
      </Grid>
      <Grid item xs={10} mb={0.5}>
        <Select
          label={t('pages.tenantConfig.tenancyConfiguration.timeZone')}
          required
          value={
            timezones.length > 0 && localeInfo.timeZones.length > 0
              ? localeInfo.timeZones[0].timeZoneName
              : ''
          }
          id="timeZoneSelect"
          options={
            timezones?.map((timezone) => {
              return {
                key: timezone.timeZoneName ?? '',
                desc: timezone.timeZoneName ?? '',
              };
            }) ?? [{ key: ' ', desc: 'Select Time Zone' }]
          }
          onChange={(event) => {
            const selectedTimezone = event.target.value as string;
            handleChange(
              {
                ...localeInfo,
                timeZones: [{ timeZoneName: selectedTimezone, selected: true }],
              },
              securityInfo
            );
          }}
        />
      </Grid>
      <Grid item xs={10} mb={0.5}>
        <DataDisplay
          label={t('pages.tenantConfig.tenancyConfiguration.currency')}
          data={localeInfo.currency}
          id="currencySelect"
        />
      </Grid>
      <Grid item xs={10} mb={0.5}>
        <Input
          id="domainName"
          label={t('pages.tenantConfig.tenancyConfiguration.internalUrl')}
          required
          value={securityInfo.frontDoor.domainName}
          onChange={(event) => {
            handleChange(localeInfo, {
              ...securityInfo,
              frontDoor: { domainName: event.target.value },
            });
          }}
        />
      </Grid>
    </Grid>
  );
}

export default TenancyConfig;
