import { useEffect, useState } from 'react';

import FindInPageIcon from '@mui/icons-material/FindInPage';
import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material';
import {
  Button,
  DataDisplay,
  Input,
  Select,
} from '@revenue-solutions-inc/revxcoreui';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { Attribute } from 'types/graphTypes';

interface Props {
  attribute: Attribute;
  handleChange: (attribute: Attribute) => void;
  handleDelete: () => void;
}
const mockAttrType: SelectType[] = [
  { key: 'string', desc: 'string' },
  { key: 'int', desc: 'int' },
  { key: 'ReferenceData', desc: 'ReferenceData' },
  { key: 'DependentData', desc: 'DependentData' },
  { key: 'SubData', desc: 'SubData' },
  { key: 'Schema', desc: 'Schema' },
];

function AttributeDetails({ attribute, handleChange, handleDelete }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configTypeEditor',
  });

  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [tempAttribute, setTempAttribute] = useState<Attribute>(attribute);

  const handleAttrType = (event: SelectChangeEvent<string | number>) => {
    const newAttribute = { ...tempAttribute };
    newAttribute.attributeType = (event.target as HTMLSelectElement).value;
    setTempAttribute(newAttribute);
  };

  const handleEditClick = () => {
    setInEditMode(true);
  };
  const handleCancelClick = () => {
    setTempAttribute(attribute);
    setInEditMode(false);
  };

  const handleSaveClick = () => {
    handleChange(tempAttribute);
    setInEditMode(false);
  };

  useEffect(() => {
    setTempAttribute(attribute);
  }, [attribute]);

  return (
    <>
      {!inEditMode && (
        <>
          <Box sx={{ pb: '1em' }}>
            <Typography variant="h2" paddingBottom="1em" display="inline">
              <FindInPageIcon />
              {t('attributeDetails')}
            </Typography>
          </Box>
          <Box>
            <Button
              id={'deleteButton-attributeDetails'}
              onClick={handleDelete}
              disabled={!attribute}
              sx={{ float: 'right' }}
              type={'secondary'}
            >
              {t('delete')}
            </Button>
            <Button
              id={'editButton-attributeDetails'}
              onClick={handleEditClick}
              sx={{ float: 'right' }}
              type={'secondary'}
            >
              {t('edit')}
            </Button>
          </Box>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2.5}
          >
            <DataDisplay
              id="attributeNameId"
              label={t('attributeName')}
              data={tempAttribute?.attributeName || ''}
            />
            <DataDisplay
              id="attributeDisplayNameId"
              label={t('attributeDisplayName')}
              data={tempAttribute?.attributeDisplayName || ''}
            />
            <DataDisplay
              id="attributeTypeId"
              label={t('attributeType')}
              data={tempAttribute?.attributeType || ''}
            />
            <DataDisplay
              id="dataSourceId"
              label={t('attributeDatasource')}
              data={tempAttribute?.dataSource || ''}
            />
          </Stack>
        </>
      )}
      {inEditMode && (
        <>
          <Box sx={{ pb: '1em' }}>
            <Typography variant="h2" paddingBottom="1em" display="inline">
              <FindInPageIcon />
              {t('attributeDetails')}
            </Typography>
          </Box>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1.5}
          >
            <Input
              required
              id="attributeNameId"
              onChange={(e) => {
                setTempAttribute({
                  ...tempAttribute,
                  attributeName: e.target.value,
                });
              }}
              label={t('attributeName')}
              value={tempAttribute?.attributeName || ''}
              sx={{ minWidth: '200px' }}
            />
            <Input
              required
              id="attributeDisplayNameId"
              onChange={(e) => {
                setTempAttribute({
                  ...tempAttribute,
                  attributeDisplayName: e.target.value,
                });
              }}
              label={t('attributeDisplayName')}
              value={tempAttribute?.attributeDisplayName || ''}
              sx={{ minWidth: '200px' }}
            />
            <Select
              required
              id="attributeTypeId"
              label={t('attributeType')}
              options={mockAttrType}
              value={tempAttribute?.attributeType || ''}
              onChange={handleAttrType}
              sx={{ minWidth: '200px' }}
            />
            <Input
              required
              id="dataSourceId"
              onChange={(e) => {
                setTempAttribute({
                  ...tempAttribute,
                  dataSource: e.target.value,
                });
              }}
              disabled={
                tempAttribute.attributeType?.toLowerCase() != 'referencedata'
              }
              label={t('attributeDatasource')}
              value={tempAttribute?.dataSource || ''}
              sx={{ minWidth: '200px' }}
            />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button
              id={'saveAttribute-btn'}
              onClick={handleSaveClick}
              disabled={
                !tempAttribute.attributeDisplayName ||
                !tempAttribute.attributeName ||
                !tempAttribute.attributeType
              }
            >
              {t('update')}
            </Button>
            <Button id={'cancelAttribute-btn'} onClick={handleCancelClick}>
              {t('cancel')}
            </Button>
          </Stack>
        </>
      )}
    </>
  );
}
export default AttributeDetails;
