import React from 'react';

import { useMsal } from '@azure/msal-react';
import MenuItem from '@mui/material/MenuItem';
import MenuBar from '@revenue-solutions-inc/revxcoreui/material/layout/MenuBar';
import Favorites from '@revenue-solutions-inc/revxcoreui/material/layout/MenuBar/Favorites';
import HeaderLogo from '@revenue-solutions-inc/revxcoreui/material/layout/MenuBar/HeaderLogo';
import QuestionMark from '@revenue-solutions-inc/revxcoreui/material/layout/MenuBar/QuestionMark';
import UserProfile from '@revenue-solutions-inc/revxcoreui/material/layout/MenuBar/UserProfile';
import SingleSearchWrapper from 'components/SingleSearchWrapper';
import { useLocation } from 'react-router-dom';

const menuItemStyle = {
  backgroundColor: 'primary.main',
  color: 'common.white',
  fontFamily: 'fontFamily',
  fontSize: '12px',
  ':hover': { backgroundColor: 'cardRed.main', color: 'common.white' },
};

function AppMenuBar(): JSX.Element {
  const { accounts, instance } = useMsal();
  const location = useLocation();

  const logout = () => {
    instance
      .logoutRedirect({
        account: accounts[0],
      })
      .catch((response: unknown) => {
        console.log(response);
      });
  };

  // TODO: add onclick event when links need to add
  const questionMarkItems = (
    <>
      <MenuItem sx={menuItemStyle}>Getting started</MenuItem>
      <MenuItem sx={menuItemStyle}>Documentation</MenuItem>
      <MenuItem sx={menuItemStyle}>Training</MenuItem>
      <MenuItem sx={menuItemStyle}>Feedback</MenuItem>
    </>
  );

  // TODO: add onclick event when actual links need to add
  const userProfileItems = (
    <>
      <MenuItem sx={menuItemStyle}>Profile</MenuItem>
      <MenuItem sx={menuItemStyle}>Favorites</MenuItem>
      <MenuItem sx={menuItemStyle} onClick={logout}>
        Sign Out
      </MenuItem>
    </>
  );

  return (
    <MenuBar
      headerLogo={<HeaderLogo />}
      favorites={
        <Favorites
          handleClick={() => {
            // eslint-disable-next-line no-console
            console.log(location);
          }}
        />
      }
      userProfile={
        <UserProfile
          userName={
            accounts[0] && accounts[0].name ? accounts[0].name : 'No Name'
          }
          dropDownItems={userProfileItems}
        />
      }
      questionMark={<QuestionMark children={questionMarkItems} />}
      search={<SingleSearchWrapper />}
      data-testid="appmenubar-id"
    />
  );
}

export default AppMenuBar;
