import {
  ReusableContentsResponse as ReusableContent,
  TemplateResponse as Template,
} from 'types/graphTypes';

import { Address, Contact, Name } from '../types/contact';

interface GenericEntity {
  id?: string;
  addresses: Address[] | null;
  name?: Name | undefined;
  phoneNumbers?: Contact[] | null | undefined;
  emailAddresses?: Contact[] | null | undefined;
}

export const deleteContactsIfEmpty = (entity: GenericEntity): GenericEntity => {
  if (
    entity.emailAddresses &&
    entity.emailAddresses[0].type === '' &&
    entity.emailAddresses[0].value === ''
  ) {
    delete entity.emailAddresses;
  }
  if (
    entity.phoneNumbers &&
    entity.phoneNumbers[0].type === '' &&
    entity.phoneNumbers[0].value === ''
  ) {
    delete entity.phoneNumbers;
  }
  return entity;
};

export const deleteIds = (entity: GenericEntity): GenericEntity => {
  delete entity.id;
  delete entity?.name?.id;
  entity.addresses?.forEach((prop) => {
    delete prop.id;
  });
  entity.emailAddresses?.forEach((prop) => {
    delete prop.id;
  });
  entity.phoneNumbers?.forEach((prop) => {
    delete prop.id;
  });
  return entity;
};

export const validateContact = (
  entity: GenericEntity,
  validOne: boolean
): boolean => {
  let valid = validOne;
  if (entity && entity.emailAddresses) {
    const email = entity.emailAddresses[0];
    if (email.type !== '' && email.value === '') {
      valid = false;
    }
    if (email.value !== '' && email.type === '') {
      valid = false;
    }
    if (email.isPrimary === true && email.value === '' && email.type === '') {
      valid = false;
    }
  }
  if (entity && entity.phoneNumbers) {
    const phone = entity.phoneNumbers[0];
    if (phone.type !== '' && phone.value === '') {
      valid = false;
    }
    if (phone.value !== '' && phone.type === '') {
      valid = false;
    }
    if (phone.isPrimary === true && phone.value === '' && phone.type === '') {
      valid = false;
    }
  }
  return valid;
};

export const formatAddress = (address: Address) => {
  return (
    address.street +
    ', ' +
    (address.street2 ? address.street2 : '') +
    ' ' +
    address.city +
    ' ' +
    address.stateProvince +
    ' ' +
    address.postalCode
  );
};

export const filterByDateRange = (
  contents: (ReusableContent | Template)[] | undefined,
  beginDate: Date | null | undefined,
  endDate: Date | null | undefined
) => {
  let filtered: (ReusableContent | Template)[] = [];

  if (contents) {
    filtered = contents.filter((item: ReusableContent | Template) => {
      if (item.createdDate) {
        const openDate = new Date(item.createdDate);
        if (beginDate && endDate) {
          return openDate >= beginDate && openDate <= endDate;
        }
        if (beginDate && !endDate) {
          return openDate >= beginDate;
        }
        if (endDate && !beginDate) {
          return openDate <= endDate;
        }
      }
    });
  }
  return filtered;
};

export const formatPhone = (phone: string) => {
  return phone.toString().replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const formatNumber = (value: number) => {
  return new Intl.NumberFormat('en-US').format(value);
};
