import { gql } from 'graphql-request';

const policies = gql`
  query ($moduleId: String!) {
    PoliciesByModule(moduleId: $moduleId) {
      authorizationPolicyId
      policyName
      policyDescription
    }
  }
`;

const doesRoleExist = gql`
  query ($moduleId: Float!, $roleName: String!) {
    DoesRoleExist(moduleId: $moduleId, roleName: $roleName)
  }
`;

const role = gql`
  query Role($roleId: String!) {
    Role(roleId: $roleId) {
      roleId
      statusId
      moduleId
      moduleName
      roleName
      roleDescription
      authorizationPolicy {
        authorizationPolicyId
        policyName
        policyDescription
      }
    }
  }
`;

const createRole = gql`
  mutation Mutation($role: CreateRoleInput!) {
    CreateRole(role: $role) {
      name
      description
      statusId
    }
  }
`;

const updateRole = gql`
  mutation Mutation($role: UpdateRoleInput!) {
    UpdateRole(role: $role) {
      name
      description
      statusId
    }
  }
`;

const roleUsers = gql`
  query ($isAssigned: Boolean!, $userId: String!) {
    UsersByRole(isAssigned: $isAssigned, userId: $userId) {
      userId
      name
      email
    }
  }
`;

export default policies;
export { doesRoleExist, role, createRole, roleUsers, updateRole };
