import React from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  open?: boolean;
}

function Loading({ open = true }: Props): JSX.Element {
  return (
    <Backdrop open={open} data-testid="backdrop">
      <CircularProgress color="inherit" data-testid="circular-progress" />
    </Backdrop>
  );
}

export default Loading;
