import { useCallback, useMemo, useState } from 'react';

import { Box, Grid, Link, Typography, CardActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DefaultDataTable } from '@revenue-solutions-inc/revxcoreui';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import { formatNumber } from 'common/helpers';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { CommitBulkFileResponse, StatusResponseType } from 'types/roles';

const useStyles = makeStyles(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ palette: { cardHeader, medBlue, white, primary } }: any) => ({
    cardHeader: {
      backgroundColor: cardHeader.main,
      '& .MuiCardHeader-title': {
        fontSize: '0.875rem',
        fontWeight: 700,
        color: primary.main,
      },
    },
    text: {
      fontSize: '1.1em',
    },
    box: {
      margin: '0.5em',
      padding: '0 2.5em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      paddingTop: '0.5em !important',
      cursor: 'pointer',
    },
    labelBox: {
      fontWeight: 600,
      fontSize: '1.3em',
    },
    active: {
      backgroundColor: medBlue.main,
      color: white.main,
    },
    numberBox: {
      fontSize: '3em',
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    showAllText: {
      fontSize: '1.1em',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  })
);

const resultColums: Column[] = [
  {
    Header: 'Row',
    accessor: 'rowNumber',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Details',
    accessor: 'details',
  },
  {
    Header: 'Data',
    accessor: 'data',
  },
];

interface TempProps {
  rowNumber: number;
  status: string;
  details: string;
  data: string;
}

interface Props {
  comittedDataResponse: CommitBulkFileResponse[] | undefined;
  startOver: () => void;
}

export default function CommitResponse({
  comittedDataResponse,
  startOver,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.bulkUserUpload',
  });
  const [statusType, setStatusType] = useState<StatusResponseType>('Error');
  const [isShowAll, setIsShowAll] = useState(false);

  const getDataByStatus = useCallback(
    (statusParam: StatusResponseType) => {
      const temp: TempProps[] = [];

      comittedDataResponse?.forEach(
        ({
          status,
          descriptions,
          rowNumber,
          firstName,
          lastName,
          email,
          moduleName,
          roleName,
        }) => {
          if (statusParam === status) {
            const dataBuilder: string[] = [`${firstName} ${lastName}`, email];
            if (moduleName) dataBuilder.push(moduleName);
            if (roleName) dataBuilder.push(roleName);

            temp.push({
              rowNumber,
              status,
              details: descriptions.join(' - '),
              data: dataBuilder.join(' - '),
            });
          }
        }
      );

      return temp;
    },
    [comittedDataResponse]
  );

  const { successTotal, skippedTotal, errorTotal } = useMemo(() => {
    return {
      successTotal: formatNumber(getDataByStatus('Success').length),
      skippedTotal: formatNumber(getDataByStatus('Skipped').length),
      errorTotal: formatNumber(getDataByStatus('Error').length),
    };
  }, [getDataByStatus]);

  const dataSource = useMemo(() => {
    const successes: TempProps[] = [];
    const skipped: TempProps[] = [];
    const errors: TempProps[] = [];

    if (statusType === 'Success' || isShowAll) {
      getDataByStatus('Success').forEach((row) => {
        successes.push(row);
      });
    }

    if (statusType === 'Skipped' || isShowAll) {
      getDataByStatus('Skipped').forEach((row) => {
        skipped.push(row);
      });
    }

    if (statusType === 'Error' || isShowAll) {
      getDataByStatus('Error').forEach((row) => {
        errors.push(row);
      });
    }

    return [...successes, ...skipped, ...errors];
  }, [isShowAll, statusType, getDataByStatus]);

  const handleStatus = (param: StatusResponseType) => () => {
    setStatusType(param);
    setIsShowAll(false);
  };

  const boxClass = (param: StatusResponseType) => {
    const classesName = [classes.box];
    if (param === statusType && !isShowAll) {
      classesName.push(classes.active);
    }

    return classesName.join(' ');
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography color="text.primary" className={classes.text}>
          {t('uploadedFile')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2, mb: '4em' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <Box
            className={boxClass('Success')}
            onClick={handleStatus('Success')}
            data-testid="successTotal"
          >
            <Box className={classes.labelBox}>{t('boxes.successes')}</Box>
            <Box className={classes.numberBox}>{successTotal}</Box>
          </Box>
          <Box
            className={boxClass('Skipped')}
            onClick={handleStatus('Skipped')}
            data-testid="skippedTotal"
          >
            <Box className={classes.labelBox}>{t('boxes.skipped')}</Box>
            <Box className={classes.numberBox}>{skippedTotal}</Box>
          </Box>
          <Box
            className={boxClass('Error')}
            onClick={handleStatus('Error')}
            data-testid="errorTotal"
          >
            <Box className={classes.labelBox}>{t('boxes.errors')}</Box>
            <Box className={classes.numberBox}>{errorTotal}</Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <Grid sx={{ mb: '-4.2em', ml: '1em' }}>
          {!isShowAll && (
            <Link
              id="showAllResults"
              className={classes.showAllText}
              onClick={() => setIsShowAll(true)}
            >
              {t('showAllResults')}
            </Link>
          )}
        </Grid>
        <DefaultDataTable
          columns={resultColums}
          data-testid="result-data"
          data={dataSource}
        />
      </Grid>
      <CardActions sx={{ justifyContent: 'flex-end', py: 3, px: 2 }}>
        <Button id="startOver" type="ghost" onClick={startOver}>
          {t('buttons.startOver')}
        </Button>
      </CardActions>
    </>
  );
}
