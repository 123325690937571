const en = {
  pages: {
    welcome: {
      title: 'Welcome',
      message: 'Welcome to Property Premier',
    },
    dashboard: {
      title: 'Dashboard',
    },
    footer: {
      loggedIn: 'Logged in as: ',
    },
    appMenuBar: {
      favorites: 'Favorites',
      modules: 'Modules',
      searchPlaceHolder: 'Find any object using the name, ID, or any keyword',
      search: 'Search',
    },
    configuration: {
      title: 'User Config',
      lightOption: 'Light',
      darkOption: 'Dark',
      langEnglish: 'English',
      langPigLatin: 'Pig Latin',
      langEspanol: 'Español',
      language: 'Language',
    },
    manageTaxingAuthority: {
      title: 'Manage Taxing Authority',
      taxAuthorityDetails: 'Tax Authority Details',
      taxingAuthorityId: 'Taxing Authority Id',
      name: 'Name',
      taxYear: 'Tax Year',
      fiscalBeginDate: 'Fiscal Begin Date',
      fiscalEndDate: 'Fiscal End Date',
      taxRates: 'Tax Rates',
      taxRate: 'Tax Rate',
      taxType: 'Tax Type',
      effectiveBeginDate: 'Effective Begin Date',
      effectiveEndDate: 'Effective End Date',
      exemptions: 'Exemptions',
      exemption: 'Exemption',
      exemptionType: 'Exemption Type',
      amount: 'Amount',
      maxAmount: 'Max Amount',
      percentage: 'Percentage',
      totalTaxRate: 'Total Tax Rate',
      addresses: 'Addresses',
      address: 'Address',
      emails: 'Emails',
      email: 'Email',
      phoneNumbers: 'Phone Numbers',
      phone: 'Phone',
      viewTaxingAuthority: 'View Taxing Authority',
      details: 'Details',
      addExemption: 'Add Exemption',
      addTaxRate: 'Add Tax Rate',
      createTaxingAuthority: 'Create Taxing Authority',
      taxRatesExemptions: 'Tax Rates and Exemptions',
      taxPeriod: 'Tax Period',
      addYear: 'Add Year',
    },
    horizontalNonLinearStepper: {
      allStepsCompleted: 'All steps completed - you are finished',
      step: 'Step',
      alreadyCompleted: 'already completed',
      save: 'Save',
      nextStep: 'Next Step',
      prevStep: 'Previous Step',
    },
    manageRole: {
      title: 'Manage Role',
      assignedPolicies: 'Assigned Policies',
      assignedUsers: 'Assigned Users',
      createRole: 'Create Role',
      editRole: 'Edit Role',
      cloneRole: 'Clone Role',
      module: 'Platform or Module',
      navTitle: 'Manage Role',
      platformPolicies: 'Platform Policies',
      policyName: 'Policy Name',
      policyDescription: 'Policy Description',
      roleName: 'Role Name',
      roleDescription: 'Description',
      enabled: 'Unlocked',
      disabled: 'Locked',
      roleExistsMessage: 'Role name already exists',
      selected: 'Selected',
      selectedPolicies: 'Selected Policies',
      selectAll: 'Select All',
      save: 'Save',
      saveAndCreate: 'Save and Create Another',
      successMessage: 'Role saved successfully.',
      userName: 'User Name',
      userEmail: 'Email',
      userWarning:
        'Warning: There are users currently assigned to this role. Any changes to this role may affect those users.',
    },
    manageRoles: {
      title: 'Manage Roles',
      showRolesFor: 'Show roles for',
      searchRolesOrPolicies: 'search roles or policies',
      navTitle: 'Manage Roles',
      selectPlatformModule: 'Select platform/module',
      state: 'State',
      name: 'Name',
      policies: 'Policies',
      platform: 'Platform',
      module: 'Module',
      status: 'Status',
      description: 'Description',
      assignedPolicies: 'Assigned Policies',
      buttons: {
        create: 'Create new role',
      },
      allModules: 'All Modules',
    },
    viewRolesDashboard: {
      title: 'View Roles',
      navTitle: 'View Roles',
      selectPlatformModule: 'Select platform/module',
      state: 'State',
      name: 'Name',
      policies: 'Policies',
      platform: 'Platform',
      module: 'Module',
      status: 'Status',
      description: 'Description',
      assignedPolicies: 'Assigned Policies',
      showUsers: 'Show Users',
      usersByRole: 'Users By Role',
    },
    bulkUserUpload: {
      navTitle: 'Bulk Upload',
      title: 'Bulk User Upload',
      previewTitle: 'Preview',
      summaryTitle: 'Summary',
      cardTitle: 'How to upload',
      uploadFile: 'Upload',
      checkedFileText:
        'We’ve checked your file for errors and this is what we found.',
      continueText:
        'You can continue with warnings but errors need to be fixed in the Bulk User File template and uploaded again.',
      showAllResults: 'Show all results',
      commitWithErrors:
        'Users that contain warnings will be processed with incomplete data and users with errors will be skipped entirely',
      acceptFile: '.csv',
      instructions: {
        download: '1. Download the',
        bulkFile: 'Bulk User File template',
        add: '2. Add your data to the template',
        steps: {
          dontChange: "a. Don't change the order of the columns.",
          enter: 'b. Enter one row per each role to be assigned to the user.',
          moduleName: 'c. Module Name and Role Name are optional fields.',
          moduleAndRoleName:
            'd. If the Module Name and/or Role Name are populated then the Module Name and/or Role Name must exist and be active in the system.',
          roleName: 'e. Role Name must be part of the Module Name specified.',
          roleStartandEnd:
            'f. Role Start Date and Role End Date are optional. If they are filled, the Role Start Date must be between today and the Role End Date',
          formatDates:
            'g. The format for the Role Start Date and Role End Date is MM/DD/YYYY.',
          datesAreNotProvided:
            'h. If the Role Start Date and/or Role End Date is not provided, then the system will assume the current date is the start date without expiration. That is, the current date will be defaulted to the Role Start Date and the Role End Date will not be populated to indicate that there is no end date for the role.',
          datesEquals:
            'i. If the Role Start Date and Role End Date are set to the same date, then the duration of the role will be just one day.',
        },
        example: {
          title: 'Example:',
          header:
            'User First Name, User Last Name, User Email, Module Name, Role Name, Role Start Date, Role End Date',
          correct: {
            row1: 'John, Doe, jdoe@company.com, Global Roles, RSIConsultant, 07/02/2022, 08/10/2022',
            row2: 'Jane, Doe, janed@company.com, , , ,	',
          },
          incorrect:
            'Joseo, Perez, jperez@company.com, Local Module, InvalidRole, 08/10/2022, 02/01/2021',
        },
        correct: 'Correct',
        incorrect: 'Incorrect',
        assistance:
          'If you need further assistance please contact revx@rsimail.com',
        upload: '3. Upload it below for processing',
      },
      boxes: {
        valids: 'Valids',
        warnings: 'Warnings',
        successes: 'Successes',
        skipped: 'Skipped',
        errors: 'Errors',
      },
      buttons: {
        upload: 'Upload',
        startOver: 'Start over',
        applyChanges: 'Apply changes',
      },
      uploadedFile: 'Your File has been successfully uploaded',
    },
    addUser: {
      title: 'Add User into Platform',
      navTitle: 'Add User',
      emailAddress: 'Email Address',
      requiredField: 'Requiered Field',
      firstName: 'First Name',
      lastName: 'Last Name',
      addUserButton: 'Add User',
      userAdded: 'User was added successfully',
      buttons: {
        assignRole: 'Assign Role',
        createAnotherNewUser: 'Create Another New User',
        cancel: 'Cancel',
      },
      errors: {
        email: 'Invalid Email Address',
        existingUser: 'User Already Exist',
        emailLength: 'The email length is greater than 255 characters',
        fullNameLength:
          'The First Name and Last Name is greater than 150 characters',
        emailRequired: 'Please enter valid email',
        firstNameRequired: 'Please enter valid First Name',
        lastNameRequired: 'Please enter valid Last Name',
      },
    },
    tenantConfig: {
      title: 'New Tenant Creation',
      navTitle: 'Create Tenant',
      description:
        'Use this checklist to create a new tenant in this platform.',
      buttons: {
        draft: 'Save As Draft',
        saveAndCreate: 'Save And Create Tenant',
        create: 'Create New Tenant',
        next: 'Next',
      },
      tenantInfo: {
        title: 'Customer & Tenancy Information',
        description:
          'Enter tenant, customer names and, at least, one primary contact.',
        primaryTitle: 'Primary Contact Information',
        secondaryTitle: 'Secondary Contact Information',
        tenantName: 'Tenant Name',
        domainName: 'Domain Name',
        customerName: 'Customer Name',
        contacts: 'Contacts',

        primaryContact: {
          name: 'Name',
          phone: 'Phone',
          email: 'Email',
        },
        secondaryContact: {
          name: 'Name',
          phone: 'Phone',
          email: 'Email',
        },
      },
      rsiContacts: {
        title: 'RSI Contact(s)',
        description:
          'There must be at least one primary contact added. Any additional people added will be secondary contacts.',
        availableContacts: 'Available Contacts',
        type: 'Type',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        organization: 'Organization',
      },
      tenancyConfiguration: {
        title: 'Tenancy Configuration',
        locale: 'Locale',
        language: 'Language',
        timeZone: 'Time Zone',
        currency: 'Currency',
        internalUrl: 'Internal Portal URL',
        externalUrl: 'External Portal URL',
      },
      tenantIdp: {
        title: 'Tenant Identity Provider',
        identityProvider: 'Identity Provider',
        mgtAdminName: 'Management Admin Name',
        mgtAdminEmail: 'Management Admin Email',
      },
      modulesAndTaxTypes: {
        title: 'Modules and Tax Types',
      },
      errors: {
        phone: 'Invalid Phone Number',
        email: 'Invalid Email Address',
        mgtAdminEmail: 'Invalid Admin Email',
        tenantName: 'Tenant name is already in use',
      },
    },
    userRolesList: {
      title: 'Manage User Roles',
      navTitle: 'Manage Roles',
      users: 'Users',
      name: 'Name',
      email: 'Email',
      roles: 'Roles',
      edit: 'Edit',
      searchBy: 'search by user name or email..',
      roleName: 'Role',
      moduleName: 'Module',
      startDate: 'Start Date',
      endDate: 'End Date',
      noDateSpecified: 'No date specified',
    },
    tenantList: {
      title: 'Tenant Onboarding',
      navTitle: 'Tenant List',
      status: 'Status',
      tenantName: 'Tenant Name',
      serviceAdmin: 'Service Administrator',
      tenantContact: 'Tenant Contact',
      modules: 'Modules',
      messages: 'Messages',
      creationDate: 'Creation Date',
      lastModifiedDate: 'Last Modified Date',
      lastModifiedBy: 'Last Modified By',
      searchPlaceholder: 'search tenants',
      statusMessage: 'Status Message',
    },
    tenantView: {
      navTitle: 'View Tenant',
      tenant: 'Tenant',
      status: 'Status',
      editButton: 'Edit',
      tenantContacts: 'Tenant Contacts',
      rsiContacts: 'RSI Contacts',
      tenancyConfiguration: 'Tenancy Configuration',
      tenantIdP: 'Tenant Identity Provider',
      modulesTax: 'Modules and Tax Types',
      messageHistory: 'Message History',
      name: 'Name',
      userOrganization: 'Organization',
      phone: 'Phone',
      email: 'Email',
      locale: 'Locale',
      language: 'Language',
      timeZone: 'Time Zone',
      currency: 'Currency',
      portalURL: 'Portal URL',
      tenantIdentityProvider: 'Tenant Identity Provider',
      adminName: 'Management Admin Name',
      adminEmail: 'Management Admin Email',
      dateSent: 'Date Sent',
      resend: 'Resend',
      primaryContact: 'Primary Contact Information',
      secondaryContact: 'Secondary Contact Information',
      thirdContact: '3rd Party Contact Information',
    },
    fiduciary: {
      title: 'Manage Fiduciary',
      fiduciaryId: 'Fiduciary ID',
      fiduciaryIdExists: 'Fiduciary ID already exists.',
      serviceCompanyId: 'Service Company ID',
      serviceCompanyIdExists: 'Service Company ID already exists.',
      correspondenceType: 'Correspondence Type',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
    },
    agent: {
      title: 'Manage Agent',
      agentId: 'Agent ID',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
      responsibilities: 'Responsibilities',
      taxCorrespondence: 'Tax Correspondence',
      taxPayments: 'Tax Payments',
      preparer: 'Preparer',
      whPayrollProvider: 'WH Payroll Provider',
      estate: 'Estate',
      administrator: 'Administrator',
      executor: 'Executor',
      fiduciary: 'Fiduciary',
    },
    manageChannel: {
      title: 'Manage Channels',
      newChannel: 'New Channel Creation',
      name: 'Channel Name',
      fileGroupDetails: 'File Group Details',
      fileDetails: 'File Details',
      sourceSchemaDef: 'Source Schema Definition',
      fileGroupName: 'File group name',
      fileGroupType: 'File group type',
      channelNameExists: 'Channel Name Already Exists',
      platform: 'Platform/Module',
      fileProcessingType: 'File processing type',
      fileProcessingOrder: 'File Processing Order',
      fileName: 'File Name',
      addFile: 'Add New File',
      fileMetaData: 'File Meta Data',
      connector: 'Connector',
      test: 'Test',
      endDateCheck: 'End date cannot be less than start date',
      isCompressed: 'Is Compressed',
      isEncrypted: 'Is Encrypted',
      parameters: 'Parameters',
      clone: 'Clone',
      delete: 'Delete',
      channelScheduleName: 'Channel Schedule Name',
      runDays: 'Run on these days: ',
      monthDays: 'Select day(s) of the month to execute',
      scheduleTime: 'Schedule execution time',
      endDate: 'Specify an end date',
      newChannelSave: 'Create New Channel',
      executeTimes: 'Execute at these times:',
      hour: 'Hour',
      hourMask: 'Hour values not in range 0-23',
      minuteMask: 'Minute values not in range 0-59',
      minuteRange: 'Type Minute, interval range 1 - 720000 inclusive',
      hourRange: 'Type Hour, interval range 1 - 12000 inclusive',
      dayRange: 'Type Day, interval range 1 - 500 inclusive',
      weekRange: 'Type Week, interval range 1 - 71 inclusive',
      monthRange: 'Type Month, interval range 1 - 16 inclusive',
      minutes: 'Minutes',
      createChannel: 'Create Channel',
      startTime: 'Start Time',
      endTime: 'End Time',
      frequency: 'Frequency',
      every: 'Every',
      cloneChannel: 'Clone Channel',
      channelNameMask:
        'Channel name should only contain letters, numbers, dashes (-), underscores (_), or spaces',
      channelScheduleNameMask:
        'Channel Schedule name should only contain letters, numbers, dashes (-), underscores (_), or spaces',
      editChannel: 'Edit Channel',
      intervalMask: 'Interval must be a positive number between 1 and 15',
      channelCreated: 'Channel Created',
      channelDetails: 'Channel Details',
      components: 'Components',
      scheduleCreation: 'Schedule Creation',
      backChannel: 'search results',
      created: 'Creation Date',
      fileType: 'File Type',
      schedule: 'Schedule',
      lastRunStatus: 'Last Run Status',
      source: 'Source',
      sortChannels: 'Sort channels by:',
      destination: 'Destination',
      state: 'State',
      action: 'Action',
      buttons: {
        create: 'Create new channel',
      },
    },
    configRecordEditor: {
      title: 'Record Editor',
      entityType: 'Entity Type',
      idType: 'ID Type',
      configList: 'Configuration List',
      configName: 'Configuration Name',
      configDesc: 'Configuration Description',
      configRecords: 'Configuration Records',
      confirmButton: 'Confirm',
      confirmButtonMsg: 'Remove Row?',
    },
    configTypeEditor: {
      title: 'Type Editor',
      configTypes: 'Configuration Types',
      attributes: 'Attributes',
      attributeName: 'Attribute Name',
      attributeType: 'Attribute Type',
      attributeDatasource: 'DataSource',
      attributeDisplayName: 'Attribute Display Name',
      attributeDetails: 'Attribute Details',
      delete: 'Delete',
      cancel: 'Cancel',
      edit: 'Edit',
      add: '+ Add',
      save: 'Save',
      update: 'Update',
      configType: 'Configuration Type',
      configModule: 'Configuration Module',
      configDomain: 'Configuration Domain',
      confirmButton: 'Confirm',
      confirmButtonMsg: 'Delete Configuration Type?',
      configDetails: 'Configuration Details',
      howToUse: 'How to use the configuration Type editor',
      instructions:
        'Please select a Configuration Type to get started, or click the Add button to create a new configuration. Once you have selected an existing configuration type, you may add, remove, or edit its attributes by select from the list. You can also view the configuration type details. After adding, removing, or updating attributes, click the Save button to save your changes to the configuration type.',
    },
    configGroupEditor: {
      title: 'Group Editor',
      configTypes: 'Configuration Groups',
      configGroups: 'Configuration Groups',
      entityType: 'Entity Type',
      idType: 'ID Type',
      configName: 'Configuration Group Name',
      configDesc: 'Configuration Group Description',
      confirmButton: 'Confirm',
      confirmButtonMsg: 'Remove Row?',
    },
    templates: {
      title: 'Manage Templates',
      id: 'Template ID',
      subject: 'Template Name',
      version: 'Version',
      inactive: 'Show Inactive Templates',
      buttons: {
        create: 'Create New Template',
      },
    },
    reusableContent: {
      title: 'Reusable Contents',
      id: 'Content ID',
      subject: 'Content Name',
      status: 'Status',
      type: 'Content Type',
      createdBy: 'Created By',
      updatedBy: 'Updated By',
      createdDate: 'Creation Date',
      updatedDate: 'Update Date',
      edit: 'Edit',
      download: 'Download XML',
      delete: 'Delete',
      buttons: {
        create: 'Create New Content',
      },
    },
    manageReusableContent: {
      title: 'Create New Content',
      editContent: 'Edit Reusable Content',
      name: 'Content Title',
      type: 'Content Type',
      status: 'Content Status',
      download: 'Download XML',
      successMessage: 'Content Saved Successfully.',
      networkError: 'Failed due to Network Error',
      history: 'Content History',
      details: 'Content Details',
      errorMessage: 'Please select XML type only',
      newVersion: 'Create New Version',
      buttons: {
        save: 'Save',
        update: 'Update',
        upload: 'Upload XML',
      },
    },

    manageBatches: {
      title: 'Manage Batches',
      navTitle: 'Manage Batches',
      status: 'Status',
      name: 'Name',
      transactions: 'Transactions',
      associatedDeposit: 'Associated Deposit',
      effectivePostingDate: 'Effective Posting Date',
      paidDate: 'Paid Date',
      branchLocation: 'Branch Location',
      message: {
        batchCreated: 'Batch Created',
        batchClosed: 'Batch Closed',
      },
    },
    createBatch: {
      createBatchTitle: 'Create Batch',
      editBatchTitle: 'Edit Batch',
      batchLabel: 'Batch Label',
      effectivePostingDate: 'Effective Posting Date',
      paidDate: 'Paid Date',
      branchLocation: 'Branch Location',
      batchType: 'Batch Type',
      saveBatch: 'Save Batch',
      buttons: {
        create: 'Create new batch',
      },
    },
    propertyEdit: {
      title: 'Manage Property',
      propertyOwnerId: 'Property Owner ID',
      propertyId: 'Property ID',
      propertyIdExists: 'Property ID already exists.',
      propertyType: 'Property Type',
      propertySubType: 'Property SubType',
      legalDescription: 'Legal Description',
      geographicalId: 'Geographical ID',
      geographicalIdExists: 'Geographical ID already exists.',
      addOwner: 'Add Owner',
      total: 'Total',
    },
    logixTestHarness: {
      navTitle: 'Logix Test Harness',
    },

    propertyView: {
      propertyId: 'Property ID',
      propertyType: 'Property Type',
      propertySubType: 'SubType',
      year: 'Year',
      liabilitySummary: 'Liability Summary',
      totalUnpaidLiability: 'Total Unpaid Liability',
      liabilityPaid: 'Liability Paid',
      acceptPayment: 'Accept Payment',
      correspondence: 'Correspondence',
      exemptions: 'Exemptions',
      codes: 'Codes',
      fiduciary: 'Fiduciary',
      fiduciaryCode: 'Fiduciary Code',
      name: 'Name',
      address: 'Address',
      courtesy: 'Courtesy Copy to Owner',
      notes: 'Notes',
      lastestNote: 'Lastest Note',
      ownerInformation: 'Owner Information',
      ownerId: 'Owner ID',
      ownerPercentage: 'Owner percentage',
      ownerDate: 'Last ownership change date',
      propertySummary: 'Property Summary',
      legalDescription: 'Legal Description',
      taxStatement: 'Lastest Tax Statement',
      taxableValues: 'Taxable Values - Certified',
      taxYear: 'Which tax year?',
      updateOwner: 'Update Owner',
      ownerHistory: 'Owner History',
      ownerInformationDetails: 'Owner Information Details',
      newOwner: 'New Owner',
      updated: 'Updated',
    },
    propertyOwner: {
      title: 'Manage Property Owner',
      roles: 'Roles',
      addresses: 'Addresses',
      contacts: 'Contacts',
      customAttributes: 'Custom Attributes',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
      email: 'Email',
      phoneNumber: 'Phone Number',
      ownerId: 'Owner Id',
      editPropertyOwner: 'Edit Property Owner',
    },
    reporting: {
      title: 'Reports',
    },
    bookmarks: {
      title: 'Bookmarks',
    },
    editRole: {
      title: 'Edit Role',
      updateRole: 'Update Role',
      assignedUsers: 'Assigned Users',
      assignedPolicies: 'Assigned Policies',
      userName: 'User Name',
      email: 'Email Address',
      addUsers: 'Add Users',
      selectedForRemoval: 'selected for removal. Update role to confirm',
    },
    editUserRole: {
      title: 'Edit User Role',
      assignedRoles: 'Assigned roles',
      availableRoles: 'Available roles',
      roleName: 'Role name',
      description: 'Description',
      module: 'Module',
      startEndDate: 'Start and End Dates',
      rolesAssigned: '# of Roles Assigned',
      textAvailableRoles:
        'Select roles to add to this user from the list. You can preview your selections below before confirming assignment.',
      labelAvailableSelectRoles: 'Selected Roles to Add',
      assignSelectedRolesButton: 'Assign selected roles',
      noRolesSelected: 'No roles have been selected yet',
      userName: 'Name',
      email: 'Email',
      subTable: {
        requiredText:
          'Does this role assignment require a start and/or end date?',
        selectText:
          'Select a start and/or end date. At least one date must be selected.',
        startDate: 'Start Date',
        endDate: 'End Date',
        yes: 'Yes',
        no: 'No',
        optional: 'optional',
      },
      changesEffectiveMessage: 'Changes will be effective immediately.',
      sendEmailMessage: 'Send notification email to',
      removeRoles: 'Remove Role(s)',
      noDateSpecified: 'No date specified',
    },
    addUsers: {
      title: 'Edit Role',
      header: 'Add Users',
      subHeader: 'Select one or more users to add',
      select: 'Selected Users',
      userSelected: ' Users selected.',
      unassignedUsers: 'Unassigned Users',
      userName: 'User Name',
      email: 'Email Address',
      addUserBtn: 'Add User',
      addUsersBtn: 'Add Users',
      goBackBtn: 'Go back',
    },
    manageInterestedParty: {
      title: 'Manage Interested Party',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
      interestedPartyId: 'Interested Party ID',
      properties: 'Properties',
      interestedPartyIdExists: 'Interested Party ID already exists.',
      correspondenceType: 'Correspondence Type',
      correspondenceEnabled: 'Correspondence Enabled',
    },
    manageCourt: {
      title: 'Manage Court',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
      courtName: 'Court Name',
      courtId: 'Court ID',
      courtIdExists: 'Court ID already exists.',
    },
    manageServiceCompany: {
      title: 'Manage Service Company',
      serviceCompanyId: 'Service Company ID',
      serviceCompanyIdExists: 'Service Company ID already exists.',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
    },
    manageCollectionsAgency: {
      title: 'Manage Collections Agency',
      collectionsAgencyId: 'Collections Agency ID',
      collectionsAgencyIdExists: 'Collections Agency ID already exists.',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
    },
    manageCertifiedServiceProvider: {
      title: 'Manage Certified Service Provider',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
      certifiedServiceProviderName: 'Certified Service Provider Name',
      certifiedServiceProviderId: 'Certified Service Provider ID',
      certifiedServiceProviderIdExists:
        'Certified Service Provider ID already exists.',
    },
    search: {
      title: 'Search',
      subtitle: 'Global Search',
      searchtextfield: 'Search',
      searchdropdown: 'Search Type',
      searchBtn: 'Search',
      propertyOwnerSearch: {
        title: 'Property Owner Search',
        searchTerm: 'Owner Id/Property Id/Name',
        results: {
          title: 'Search Results',
          ownerId: 'Owner Id',
          name: 'Name',
        },
      },
      propertySearch: {
        title: 'Property Search',
        searchTerm: 'Owner Id/Property Id/Street Address',
        results: {
          title: 'Search Results',
          propertyId: 'Property Id',
          type: 'Type',
          subtype: 'Sub Type',
          legal: 'Legal Description',
        },
      },
      taxingAuthoritySearch: {
        title: 'Taxing Authority Search',
        searchTerm: 'Owner Id/Property Id/Name',
        results: {
          title: 'Search Results',
          taxingAuthorityId: 'Taxing Authority Id',
          name: 'Name',
        },
      },
      fiduciarySearch: {
        title: 'Fiduciary Search',
        searchTerm: 'Fiduciary Id/Name',
        results: {
          title: 'Search Results',
          fiduciaryId: 'Fiduciary Id',
          name: 'Name',
        },
      },
      interestedPartySearch: {
        title: 'Interested Party Search',
        searchTerm: 'Id/Name',
        results: {
          title: 'Search Results',
          interestedPartyId: 'Id',
          name: 'Name',
        },
      },
      serviceCompanySearch: {
        title: 'Service Company Search',
        searchTerm: 'Id/Name',
        results: {
          title: 'Search Results',
          serviceCompanyId: 'Id',
          name: 'Name',
        },
      },
      courtSearch: {
        title: 'Court Search',
        searchTerm: 'Court Id/Name',
        results: {
          title: 'Search Results',
          courtId: 'Id',
          name: 'Name',
        },
      },
      collectionsAgencySearch: {
        title: 'Collections Agency Search',
        searchTerm: 'Id/Name',
        results: {
          title: 'Search Results',
          collectionsAgencyId: 'Id',
          name: 'Name',
        },
      },
      agentSearch: {
        title: 'Agent Search',
        searchTerm: 'Id/Name',
        results: {
          title: 'Search Results',
          agentId: 'Id',
          name: 'Name',
        },
      },
      certifiedServiceProviderSearch: {
        title: 'Certified Service Provider Search',
        searchTerm: 'Certified Service Provider Id',
        results: {
          title: 'Search Results',
          certifiedServiceProviderId: 'Certified Service Provider Id',
          name: 'Name',
        },
      },
    },
    manageFormsProcessing: {
      navTitle: 'Form Processing',
      latestStatusSummary: 'Latest Status',
      incorrectFileUploaded: ' incorrect file format uploaded',
      startSummary: 'Start',
      latestStatus: {
        new: 'New',
        inProgress: 'In progress',
        error: 'Error',
      },
      start: {
        createYourFormTitle: 'Create your own form group',
        createYourFileTitle: 'Create your own file group',
        createYourFormSubtitle: 'Start to build your form group',
        createYourFileSubtitle: 'Start to build your file group',
        uploadFormTitle:
          'Upload a PDF, XSD, CSV file (will create a new file group)',
        uploadFormSubtitle: 'Click here to upload a form',
        browse: 'browse',
        searchTemplateTitle: 'Search an existing form template',
        searchForm: {
          formCategory: 'Form category',
          formYear: 'Form year',
          formName: 'Form name',
        },
        addNewForm: 'Add new form',
        clone: 'Clone',
      },
      name: 'Name',
      actions: 'Actions',
    },
  },
  components: {
    nav: {
      account: 'My Account',
      signout: 'Sign out',
    },
    address: {
      type: 'Type',
      address: 'Address',
      address1: 'Address One',
      address2: 'Address Two',
      city: 'City',
      state: 'State',
      zip: 'Zip',
      country: 'Country',
    },
    actions: {
      title: 'Actions',
      entity: 'Entity',
      welcome: 'Welcome',
      edit: 'Edit',
      clone: 'Clone',
      copy: 'Copy',
      remove: 'Remove',
    },
    button: {
      save: 'Save',
      add: 'Add',
      addAddress: 'Add Address',
      addOwner: 'Add Owner',
      cancel: 'Cancel',
      search: 'Search',
      addContact: 'Add Contact',
      delete: 'Delete',
    },
    name: {
      name: 'Name',
      given: 'First',
      middle: 'Middle',
      family: 'Last',
      value: 'Name',
    },
    contact: {
      primary: 'Primary',
      type: 'Type',
      email: 'Email',
      phone: 'Phone',
    },
    message: {
      success: 'Success',
      warning: 'Warning',
      info: 'Info',
      networkerror: 'Failed due to Network Error',
      error: 'Error',
      typeError: 'File type is wrong, the valid format text/csv',
    },
  },
};

export default en;
