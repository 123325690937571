import { useEffect } from 'react';

import { updatePageTitle } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';

function useUpdateTitle(title: string): void {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(updatePageTitle(title));
  }, [dispatch, title]);
}

export default useUpdateTitle;
