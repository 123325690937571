import { useCallback, useEffect, useState } from 'react';

import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import useUniqueTenantName from 'hooks/useUniqueTenantName';
import { useTranslation } from 'react-i18next';
import { Contact, contactDefault, TenantInfo } from 'types/tenants';

import RsiContactManager from '../RsiContactManager';

interface TenantInfoProps {
  tenantName: string;
  tenantInfo: TenantInfo;
  reset: boolean;
  showTenantNameError: boolean;
  validTenantName: boolean;
  setReset: (reset: boolean) => void;
  handleChange: (tenantInfo: TenantInfo, tenantName: string) => void;
  handleValidation: (isValid: boolean) => void;
  setShowTenantNameError: (showTenantNameError: boolean) => void;
  setValidTenantName: (validTenantName: boolean) => void;
}

function TenantInformation({
  tenantName,
  tenantInfo,
  reset,
  showTenantNameError,
  validTenantName,
  setReset,
  handleChange,
  handleValidation,
  setShowTenantNameError,
  setValidTenantName,
}: TenantInfoProps): JSX.Element {
  const { t } = useTranslation();
  const [contactsHaveErrors, setContactError] = useState<boolean>(false);
  const { refetch, isError, isRefetching } = useUniqueTenantName(tenantName);

  useEffect(() => {
    setShowTenantNameError(false);
    setValidTenantName(false);
  }, [tenantName, setShowTenantNameError, setValidTenantName]);

  const searchTenantName = useCallback(() => {
    if (!!tenantName) refetch();
  }, [tenantName, refetch]);

  useEffect(() => {
    if (!isRefetching && !isError) {
      setValidTenantName(true);
    }
    if (!isRefetching && isError) setShowTenantNameError(true);
  }, [isRefetching, isError, setShowTenantNameError, setValidTenantName]);

  const validateNameAndContacts = useCallback(
    (contacts: Contact[]) => {
      if (
        contacts &&
        !contactsHaveErrors &&
        tenantInfo.customerName[0] &&
        tenantName
      ) {
        const requiredFields = contacts.filter(
          ({
            userContactType,
            userFullName,
            userEmail,
            userPhone,
            userOrganization,
          }) => {
            return (
              !userContactType.trim() ||
              !userFullName.trim() ||
              !userEmail.trim() ||
              !userPhone ||
              !userOrganization?.trim() ||
              !validTenantName
            );
          }
        );
        const onePrimary = contacts.filter(({ userContactType }) => {
          return userContactType === 'Primary';
        });
        return (
          requiredFields.length === 0 &&
          !!tenantInfo &&
          !!tenantName &&
          onePrimary.length === 1
        );
      }
      return false;
    },
    [tenantInfo, tenantName, contactsHaveErrors, validTenantName]
  );

  /*
   * Need to check that values are not blank strings bc currently our mask
   * does not error on blank strings.
   * We are calling this useEffect w/o a 2nd argument bc this will need to
   * run every time a contact gets updated
   */

  useEffect(() => {
    const isValid = validateNameAndContacts(tenantInfo.contacts);
    handleValidation(isValid);
  }, [tenantInfo.contacts, validateNameAndContacts, handleValidation]);

  useEffect(() => {
    if (reset) {
      handleChange(
        {
          ...tenantInfo,
          contacts: [{ ...contactDefault, userContactType: 'Primary' }],
        },
        tenantName
      );
      setReset(false);
    }
  }, [reset, tenantInfo, tenantName, handleChange, setReset]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={10} mb={0.5} data-testid="tenantName">
        <Stack direction="row" spacing={2}>
          <Input
            id="tenantName"
            label={t('pages.tenantConfig.tenantInfo.tenantName')}
            required
            value={tenantName ?? ''}
            onChange={(event) => {
              handleChange(tenantInfo, event.target.value);
            }}
            helperText={
              showTenantNameError
                ? t('pages.tenantConfig.errors.tenantName')
                : ''
            }
            onBlur={searchTenantName}
          />
          {isRefetching && (
            <Box sx={{ display: 'flex', pt: 3 }}>
              <CircularProgress size={24} />
            </Box>
          )}
        </Stack>
      </Grid>
      <Grid item xs={10} mb={0.5} data-testid="customerName">
        <Input
          id="customerName"
          label={t('pages.tenantConfig.tenantInfo.customerName')}
          required
          value={tenantInfo.customerName ?? ''}
          onChange={(event) => {
            handleChange(
              { ...tenantInfo, customerName: event.target.value },
              tenantName
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">
          {t('pages.tenantConfig.tenantInfo.contacts')}
        </Typography>
      </Grid>

      <Grid item xs={10}>
        <RsiContactManager
          isFullName={true}
          contacts={tenantInfo.contacts}
          onChange={(contacts: Contact[]) => {
            handleChange({ ...tenantInfo, contacts: contacts }, tenantName);
          }}
          showOrganization={true}
          showThirdPartyType={true}
          isReadOnly={false}
          allowFirstRowRemoval={false}
          phoneRequired={true}
          onError={(hasError: boolean) => {
            setContactError(hasError);
          }}
        />
      </Grid>
      <Grid item xs={10}>
        <Button
          id="addContacts"
          variant="outlined"
          type="secondary"
          onClick={() => {
            handleChange(
              {
                ...tenantInfo,
                contacts: [...tenantInfo.contacts, { ...contactDefault }],
              },
              tenantName
            );
          }}
        >
          {t('components.button.addContact')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default TenantInformation;
