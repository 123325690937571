import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import { RootState } from 'redux/store';

export interface Message {
  message: string;
  isDismissable?: boolean;
}

export interface MessageState {
  message: string | undefined;
  type: MessageType | undefined;
  actionType: MessageActionType;
  actionValue: string | undefined;
}

export interface MessagePayload {
  message: string;
  type: MessageType;
  actionType?: MessageActionType;
}

const initialState: MessageState = {
  message: undefined,
  type: undefined,
  actionType: MessageActionType.None,
  actionValue: undefined,
};

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addMessage: (state, action: PayloadAction<MessagePayload>) => {
      state.message = action.payload.message;
      state.actionType = action.payload.actionType
        ? action.payload.actionType
        : MessageActionType.None;
      state.type = action.payload.type;
    },
    clearMessage: (state) => {
      state.message = undefined;
      state.actionType = MessageActionType.None;
      state.type = undefined;
    },
    clearActionValue: (state) => {
      state.actionValue = undefined;
    },
    setAction: (state, action: PayloadAction<string>) => {
      state.actionValue = action.payload;
    },
  },
});

export const messages = (state: RootState): MessageState => {
  return state.message;
};

export const { clearMessage, addMessage, setAction, clearActionValue } =
  messageSlice.actions;

export default messageSlice.reducer;
