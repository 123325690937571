import { gql } from 'graphql-request';

export const addConfigurationGroupQuery = gql`
  mutation Mutation($newConfigurationGroup: ConfigurationGroupInput!) {
    createConfigurationGroup(newConfigurationGroup: $newConfigurationGroup) {
      platformConfigurationGroupId
      name
      description
    }
  }
`;
