import React, { useEffect, useState } from 'react';

import { Box, FormLabel, Grid, Theme } from '@mui/material';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import { useTranslation } from 'react-i18next';
import { ConnectorInfo, ParameterInfo, ParametersInput } from 'types/channels';

interface Props {
  connectorData: ConnectorInfo[];
  connectorClicked: string;
  setParameters: React.Dispatch<React.SetStateAction<ParametersInput>>;
  parameters: ParametersInput;
  setParametersValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const labelStyle = (theme: Theme) => {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey1.main,
    borderBottom: 'none',
    '& .MuiFormLabel-asterisk': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};

function ConnectorParameters({
  connectorData,
  connectorClicked,
  setParameters,
  parameters,
  setParametersValid,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [isInitialRender, setIsInitialRender] = useState('');
  const connectorNames: string[] = connectorData
    ? connectorData.map((connector: ConnectorInfo) => {
        return connector.name;
      })
    : [];

  const indexValue = connectorNames.indexOf(connectorClicked);

  const parameterValues = connectorData
    ? connectorData.map((object: ConnectorInfo) => {
        return object.parameters;
      })
    : [];
  const parameterValue = parameterValues[indexValue];

  const parameterValueLength = parameterValue ? parameterValue.length : 0;
  const parametersObjectLength = parameters
    ? Object.keys(parameters).length
    : 0;

  useEffect(() => {
    if (
      (isInitialRender !== connectorClicked &&
        Object.keys(parameters).length === 0) ||
      parameterValueLength !== parametersObjectLength
    ) {
      setParameters({});
      if (parameterValue) {
        for (const item of parameterValue) {
          setParameters((prev) => ({
            ...prev,
            [item.name]: '',
          }));
        }
      }

      setIsInitialRender(connectorClicked);
    }
  }, [
    connectorClicked,
    isInitialRender,
    parameterValue,
    parameters,
    setParameters,
    parameterValueLength,
    parametersObjectLength,
  ]);

  const validateRequiredFields = () => {
    let isValid = true;

    parameterValue?.map((value: ParameterInfo) => {
      if (value.required && parameters[value.name] === '') {
        isValid = false;
      }
    });

    setParametersValid(isValid);
  };

  useEffect(() => {
    validateRequiredFields();
  });

  return (
    <Grid item xs={10} mb={3}>
      <Box sx={{ marginBottom: '.4em' }}>
        <FormLabel sx={labelStyle} id="parameter-label">
          {t('pages.manageChannel.parameters')}
        </FormLabel>
      </Box>
      <Box
        sx={{
          minWidth: '70em',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {parameterValue ? (
          parameterValue.map((value: ParameterInfo, index) => (
            <React.Fragment key={`${index}`}>
              <Input
                required={value.required}
                id="channelParameter"
                label={value.displayName}
                value={parameters[value.name] as string}
                inputProps={{ maxLength: value.fieldLength }}
                sx={{
                  minWidth: '20em',
                  marginRight: '2em',
                  marginBottom: '1em',
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setParameters({
                    ...parameters,
                    [value.name]: event.target.value,
                  });
                }}
              />
            </React.Fragment>
          ))
        ) : (
          <></>
        )}
      </Box>
    </Grid>
  );
}
export default ConnectorParameters;
