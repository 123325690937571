// TODO: These were added manually but it may be handled by a 3rd party
const es = {
  pages: {
    welcome: {
      title: 'Bienvenido',
      message: {
        loggedIn: 'Bienvenido a Property Premier',
        loggedOut: 'Bienvenido a Property Premier! Inicia sesión por favor',
      },
    },
    home: {
      title: 'Inicio',
    },
    tenantList: {
      title: 'Lista de Inquilinos',
      navTitle: 'Lista de Inquilinos',
      searchPlaceholder: 'Buscar inquilinos',
    },
    manageRoles: {
      title: 'Administrar Roles',
      showRolesFor: 'Mostrar roles para',
      searchRolesOrPolicies: 'roles o políticas de búsqueda',
      navTitle: 'Administrar Roles',
      selectPlatformModule: 'Seleccione plataforma/module',
      policies: 'Politicas',
      platform: 'Plataforma',
      module: 'Modulo',
      name: 'Nombre',
      state: 'Estado',
      description: 'Descripción',
      assignedPolicies: 'Polítias Asignadas',
      buttons: {
        create: 'Crear rol',
      },
    },
  },
  configuration: {
    title: 'Configuracion de usuario',
    lightOption: 'Claro',
    darkOption: 'Oscuro',
    langEnglish: 'English',
    langPigLatin: 'Pig Latin',
    langEspanol: 'Español',
    language: 'Idioma',
    footer: {
      loggedIn: 'Conectado como: ',
    },
    configuration: {
      title: 'Configuración de usuario',
      lightOption: 'Claro',
      darkOption: 'Oscuro',
      langEnglish: 'English',
      langPigLatin: 'Pig Latin',
      langEspanol: 'Español',
      language: 'Idioma',
    },
    manageTaxingAuthority: {
      title: 'Administrar Autoridad Fiscal',
      taxAuthorityDetails: 'Detalles de Autoridad Fiscal',
      taxingAuthorityId: 'ID Autoridad Fiscal',
      name: 'Nombre',
      taxYear: 'Año Fiscal',
      fiscalBeginDate: 'Fecha de Inicio Fiscal',
      fiscalEndDate: 'Fecha de Finalización Fiscal',
      taxRates: 'Tasas de Impuestos',
      taxRate: 'Tasa de Impuestos',
      taxType: 'Tipo de Impuestos',
      effectiveBeginDate: 'Fecha de Inicio Efectiva',
      effectiveEndDate: 'Fecha de Finalización Efectiva',
      exemptions: 'Exenciones',
      exemption: 'Exención',
      exemptionType: 'Tipo de Exenciones',
      amount: 'Cantidad',
      maxAmount: 'Cantidad Máxima',
      percentage: 'Porcentaje',
      totalTaxRate: 'Tasa Impositiva Total',
      addresses: 'Direcciones',
      emails: 'Emails',
      phoneNumbers: 'Números de Teléfono',
    },
    tenantConfig: {
      title: 'Creación de Nuevo Inquilino',
      navTitle: 'Configuración',
      description:
        'Use esta lista para crear un nuevo inquilino en esta plataforma.',
      buttons: {
        draft: 'Guardar como Borrador',
        saveAndCreate: 'Guardar y Crear Inquilino',
        create: 'Crear nuevo inquilino',
        next: 'Siguiente',
      },
      tenantInfo: {
        title: 'Información de Inquilino',
        primaryTitle: 'Información de Contacto Primario',
        secondaryTitle: 'Información de Contacto Secundario',
        tenantName: 'Nombre de Inquilino',
        primaryContact: {
          name: 'Nombre',
          phone: 'Teléfono',
          email: 'Email',
        },
        secondaryContact: {
          name: 'Nombre',
          phone: 'Teléfono',
          email: 'Email',
        },
      },
      rsiContacts: {
        title: 'Contacto(s) de RSI',
        primaryName: 'Nombre Primario',
        primaryEmail: 'Email Primario',
        secondaryName: 'Nombre Secundario',
        secondaryEmail: 'Email Secundario',
      },
      tenancyConfiguration: {
        title: 'Configuración de Inquilino',
        locale: 'Lugar',
        language: 'Lenguaje',
        timeZone: 'Zona Horaria',
        currency: 'Divisa',
        internalUrl: 'Portal URL Interno',
        externalUrl: 'Portal URL Externo',
      },
      tenantIdp: {
        title: 'Proveedor de Identidad',
        identityProvider: 'Proveedor de Identidad Inicial',
        mgtAdminName: 'Nombre del Administrador Inicial',
        mgtAdminEmail: 'Email del Administrador Inicial',
      },
      modulesAndTaxTypes: {
        title: 'Módulos y Tipos de Impuestos',
      },
      errors: {
        phone: 'Teléfono Inválido',
        email: 'Email Inválido',
        mgtAdminEmail: 'Email del Administrador Inválido',
      },
    },
    search: {
      title: 'Buscar',
      subtitle: 'Busqueda Global',
      searchtextfield: 'Buscar',
      searchdropdown: 'Tipo de busqueda',
    },
  },
  pageTitles: {
    managePropertyOwner: 'Agregar Property Owner',
    manageProperty: 'Agregar Property',
  },
  demo: {
    title: 'Demo',
  },
  search: {
    title: 'Buscar',
    propertyOwnerSearch: 'Busqueda de Property Owner',
  },
  reporting: {
    title: 'Reportes',
  },
  bookmarks: {
    title: 'Favoritos',
  },
  components: {
    nav: {
      account: 'Mi Cuenta',
      signout: 'Cerrar Sesión',
    },
    address: {
      type: 'Tipo',
      address: 'Dirección',
      address1: 'Dirección Principal',
      address2: 'Dirección Secundaria',
      city: 'Ciudad',
      state: 'Estado',
      zip: 'Código Postal',
      country: 'País',
    },
    actions: {
      title: 'Acciones',
      entity: 'Entidades',
      welcome: 'Bienvenido',
      edit: 'Editar',
      clone: 'Clonar',
    },
    button: {
      save: 'Guardar',
      add: 'Agregar',
      addAddress: 'Agregar Domicilio',
      addOwner: 'Agregar Propietario',
      search: 'Buscar',
    },
    name: {
      first: 'Nombre',
      middle: 'Segundo Nombre',
      last: 'Apellidos',
    },
    contact: {
      primary: 'Primario',
      type: 'Tipo',
      email: 'Email',
      phone: 'Teléfono',
    },
  },
  message: {
    success: 'Satisfactorio',
    networkerror: 'Error de conexion',
  },
};

export default es;
