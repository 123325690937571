import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { Tenant } from 'types/tenants';

const fetchTenantById = async (
  token: string | undefined,
  tenantId: string
): Promise<Tenant> => {
  const result = await axios.get(
    `${process.env.REACT_APP_TENANT}/tenantquery/${tenantId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result.data;
};

export default function useTenantById(
  accessToken: string | undefined,
  tenantId: string
): UseQueryResult<Tenant, Error> {
  return useQuery<Tenant, Error>(
    ['tenantId', tenantId],
    () => {
      return fetchTenantById(accessToken, tenantId);
    },
    {
      enabled: !!accessToken && !!tenantId,
    }
  );
}
