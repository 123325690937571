import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

export interface MessageProps {
  message?: string;
  type?: 'success' | 'warning' | 'error' | 'info';
  content?: JSX.Element;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const alertSuccess = (type: string) => {
  return type === 'success'
    ? { backgroundColor: '#018029', width: '100%' }
    : { width: '100%' };
};

function Alert(props: AlertProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert variant="filled" {...props} />;
}

const MessageAlert = ({
  message = '',
  type = 'success',
  content,
  open,
  setOpen,
}: MessageProps): JSX.Element => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <div>
          <Alert
            severity={type}
            sx={alertSuccess(type)}
            action={
              <Button onClick={handleClose}>
                <CloseIcon color="secondary" fontSize="medium" />
              </Button>
            }
            iconMapping={{
              success: <CheckIcon />,
            }}
          >
            <Box
              display="flex"
              sx={{ justifyContent: 'center', alignItems: 'center' }}
            >
              {message} <Box ml={2}>{content}</Box>
            </Box>
          </Alert>
        </div>
      </Snackbar>
    </Box>
  );
};

export default MessageAlert;
