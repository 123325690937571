import { gql } from 'graphql-request';

const deleteTemplate = gql`
  mutation Mutation($template: DeleteTemplate!) {
    DeleteTemplate(template: $template)
  }
`;

const getTemplates = gql`
  query Query {
    TemplatesList {
      templateId
      fixedId
      name
      status
      version
      templateOpenXml
      createdBy
      createdDate
      updatedBy
      updatedDate
      reusableContents {
        reusableContentId
        fixedId
        title
        contentType
        status
        version
        contentOpenXml
        createdBy
        createdDate
        updatedBy
        updatedDate
      }
      roleIds
    }
  }
`;

export { deleteTemplate, getTemplates };
