import React, { useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import NavSidebar from '@revenue-solutions-inc/revxcoreui/material/layout/Sidebar';
import AuthenticatedComponent from 'components/AuthenticatedComponent';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import adminMenuConfig from './sidebarScript';

export interface SidebarProps {
  handleWidth: (arg: number) => void;
  width: number;
}

export interface ConfigItem {
  id: string;
  label: string;
  route?: string;
  items?: ConfigItem[];
}

function Sidebar({ handleWidth, width }: SidebarProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<{ [key: string]: boolean }>({
    roles: false,
    users: false,
    tenants: false,
    logix: false,
    configuration: false,
    content: false,
    channel: false,
    correspondence: false,
  });
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const newOpen = { ...open };
    Object.keys(newOpen).forEach((key) =>
      key !== event.currentTarget.id
        ? (newOpen[key] = false)
        : (newOpen[key] = !newOpen[key])
    );
    setOpen({
      ...newOpen,
    });
  };
  const menuItems = (
    <AuthenticatedComponent>
      <List
        sx={{
          '&:hover': {
            color: 'white !important',
          },
        }}
      >
        {adminMenuConfig.map((listItem) => {
          if (listItem.items && listItem.items.length) {
            return (
              <React.Fragment key={listItem.id}>
                <ListItemButton
                  sx={{
                    borderBottom: 'solid',
                    borderBottomColor: 'secondary.main',
                    borderBottomWidth: '1px',
                    '&:hover': {
                      backgroundColor: 'skyBlue.main',
                    },
                  }}
                  id={listItem.id}
                  onClick={handleClick}
                >
                  <ListItemText
                    sx={{
                      '&:hover': {
                        color: 'white !important',
                      },
                    }}
                    primary={listItem.label}
                    primaryTypographyProps={{ color: 'secondary.main' }}
                  />
                  {open[listItem.id] ? (
                    <ExpandLess color="secondary" />
                  ) : (
                    <ExpandMore color="secondary" />
                  )}
                </ListItemButton>
                <Collapse in={open[listItem.id]} timeout="auto" unmountOnExit>
                  <List
                    sx={{
                      backgroundColor: 'linkBlue1.main',
                      borderBottom: 'solid',
                      borderBottomColor: 'secondary.main',
                      borderBottomWidth: 1,
                    }}
                    component="div"
                    disablePadding
                  >
                    {listItem.items.map((subList) => {
                      return (
                        <ListItem
                          button
                          key={subList.id}
                          component={Link}
                          to={subList.route}
                          sx={{
                            '&:hover': {
                              backgroundColor: '#ff6055',
                            },
                          }}
                        >
                          <ListItemText
                            primary={t(subList.label)}
                            primaryTypographyProps={{ color: 'secondary.main' }}
                            sx={{
                              '.MuiTypography-root': {
                                marginLeft: '8px',
                              },
                            }}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          } else {
            return (
              <ListItem
                sx={{
                  borderBottom: 'solid',
                  borderBottomColor: 'secondary.main',
                  borderBottomWidth: '1px',
                  '&:hover': {
                    backgroundColor: 'skyBlue.main',
                  },
                }}
                button
                key={listItem.id}
                component={Link}
                to={listItem.route ?? ''}
              >
                <ListItemText
                  primaryTypographyProps={{
                    color: 'secondary.main',
                  }}
                  primary={t(listItem.label)}
                />
              </ListItem>
            );
          }
        })}
      </List>
    </AuthenticatedComponent>
  );

  return (
    <NavSidebar
      sideBarMarginTop={104}
      menuList={menuItems}
      handleWidth={handleWidth}
      width={width}
    />
  );
}

export default Sidebar;
