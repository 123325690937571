/* eslint-disable no-console */
import React, { useState, ChangeEvent } from 'react';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { ReactComponent as RevXPowered } from 'assets/revx-powered-by-logo.svg';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';

function Welcome(): JSX.Element {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [isDisabled, setIsDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  useUpdateTitle(t('pages.welcome.title'));

  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={10} md={6} lg={4} xl={4}>
        <Box textAlign="center" sx={{ marginTop: '7em', marginBottom: '5em' }}>
          <RevXPowered height={100} />
        </Box>
        <Card
          sx={{
            padding: '2em',
            maxWidth: 600,
            marginX: '1em',
            backgroundColor: 'primary.main',
          }}
        >
          <FormGroup sx={{ alignItems: 'center' }}>
            <FormControlLabel
              sx={{ color: 'white' }}
              control={
                <Checkbox
                  sx={{
                    color: 'paperWhite.main',
                    '&.Mui-checked': {
                      color: 'paperWhite.main',
                    },
                  }}
                  onChange={function (
                    event: ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ): void {
                    setIsDisabled(!checked);
                  }}
                />
              }
              label={
                <Box
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{ color: 'paperWhite.main' }}
                  >
                    I agree to the{' '}
                  </Typography>
                  <Link
                    color="#fa4138"
                    component="button"
                    variant="body2"
                    onClick={() => {
                      setOpen(true);
                    }}
                    sx={{ lineHeight: 1.5 }}
                  >
                    Terms of System Use
                  </Link>
                </Box>
              }
            />
          </FormGroup>

          <Collapse in={open}>
            <Box sx={{ backgroundColor: 'paperWhite.main' }}>
              <Box sx={{ textAlign: 'right' }}>
                <IconButton
                  onClick={() => {
                    setOpen(false);
                  }}
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <Close color="error" />
                </IconButton>
              </Box>
              <Typography
                variant="h2"
                sx={{
                  paddingX: '.25em',
                  backgroundColor: 'paperWhite.main',
                  display: 'inline-block',
                }}
              >
                <p>
                  Browsing is strictly prohibited! You may not access a
                  taxpayers information unless the taxpayer is assigned to you
                  or is otherwise directly related to your division of taxation
                  duties. Unauthorized inspection or disclosure of information
                  is prohibited by law.
                </p>
                <p>
                  This system contains U.S. Government information. By accessing
                  and using this computer system you are consenting to system
                  monitoring for law enforcement and other purposes.
                  Unauthorized use of, or access to, this computer system may
                  subject you to state and federal criminal prosecution and
                  penalties, as well as civil penalties.
                </p>
              </Typography>
            </Box>
          </Collapse>
          {!isAuthenticated ? (
            <Box textAlign="center" sx={{ marginTop: '1em' }}>
              <Button
                size="medium"
                disabled={isDisabled}
                sx={{ width: 120, maxWidth: 120, backgroundColor: '#fa4138' }}
                id="login"
                onClick={() => {
                  instance
                    .loginRedirect({
                      scopes: ['openid'],
                    })
                    .catch((e) => {
                      // eslint-disable-next-line no-console
                      console.log(e);
                    });
                }}
              >
                Sign In
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

export default Welcome;
