import React, { useEffect } from 'react';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';
import datefns from '@date-io/date-fns';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import appTheme, {
  darkTheme,
} from '@revenue-solutions-inc/revxcoreui/material/themes/defaultTheme';
import MessagingContext from 'components/contexts/Messaging';
import LayoutInternal from 'components/layout/LayoutInternal';
import MessageWrapper from 'components/MessageWrapper';
import Welcome from 'pages/Welcome';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { updateUser, User } from 'redux/userSlice';

function App(): JSX.Element {
  const isAuthenticated = useIsAuthenticated();
  const { i18n } = useTranslation();
  const darkMode = useAppSelector((state) => state.content.darkMode);
  const language = useAppSelector((state) => state.content.language);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const policies: User = {
      userName: 'currentUser@mail.com',
      roles: ['role1', 'role2'],
      policies: [
        'policy1',
        'policy2',
        'policy3',
        'policy4',
        'policy5',
        'policy6',
        'policy19',
      ],
    };
    // check to see if redux user info is
    if (isAuthenticated) {
      dispatch(updateUser(policies));
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
  return (
    <ThemeProvider theme={darkMode ? darkTheme : appTheme}>
      <LocalizationProvider dateAdapter={datefns}>
        <CssBaseline />
        <MessagingContext>
          <MessageWrapper />
          <AuthenticatedTemplate>
            <LayoutInternal />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Welcome />
          </UnauthenticatedTemplate>
        </MessagingContext>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
