import { useMemo } from 'react';

import { Select } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import { QueryModule } from 'types/tenants';

interface SelectRoleProps {
  moduleList: QueryModule[] | undefined;
  handleChange: (moduleId: string) => void;
  selectedRole?: string;
  showAll?: boolean;
}

function SelectRole({
  moduleList,
  handleChange,
  showAll = true,
  selectedRole = '1',
}: SelectRoleProps): JSX.Element {
  const { t } = useTranslation();
  const options = useMemo(() => {
    const list = [];

    if (showAll) {
      list.push({
        key: 'all',
        desc: `${t('pages.manageRoles.allModules')}`,
      });
    }

    if (moduleList && Array.isArray(moduleList)) {
      moduleList?.forEach(({ moduleId, name }) => {
        list.push({ key: `${moduleId}`, desc: name });
      });
    }

    return list;
  }, [showAll, moduleList, t]);

  return (
    <Select
      id="select-role"
      data-testid="select-role"
      label={t('pages.manageRoles.showRolesFor')}
      value={selectedRole}
      options={options}
      onChange={(event) => handleChange(`${event.target.value}`)}
      width="18em"
    />
  );
}

export default SelectRole;
