export interface TenantConsole {
  id: string;
  tenantDomain: string;
  tenantName: string;
  tenantContact: string;
  configurationStatus: string;
  serviceAdmin: string;
  messages: number;
  activationUrl: string;
  creationDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  modules: [TenantModule];
}

export interface TenantModule {
  moduleId: number;
  moduleName: string;
  selected: boolean;
  taxTypes: [TenantTaxType];
}

export interface TenantTaxType {
  selected: boolean;
  taxTypeId: number;
  taxTypeName: string;
  features: [string];
}

export interface Tenant {
  userNameLogin: string;
  userEmail: string;
  id: string;
  tenantDomain: string;
  tenantName: string;
  tenantB2CId?: string | null;
  configurationStatus?: ConfigurationStatus;
  rsiContactInfo: RsiContactInfo;
  contractualInfo: ContractualInfo;
  localeInfo: LocaleInfo;
  auditInfo: AuditInfo;
  securityInfo: SecurityInfo;
  tenantB2CInfo: boolean | null;
}

export interface TenantList {
  value: [Tenant];
}
export interface TenantCommand {
  id?: string;
  userNameLogin: string;
  userEmail: string;
  tenantName: string;
  securityInfo: SecurityInfo;
  rsiContactInfo: RsiContactInfo;
  contractualInfo: ContractualInfo;
  localeInfo: LocaleInfo;
}

export interface SecurityInfo {
  frontDoor: FrontDoor;
  mfaInfo: MfaInfo;
  tenantIdp: TenantIdp;
  adminUser: AdminUser;
}

export interface FrontDoor {
  domainName: string;
}

export interface ConfigurationStatus {
  name?: string;
  description?: string;
  type?: string;
}

export interface RsiContactInfo {
  rsiContacts: Contact[];
  dave?: string;
}

export interface ContractualInfo {
  modules: Modules[];
  tenantInfo: TenantInfo;
}

export interface Modules {
  moduleId: number;
  moduleName: string;
  selected: boolean;
  taxTypes: TaxTypes[] | null;
}

export interface TenantInfo {
  countryCode: string;
  contacts: Contact[];
  customerName: string;
  rsiOpenIdMetadataUrl: string;
}

export interface LocaleInfo {
  locationName: string;
  currency: string;
  languages: Language[];
  timeZones: TimeZone[];
}

export interface Language {
  languageName: string;
  selected: boolean;
}

export interface TimeZone {
  timeZoneName: string;
  selected: boolean;
}

export interface AuditInfo {
  createdInfo: CreatedInfo;
  modificationInfo: boolean | null;
}

export interface MfaInfo {
  mfaEnforcement: string;
  mfaMethodType: string;
}

export interface TenantIdp {
  managementAdminEmail: string;
  managementAdminName: string;
  openIdMetadataUrl: string;
}

export interface AdminUser {
  userFirstName: string;
  userLastName: string;
  userEmail: string;
}

export interface Contact {
  userContactType: string;
  userEmail: string;
  userFullName: string;
  userLastName?: string;
  userFirstName: string;
  userPhone?: string;
  userId?: string;
  userOrganization?: string;
}

export interface CreatedInfo {
  username: string;
  userEmail: string;
  dateTime: string;
}

export interface Module {
  moduleId: string;
  moduleName: string;
}

export interface QueryModule {
  moduleId: number;
  name: string;
}

export interface Policy {
  authorizationPolicyId: string;
  policyName: string;
  policyDescription: string;
}

const mfaInfoDefault: MfaInfo = {
  mfaEnforcement: '',
  mfaMethodType: '',
};

export const tenantIdpDefault: TenantIdp = {
  managementAdminEmail: '',
  managementAdminName: '',
  openIdMetadataUrl: '',
};

const adminUserDefault: AdminUser = {
  userFirstName: '',
  userLastName: '',
  userEmail: '',
};

const contactDefault: Contact = {
  userContactType: '',
  userEmail: '',
  userFullName: '',
  userLastName: '',
  userFirstName: '',
  userPhone: '',
  userId: '',
  userOrganization: '',
};

const createdInfoDefault: CreatedInfo = {
  username: '',
  userEmail: '',
  dateTime: '',
};

const configurationStatus: ConfigurationStatus = {
  name: '',
  description: '',
  type: '',
};

const frontDoorDefault: FrontDoor = {
  domainName: '',
};

export const securityInfoDefault: SecurityInfo = {
  frontDoor: frontDoorDefault,
  mfaInfo: mfaInfoDefault,
  tenantIdp: tenantIdpDefault,
  adminUser: adminUserDefault,
};

const rsiContactInfoDefault: RsiContactInfo = {
  rsiContacts: [],
};

const modulesDefault: Modules = {
  moduleId: 1,
  moduleName: '',
  selected: false,
  taxTypes: null,
};

const tenantInfoDefault: TenantInfo = {
  countryCode: '',
  customerName: '',
  contacts: [{ ...contactDefault, userContactType: 'Primary' }],
  rsiOpenIdMetadataUrl: '',
};

const contractualInfoDefault: ContractualInfo = {
  modules: [modulesDefault],
  tenantInfo: tenantInfoDefault,
};

export const localeInfoDefault: LocaleInfo = {
  locationName: '',
  currency: '',
  languages: [],
  timeZones: [],
};

const auditInfoDefault: AuditInfo = {
  createdInfo: createdInfoDefault,
  modificationInfo: false,
};

export interface Features {
  featureId: number;
  featureName: string;
  selected: boolean;
}

export interface TaxTypes {
  taxTypeId: number;
  taxTypeName: string;
  selected: boolean;
  features?: Features[] | null;
}

const tenantDefault: Tenant = {
  id: '',
  tenantDomain: '',
  tenantName: '',
  tenantB2CId: '',
  configurationStatus: configurationStatus,
  tenantB2CInfo: false,
  securityInfo: securityInfoDefault,
  rsiContactInfo: rsiContactInfoDefault,
  contractualInfo: contractualInfoDefault,
  localeInfo: localeInfoDefault,
  auditInfo: auditInfoDefault,
  userNameLogin: '',
  userEmail: '',
};

const tenantCommandDefault: TenantCommand = {
  id: '',
  userNameLogin: '',
  userEmail: '',
  tenantName: '',
  securityInfo: securityInfoDefault,
  rsiContactInfo: rsiContactInfoDefault,
  contractualInfo: contractualInfoDefault,
  localeInfo: localeInfoDefault,
};

export { tenantDefault, tenantCommandDefault, contactDefault };
