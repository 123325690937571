import { gql } from 'graphql-request';

export const updateConfigurationQuery = gql`
  mutation Mutation($newConfiguration: UpdateConfigurationInput!) {
    updateConfiguration(newConfiguration: $newConfiguration) {
      platformConfigurationId
      configurationName
      configurationDescription
      configurationModule
      configurationDomain
      configurationType
    }
  }
`;
