import { ConfigItem } from './Sidebar';

const adminMenuConfig: Array<ConfigItem> = [
  {
    id: 'dashboard',
    label: 'pages.dashboard.title',
    route: '/dashboard',
  },
  {
    id: 'roles',
    label: 'Roles',
    items: [
      {
        id: 'manageRoles',
        label: 'pages.manageRoles.navTitle',
        route: '/manageroles',
      },
      {
        id: 'rolesDashboard',
        label: 'pages.viewRolesDashboard.navTitle',
        route: '/viewRolesDashboard',
      },
    ],
  },
  {
    id: 'users',
    label: 'Users',
    items: [
      {
        id: 'bulkUserUpload',
        label: 'pages.bulkUserUpload.navTitle',
        route: '/bulkUserUpload',
      },
      {
        id: 'manageuserroles',
        label: 'pages.userRolesList.navTitle',
        route: '/manageuserroles',
      },
      {
        id: 'addUser',
        label: 'pages.addUser.navTitle',
        route: '/addUser',
      },
    ],
  },
  {
    id: 'tenants',
    label: 'Tenants',
    items: [
      {
        id: 'tenantConfig',
        label: 'pages.tenantConfig.navTitle',
        route: '/tenantConfig',
      },
      {
        id: 'tenantList',
        label: 'pages.tenantList.navTitle',
        route: '/tenantList',
      },
    ],
  },
  {
    id: 'channel',
    label: 'Channel',
    items: [
      {
        id: 'managechannels',
        label: 'pages.manageChannel.title',
        route: '/managechannels',
      },
    ],
  },
  {
    id: 'configuration',
    label: 'Configuration',
    items: [
      {
        id: 'configTypeEditor',
        label: 'pages.configTypeEditor.title',
        route: '/configTypeEditor',
      },
      {
        id: 'configRecordEditor',
        label: 'pages.configRecordEditor.title',
        route: '/manageConfigTool',
      },
      {
        id: 'configGroupEditor',
        label: 'pages.configGroupEditor.title',
        route: '/configGroupEditor',
      },
    ],
  },
  {
    id: 'correspondence',
    label: 'Correspondence',
    items: [
      {
        id: 'correspondenceTemplates',
        label: 'pages.templates.title',
        route: '/templates',
      },
      {
        id: 'reusableContent',
        label: 'pages.reusableContent.title',
        route: '/reusableContents',
      },
    ],
  },
  {
    id: 'logix',
    label: 'Logix',
    items: [
      {
        id: 'manageFormsProcessing',
        label: 'pages.manageFormsProcessing.navTitle',
        route: '/manageFormsProcessing',
      },
      {
        id: 'logixtestharness',
        label: 'pages.logixTestHarness.navTitle',
        route: '/logixtestharness',
      },
    ],
  },
];

export default adminMenuConfig;
