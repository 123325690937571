import React, { useEffect } from 'react';

import { Card, Grid } from '@mui/material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTable from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DefaultDataTable';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/TableCells/DateCell';
import StatusCell from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/TableCells/StatusCell';
import Loading from 'components/Loading';
import useTenantConsole from 'hooks/useTenantConsole';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Cell, Row, Column } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { Tenant } from 'types/tenants';

interface ModuleRow {
  value: [Module];
  row: Row;
}

interface Module {
  moduleName: string;
}

function TenantList(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useUpdateTitle(t('pages.tenantList.title'));

  // This filter overrides the default datagrid search filter
  // in order to allow filtering by a module name, since the module column's underlying value is an array of modules
  // we have to explicitly handle search behaviour for that specific column, and implement regular filtering for the other columns
  const customFilter = (
    rows: Array<Row>,
    columnIds: Array<string>,
    filterValue: string
  ) => {
    return rows.filter((row) => {
      let include = false;
      const searchValue = filterValue.toLowerCase();
      columnIds.forEach((columnId: string) => {
        if (
          row.values[columnId] &&
          row.values[columnId].toString().toLowerCase().includes(searchValue)
        ) {
          include = true;
          return;
        }

        if (include === false && columnId === 'modules') {
          include = row.values[columnId].some((m: { moduleName: string }) =>
            m.moduleName.toLowerCase().includes(searchValue)
          );
        }
      });
      return include;
    });
  };

  const COLUMNS = [
    {
      Header: t('status'),
      accessor: 'configurationStatus.type',
      Cell: ({ value }: Cell) => {
        return <StatusCell status={value} />;
      },
    },
    {
      Header: t('statusMessage'),
      accessor: 'configurationStatus.description',
    },
    {
      Header: t('tenantName'),
      accessor: 'tenantName',
      Cell: ({ row }: Cell<Tenant>) => (
        <Link to={{ pathname: `/tenant/view/${row.original.id}` }}>
          {row.original.tenantName}
        </Link>
      ),
    },
    {
      Header: t('tenantContact'),
      accessor: 'tenantContact',
      disableSortBy: true,
    },
    {
      Header: t('serviceAdmin'),
      accessor: 'serviceAdmin',
      disableSortBy: true,
    },
    {
      Header: t('modules'),
      accessor: 'modules',
      Cell: ({ value }: ModuleRow) => {
        return value.map(
          ({ moduleName }, index) => (index ? ', ' : '') + moduleName
        );
      },
      sortType: (rowA: Row, rowB: Row, columnId: string) => {
        if (rowB.values[columnId].length === 0) return 1;
        if (
          rowA.values[columnId][0]?.moduleName >
          rowB.values[columnId][0]?.moduleName
        )
          return 1;
        else return -1;
      },
    },
    {
      //TODO: update with email modal
      Header: t('messages'),
      accessor: 'messages',
      disableSortBy: true,
    },
    {
      Header: t('creationDate'),
      accessor: 'creationDate',
      disableSortBy: true,
      Cell: ({ value }: Cell) => {
        return <DateCell dateString={value} />;
      },
    },
    {
      Header: t('lastModifiedDate'),
      accessor: 'lastModifiedDate',
      disableSortBy: true,
      Cell: ({ value }: Cell) => {
        return <DateCell dateString={value} />;
      },
    },
    {
      Header: t('lastModifiedBy'),
      accessor: 'lastModifiedBy',
      disableSortBy: true,
    },
  ];
  const { data, isFetching, error } = useTenantConsole();

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          // actionType: MessageActionType.None,
        })
      );
    }
  }, [error, dispatch, t]);

  //TODO: add error handling
  return (
    <>
      <Card
        sx={{
          borderRadius: '5px',
          padding: '0px',
        }}
      >
        {isFetching && <Loading />}
        {data && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DefaultDataTable
                columns={COLUMNS as Column<object>[]}
                data={data || []}
                customFilter={customFilter}
              />
            </Grid>
          </Grid>
        )}
      </Card>
    </>
  );
}

export default TenantList;
