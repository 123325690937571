import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import placeholder from 'assets/revx-placeholder.png';
import useUpdateTitle from 'hooks/useUpdatetTitle';

function UnderConstruction(): JSX.Element {
  useUpdateTitle('Dashboard');
  return (
    <Grid container spacing={0} direction="column" alignItems="center">
      <Grid item xs={3}>
        <Box
          component="img"
          sx={{
            width: '100%',
          }}
          alt="Nothing to see here. Check back later"
          src={placeholder}
        />
      </Grid>
    </Grid>
  );
}

export default UnderConstruction;

/*
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
*/
