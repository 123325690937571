import React, {
  useState,
  useEffect,
  ChangeEvent,
  useMemo,
  useCallback,
} from 'react';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Grid, IconButton, SelectChangeEvent } from '@mui/material';
import { Input, SelectTypeProps } from '@revenue-solutions-inc/revxcoreui';
import { Select } from '@revenue-solutions-inc/revxcoreui';
import { emailExp, phoneExp } from 'common/regexp';
import useMultiMaskInput, { MaskReturn } from 'hooks/useMultiMaskInput';
import { useTranslation } from 'react-i18next';
import { Contact } from 'types/tenants';

interface Props {
  isFullName?: true;
  contacts: Contact[];
  onChange: (contacts: Contact[]) => void;
  phoneRequired: boolean;
  isReadOnly: boolean;
  showThirdPartyType: boolean;
  showOrganization: boolean;
  allowFirstRowRemoval: boolean;
  onError: (hasError: boolean) => void;
}

function RsiContactsManager({
  isFullName,
  contacts,
  onChange,
  showOrganization,
  showThirdPartyType,
  isReadOnly,
  allowFirstRowRemoval,
  phoneRequired,
  onError,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [email, setEmail] = useState<(string | null)[]>(['']);
  const [phone, setPhone] = useState<(string | null)[]>(['']);

  const selectContactTypes: SelectTypeProps[] = useMemo(() => {
    const selectContacts = [
      { key: 'Primary', desc: 'Primary' },
      { key: 'Secondary', desc: 'Secondary' },
    ];

    if (showThirdPartyType)
      selectContacts.push({ key: 'ThirdParty', desc: '3rd Party Contact' });

    return selectContacts;
  }, [showThirdPartyType]);

  const emailMask = useMultiMaskInput(
    '',
    t('pages.tenantConfig.errors.email'),
    emailExp,
    email
  );

  const phoneMask = useMultiMaskInput(
    '999-999-9999',
    t('pages.tenantConfig.errors.phone'),
    phoneExp,
    phone
  );

  const handleListContactTypes = useCallback(
    (index: number) => {
      if (showThirdPartyType && index !== 0) {
        const listContactTypes = [
          ...selectContactTypes.filter(({ key }) => key !== 'Primary'),
        ];
        return listContactTypes;
      }

      return selectContactTypes;
    },
    [selectContactTypes, showThirdPartyType]
  );

  useEffect(() => {
    onError(emailMask.hasErrors || phoneMask.hasErrors || contacts === []);
  }, [
    phoneMask,
    phoneMask.hasErrors,
    emailMask,
    emailMask.hasErrors,
    contacts,
    onError,
  ]);

  const handleHelperTextChange = (mask: MaskReturn, index: number) => {
    return mask.maskErrors[index];
  };

  useEffect(() => {
    const phoneValues: (string | null)[] = [];
    const emailValues: (string | null)[] = [];

    contacts.forEach((contact, index) => {
      phoneValues[index] = contact.userPhone ?? null;
    });

    contacts.forEach((contact, index) => {
      emailValues[index] = contact.userEmail;
    });

    setPhone(phoneValues);
    setEmail(emailValues);
  }, [contacts]);

  return (
    <>
      {contacts.map((currContact, index) => (
        <React.Fragment
          key={
            currContact.userId
              ? `${currContact.userId}_container`
              : `${index}_container`
          }
        >
          <Grid container spacing={2}>
            <Grid item mb={2} data-testid="contactType">
              <Select
                id="contactType"
                value={currContact.userContactType ?? ''}
                options={handleListContactTypes(index)}
                label={t('pages.tenantConfig.rsiContacts.type')}
                required
                onChange={(event: SelectChangeEvent<string | number>) => {
                  const newContacts = [...contacts];
                  newContacts[index].userContactType = event.target
                    .value as string;
                  onChange(newContacts);
                }}
                disabled={
                  index === 0 && !allowFirstRowRemoval && showThirdPartyType
                }
              />
            </Grid>
            <Grid item pb={2} data-testid="contactName">
              <Input
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const newContacts = [...contacts];
                  if (isFullName)
                    newContacts[index].userFullName = event.target.value;
                  else newContacts[index].userFirstName = event.target.value;
                  onChange(newContacts);
                }}
                id={currContact.userFullName + index}
                value={
                  isFullName
                    ? currContact.userFullName
                    : currContact.userFirstName ?? ''
                }
                label={t('pages.tenantConfig.rsiContacts.name')}
                required
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item pb={2} data-testid="contactEmail">
              <Input
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  emailMask.handleMaskChange(event, index);
                  const newContacts = [...contacts];
                  newContacts[index].userEmail = event.target.value;
                  onChange(newContacts);
                }}
                id={currContact.userEmail}
                value={currContact.userEmail ?? ''}
                label={t('pages.tenantConfig.rsiContacts.email')}
                required
                disabled={isReadOnly}
                helperText={handleHelperTextChange(emailMask, index)}
              />
            </Grid>
            <Grid item pb={2} data-testid="contactPhone">
              <Input
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  phoneMask.handleMaskChange(event, index);
                  const newContacts = [...contacts];
                  newContacts[index].userPhone = event.target.value;
                  onChange(newContacts);
                }}
                id={currContact.userPhone}
                value={currContact.userPhone ?? ''}
                label={t('pages.tenantConfig.rsiContacts.phone')}
                required={phoneRequired}
                helperText={handleHelperTextChange(phoneMask, index)}
              />
            </Grid>
            {showOrganization && (
              <Grid item pb={2} data-testid="contactOrg">
                <Input
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const newContacts = [...contacts];
                    newContacts[index].userOrganization = event.target.value;
                    onChange(newContacts);
                  }}
                  id={currContact.userOrganization}
                  value={currContact.userOrganization ?? ''}
                  label={t('pages.tenantConfig.rsiContacts.organization')}
                  required
                />
              </Grid>
            )}
            <Grid item mt={2}>
              {(allowFirstRowRemoval || index !== 0) && (
                <IconButton
                  onClick={() => {
                    if (contacts) {
                      const newContacts = contacts.filter(
                        (contact, position) => position !== index
                      );
                      onChange(newContacts);
                    }
                    emailMask.maskErrors.splice(index, 1);
                    phoneMask.maskErrors.splice(index, 1);
                  }}
                  aria-label="delete"
                  color="default"
                  size="small"
                  data-testid="delete-button"
                >
                  <CancelRoundedIcon fontSize="small" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
}

export default RsiContactsManager;
