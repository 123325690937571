import { useCallback, useEffect, useMemo, useState } from 'react';

import { Card, CardContent, Grid } from '@mui/material';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import HorizontalNonLinearStepper from 'components/stepper';
import ModulesAndTaxTypes from 'components/tenantConfig/ModulesAndTaxTypes';
import RsiContacts from 'components/tenantConfig/RsiContacts';
import TenancyConfig from 'components/tenantConfig/TenancyConfig';
import TenantIdentityProvider from 'components/tenantConfig/TenantIdentityProvider';
import TenantInformation from 'components/tenantConfig/TenantInformation';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useSaveTenant from 'hooks/useSaveTenant';
import useTenantById from 'hooks/useTenantById';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  LocaleInfo,
  Modules,
  RsiContactInfo,
  SecurityInfo,
  Tenant,
  TenantCommand,
  tenantDefault,
  TenantIdp,
  tenantIdpDefault,
} from 'types/tenants';

function TenantConfig(): JSX.Element {
  const { t } = useTranslation();
  useUpdateTitle(t('pages.tenantConfig.title'));
  const [currentStep, setCurrentStep] = useState(0);
  const [reset, setReset] = useState(false);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const match = useRouteMatch<{ id: string }>();
  const accessToken = useGetAccessToken();
  const { isFetching } = useTenantById(accessToken, match.params.id);

  const [isTenantInfoValid, setTenantInfoValid] = useState(false);
  const [isRsiContactsValid, setRsiContactsValid] = useState(false);
  const [isTenancyConfigValid, setTenancyConfigValid] = useState(false);
  const [isTenantIdpValid, setTenantIdpValid] = useState(false);
  const [stepPassedValidation, setStepPassedValidation] =
    useState<boolean>(true);

  const saveTenant = useSaveTenant();
  const dispatch = useAppDispatch();
  const [tenant, setTenant] = useState<Tenant>(tenantDefault);
  const [tenantIdp, setTenantIdp] = useState<TenantIdp>(tenantIdpDefault);
  const [isDone, setIsDone] = useState(false);
  const [validTenantName, setValidTenantName] = useState(false);
  const [showTenantNameError, setShowTenantNameError] = useState(false);

  // TODO: get this working and remove hard coded stuff
  const handleSaveTenant = () => {
    const tenantCommand: TenantCommand = {
      userNameLogin: 'mgonzalez',
      userEmail: 'mgonzalez@rsimail.com',
      tenantName: tenant.tenantName,
      securityInfo: { ...tenant.securityInfo, tenantIdp },
      rsiContactInfo: tenant.rsiContactInfo,
      contractualInfo: tenant.contractualInfo,
      localeInfo: tenant.localeInfo,
    };

    saveTenant.mutate(
      {
        tenantCommand,
        token: '',
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              message: t('components.message.success'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },

        onError: () => {
          dispatch(
            addMessage({
              message: t('components.message.networkerror'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );

    setTenant({
      ...tenantDefault,
    });

    setReset(true);
    setIsDone(false);
  };

  const isSaveAndCreateDisabled = useMemo(() => {
    return (
      isTenantInfoValid &&
      isRsiContactsValid &&
      isTenancyConfigValid &&
      isTenantIdpValid
    );
  }, [
    isTenantInfoValid,
    isRsiContactsValid,
    isTenancyConfigValid,
    isTenantIdpValid,
  ]);

  const validateSteps = useCallback(() => {
    let isValid = true;

    switch (currentStep) {
      case 0:
        isValid = isRsiContactsValid;
        break;
      case 1:
        isValid = isTenantInfoValid;
        break;
      case 2:
        isValid = isTenancyConfigValid;
        break;
      case 3:
        isValid = isTenantIdpValid;
        break;
    }
    setStepPassedValidation(isValid);
  }, [
    currentStep,
    isRsiContactsValid,
    isTenantInfoValid,
    isTenancyConfigValid,
    isTenantIdpValid,
    setStepPassedValidation,
  ]);

  const handleReset = () => {
    setIsDone(false);
    setCurrentStep(0);
    setCompleted({});
  };

  useEffect(() => {
    const newCompleted: { [k: number]: boolean } = {};
    switch (currentStep) {
      case 0:
        newCompleted[currentStep] = isRsiContactsValid;
        break;
      case 1:
        newCompleted[currentStep] = isTenantInfoValid;
        break;
      case 2:
        newCompleted[currentStep] = isTenancyConfigValid;
        break;
      case 3:
        newCompleted[currentStep] = isTenantIdpValid;
        break;
      case 4:
        newCompleted[currentStep] = true;
        break;
    }

    setCompleted((completes) => ({ ...completes, ...newCompleted }));
  }, [
    currentStep,
    isRsiContactsValid,
    isTenantInfoValid,
    isTenancyConfigValid,
    isTenantIdpValid,
    setCompleted,
  ]);

  useEffect(() => {
    const primaryContact = tenant.rsiContactInfo.rsiContacts.filter(
      ({ userContactType }) => {
        return userContactType === 'Primary';
      }
    );

    if (primaryContact.length === 1) {
      const [contact] = primaryContact;
      const { userFullName, userEmail } = contact;
      setTenantIdp({
        managementAdminName: `${userFullName}`,
        managementAdminEmail: userEmail,
        openIdMetadataUrl: '',
      });
    } else {
      setTenantIdp({
        managementAdminEmail: '',
        openIdMetadataUrl: '',
        managementAdminName: '',
      });
    }
  }, [tenant.rsiContactInfo.rsiContacts, setTenantIdp]);

  useEffect(() => {
    validateSteps();
  }, [validateSteps]);

  useEffect(() => {
    setReset(true);
  }, []);

  return !isFetching ? (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={20} mt={4}>
            <HorizontalNonLinearStepper
              completed={completed}
              steps={[
                {
                  title: t('pages.tenantConfig.rsiContacts.title'),
                  description: t('pages.tenantConfig.rsiContacts.description'),
                },
                {
                  title: t('pages.tenantConfig.tenantInfo.title'),
                  description: t('pages.tenantConfig.tenantInfo.description'),
                },
                {
                  title: t('pages.tenantConfig.tenancyConfiguration.title'),
                },
                {
                  title: t('pages.tenantConfig.tenantIdp.title'),
                },
                {
                  title: t('pages.tenantConfig.modulesAndTaxTypes.title'),
                },
              ]}
              isDone={isDone}
              setIsDone={setIsDone}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              handleCurrentStep={(activeStep: number) => {
                setCurrentStep(activeStep);
              }}
              handleSave={handleSaveTenant}
              handleReset={handleReset}
              isCompleteStepBtnDisabled={!stepPassedValidation}
              isSaveAndCreateDisabled={!isSaveAndCreateDisabled}
            >
              {currentStep === 0 && (
                <RsiContacts
                  rsiContactInfo={tenant.rsiContactInfo}
                  handleChange={(newRsiContactInfo: RsiContactInfo) => {
                    setTenant({ ...tenant, rsiContactInfo: newRsiContactInfo });
                  }}
                  handleValidation={setRsiContactsValid}
                />
              )}
              {currentStep === 1 && (
                <TenantInformation
                  reset={reset}
                  setReset={setReset}
                  tenantInfo={tenant.contractualInfo.tenantInfo}
                  tenantName={tenant.tenantName}
                  validTenantName={validTenantName}
                  showTenantNameError={showTenantNameError}
                  setValidTenantName={setValidTenantName}
                  setShowTenantNameError={setShowTenantNameError}
                  handleChange={(newTenantInfo, tenantName) => {
                    setTenant({
                      ...tenant,
                      tenantName,
                      contractualInfo: {
                        modules: tenant.contractualInfo.modules,
                        tenantInfo: newTenantInfo,
                      },
                    });
                  }}
                  handleValidation={setTenantInfoValid}
                />
              )}
              {currentStep === 2 && (
                <TenancyConfig
                  localeInfo={tenant.localeInfo}
                  securityInfo={tenant.securityInfo}
                  handleChange={(
                    newLocaleInfo: LocaleInfo,
                    newSecurityInfo: SecurityInfo
                  ) => {
                    setTenant({
                      ...tenant,
                      localeInfo: newLocaleInfo,
                      securityInfo: newSecurityInfo,
                    });
                  }}
                  handleValidation={setTenancyConfigValid}
                />
              )}
              {currentStep === 3 && (
                <TenantIdentityProvider
                  tenantIdp={{
                    ...tenant.securityInfo.tenantIdp,
                    managementAdminName: tenantIdp.managementAdminName,
                    managementAdminEmail: tenantIdp.managementAdminEmail,
                  }}
                  handleValidation={setTenantIdpValid}
                />
              )}
              {currentStep === 4 && (
                <ModulesAndTaxTypes
                  contractualInfoModules={tenant.contractualInfo.modules}
                  handleChange={(modules: Modules[]) => {
                    setTenant({
                      ...tenant,
                      contractualInfo: {
                        modules,
                        tenantInfo: tenant.contractualInfo.tenantInfo,
                      },
                    });
                  }}
                />
              )}
            </HorizontalNonLinearStepper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : (
    <Loading />
  );
}

export default TenantConfig;
