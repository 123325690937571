import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { LocaleInfo } from 'types/tenants';

const fetchTenantLocale = async (
  token: string | undefined
): Promise<LocaleInfo[]> => {
  const result = await axios.get(
    `${process.env.REACT_APP_LOOKUP}/localesinfo`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

export default function useGetLocale(
  accessToken: string | undefined
): UseQueryResult<LocaleInfo[], Error> {
  return useQuery<LocaleInfo[], Error>(
    ['localeInfo'],
    () => {
      return fetchTenantLocale(accessToken);
    },
    {
      enabled: !!accessToken,
    }
  );
}
