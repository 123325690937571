import { Configuration } from '@azure/msal-browser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export interface User {
  userName: string;
  roles: string[];
  policies: string[];
  language?: string;
}

export interface UserState {
  user: User;
  layout: string;
}

export interface TenantConfig {
  id: string;
  client: string;
  config: Configuration;
}

const initialState: UserState = {
  user: {
    userName: '',
    roles: [],
    policies: [],
    language: 'en',
  },
  layout: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    updateLayout: (state, action: PayloadAction<string>) => {
      state.layout = action.payload;
    },

    clearUserState: (state) => {
      state.user = initialState.user;
    },
  },
});

export const { updateUser, updateLayout, clearUserState } = userSlice.actions;

export const user = (state: RootState): User => {
  return state.user.user;
};

export const layout = (state: RootState): string => {
  return state.user.layout;
};

export default userSlice.reducer;
