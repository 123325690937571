import React, { useEffect, useState } from 'react';

import { Grid, Box, Typography } from '@mui/material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import DefaultDataTable from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DefaultDataTable';
import { AssignAction } from 'hooks/useAssignRoleUsers';
import useAssignRoleUsers from 'hooks/useAssignRoleUsers';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useRoleUsers from 'hooks/useRoleUsers';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';

interface RowProp {
  values: ValueProp;
}
interface ValueProp {
  userId: string;
}
interface Row {
  value: string;
  id: string;
  row: RowProp;
  selectedFlatRows: [];
}

function AddUsers(): JSX.Element {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const match = useRouteMatch<{ roleId: string }>();
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const { mutate: addUsers } = useAssignRoleUsers();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.addUsers',
  });

  const { data, refetch, error } = useRoleUsers(
    accessToken,
    match.params.roleId,
    false
  );

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('components.message.networkerror'),
        })
      );
    }
  }, [error, dispatch, t]);

  const COLUMNS = [
    {
      Header: () => {
        return (
          <Checkbox
            id={'selectAllCheck'}
            checked={selectedRows.length === data?.length}
            onChange={(event) => {
              let newSelectedRows = [...selectedRows];
              if (event.target.checked && data)
                newSelectedRows = data?.map((p) => p.userId);
              else newSelectedRows = [];
              setSelectedRows(newSelectedRows);
            }}
            label={''}
          />
        );
      },
      accessor: 'userId',
      Cell: ({ row }: Row) => {
        return (
          <Checkbox
            checked={
              selectedRows.findIndex((p) => p === row.values.userId) > -1
            }
            onChange={(event) => {
              let newRows = [...selectedRows];
              if (event.target.checked) {
                setSelectedRows([...selectedRows, row.values.userId]);
              } else {
                newRows = selectedRows.filter((p) => p !== row.values.userId);
                setSelectedRows(newRows);
              }
            }}
            id={''}
            label={''}
          />
        );
      },
    },
    {
      Header: t('userName'),
      accessor: 'name',
    },
    {
      Header: t('email'),
      accessor: 'email',
    },
  ];

  useUpdateTitle(t('title'));

  const history = useHistory();

  function updateUsers() {
    const addPayload = selectedRows.map((row) => {
      return { roleId: match.params.roleId, userId: row };
    });
    //TODO: remove tenant id from contract
    addUsers(
      {
        userCommand: {
          userRoleMapEntries: addPayload,
          mapAction: AssignAction.ASSIGN,
          tenantId: '8E8F97C7-8657-4895-81C5-1142B6D1DE84',
        },
        token: accessToken,
      },
      {
        onSuccess: () => {
          refetch();
          setSelectedRows([]);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('components.message.networkerror'),
            })
          );
        },
      }
    );
  }

  return (
    <Box>
      <Box sx={{ float: 'left', minWidth: '300px' }}>
        <Grid item xs={10} mb={2}>
          <Typography variant="h1">{t('header')}</Typography>
          <Typography variant="h3">{t('subHeader')}</Typography>
        </Grid>
        <Grid item xs={10} mb={2}>
          <Typography variant="h2">{t('select')}</Typography>
          {selectedRows.length > 0 && (
            <Typography>
              {selectedRows.length}
              {t('userSelected')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={10} mb={2}>
          <Button
            id="saveBtn-addUsers"
            sx={{ mt: 1, mb: 1, float: 'left' }}
            disabled={selectedRows.length === 0}
            onClick={() => {
              updateUsers();
            }}
          >
            {selectedRows.length > 1 ? t('addUsersBtn') : t('addUserBtn')}
          </Button>
          <Button
            id="backBtn-addUsers"
            onClick={() =>
              history.push('/manageroles/edit/' + match.params.roleId)
            }
            sx={{ mt: 1, mb: 1, float: 'right' }}
          >
            {t('goBackBtn')}
          </Button>
        </Grid>
      </Box>
      <Box sx={{ float: 'right', minWidth: '900px' }}>
        <Typography variant="h1">{t('unassignedUsers')}</Typography>

        {data && data.length > 0 && (
          <DefaultDataTable columns={COLUMNS as Column<object>[]} data={data} />
        )}
        {/* {data && data?.length === 0 && <NoResults />} */}
      </Box>
    </Box>
  );
}

export default AddUsers;
